import React, { useState, FC, ReactElement } from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Button, Dropdown, Menu } from 'antd'
import { ImportModal } from './ImportPendingOrder/ImportModal'
import { Requests } from '../../../../../api/requests'
import { Snapdocs } from '../../../../../hooks/api/snapdocs'
import { CreateOrderProps } from '../types'

export const CreateOrder: FC<CreateOrderProps> = ({ companyId }): ReactElement => {
  const [showImportModal, setShowImportModal] = useState(false)
  const { path: newOrderPath } = Requests.order({ id: 'new' })
  const { request: featurePoliciesRequest, response: featurePolicies } = Snapdocs.useResources(
    Requests.featurePolicies({ companyId })
  )

  if (Requests.isLoading(featurePoliciesRequest)) {
    return <></>
  }

  if (featurePolicies.allowOrderImport) {
    const menu = (
      <Menu>
        <Menu.Item>
          <Button style={{ marginBottom: 2 }} type="link" href={newOrderPath} data-testid="add-order-manually-link">
            Add order manually
          </Button>
        </Menu.Item>
      </Menu>
    )

    return (
      <>
        <Dropdown.Button
          icon={<Icons.CaretDown style={{ fontSize: 10, verticalAlign: 'middle' }} />}
          onClick={() => setShowImportModal(true)}
          overlay={menu}
          trigger="click"
          placement="bottomRight"
          type="primary"
        >
          + Import Pending Order
        </Dropdown.Button>
        {showImportModal && <ImportModal onCancel={() => setShowImportModal(false)} />}
      </>
    )
  } else {
    return (
      <Button type="primary" href={newOrderPath} data-testid="create-order-only-btn">
        + Create order
      </Button>
    )
  }
}
