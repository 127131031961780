import React, { useState, FC, ReactElement } from 'react'
import { Modal } from '../../../../../Shared/Ant'
import { Alert } from 'antd'
import { ImportModalProps, PendingOrderParams } from '../../types'
import { ClientStep } from './ClientStep'
import { FileNumberStep } from './FileNumberStep'
import { SignersStep } from './SignersStep'
import { get } from 'lodash'
import { apiCall } from '../../../../../../api/apiCall'
import { Requests } from '../../../../../../api/requests'
import { camelCaseToSnakeCase } from '../../../../../../utils/camelCaseToSnakeCase'

export const ImportModal: FC<ImportModalProps> = ({ onCancel }): ReactElement => {
  const [errors, setErrors] = useState([])
  const [importPendingOrderStep, setImportPendingOrderStep] = useState('client')
  const [params, setParams] = useState({} as PendingOrderParams)
  const [isLoading, setIsLoading] = useState(false)

  const newOrderImportLocation = () => {
    let location = `/pending_orders/new_order_import?client_id=${params.clientId}&product_id=${params.productId}&file_number=${params.fileNumber}`
    if (params.appointmentDate) {
      location = location + `&appointment_date=${params.appointmentDate.format('MMM DD, YYYY')}`
    }
    if (params.appointmentTimePrefix) {
      location = location + `&appointment_time_prefix=${params.appointmentTimePrefix}`
    }
    if (params.appointmentTime) {
      location = location + `&appointment_time=${params.appointmentTime}`
    }
    if (params.signer) {
      location = location + `&signer=${params.signer}`
    }
    if (params.coSigner) {
      location = location + `&co_signer=${params.coSigner}`
    }
    return encodeURI(location)
  }

  const onFinish = () => {
    setIsLoading(true)
    apiCall
      .post(Requests.newOrderImport().path, {
        ...camelCaseToSnakeCase(params)
      })
      .then((response) => {
        const data = get(response.data, 'pending_orders', [])
        if (data.length > 0) {
          setErrors(data)
          setIsLoading(false)
        } else {
          setErrors([])
          window.location.href = newOrderImportLocation()
        }
      })
  }

  const onChange = (changedValues: PendingOrderParams) => {
    setParams({ ...params, ...changedValues })
  }

  const showErrors = errors.map((error: string, index: number) => (
    <Alert style={{ marginBottom: 10 }} key={index} message={error} type="error" />
  ))

  return (
    <Modal onCancel={() => onCancel()} title="Import Pending Order" visible={true} footer={false}>
      {errors.length > 0 && showErrors}
      {importPendingOrderStep === 'client' && (
        <ClientStep onFinish={() => setImportPendingOrderStep('fileNumber')} onValuesChange={onChange} />
      )}
      {importPendingOrderStep === 'fileNumber' && (
        <FileNumberStep
          clientId={params.clientId}
          onFinish={() => setImportPendingOrderStep('signers')}
          onValuesChange={onChange}
        />
      )}
      {importPendingOrderStep === 'signers' && (
        <SignersStep params={params} onFinish={onFinish} onValuesChange={onChange} isLoading={isLoading} />
      )}
    </Modal>
  )
}
