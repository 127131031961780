import React, { FC, ReactElement } from 'react'
import './styles.modules.scss'
import axios from 'axios'
import { Form, Input, Checkbox, Select, Row, Col, Divider, Button, Alert } from 'antd'
import { ButtonLink } from '../../components/Shared/ButtonLink'
import { AddNewCtmProps } from './types'

const ACCESS_LEVELS = [
  {
    label: 'View & Comment',
    value: 'view_and_comment',
    description: 'Can only view a closing and write comments in the comments section'
  },
  {
    label: 'Closing Editor',
    value: 'closer',
    description: 'Closing Editors can only access and edit closings they’ve been assigned to'
  },
  {
    label: 'Manager',
    value: 'manager',
    description: 'Managers can view and edit all closings, but cannot change company settings'
  },
  {
    label: 'Admin',
    value: 'admin',
    description: "Admins can change your company's settings, as well as view and edit all closings"
  }
]

const AddNewCtm: FC<AddNewCtmProps> = ({
  companyTeamsPath,
  addNewCtmPath,
  csrfToken,
  cqcRestrictUsers
}): ReactElement => {
  const [form] = Form.useForm()
  const [errors, setErrors] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  const onFinish = (values: {
    role: string
    first_name: string
    last_name: string
    email: string
    qc_funding_report: boolean
    send_welcome_message: string[]
  }) => {
    setLoading(false)
    axios
      .post(
        addNewCtmPath,
        {
          company_team_member_create_form: {
            ...values,
            send_welcome_message: values.send_welcome_message.includes('true')
          }
        },
        {
          headers: {
            Accept: 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      )
      .then(() => {
        window.location.href = companyTeamsPath + '?success_redirect=true'
      })
      .catch((error) => {
        setLoading(true)
        if (error.response.status === 400) {
          setErrors(error.response.data.errors)
        } else {
          setErrors(['Submit failed, please try again later.'])
        }
      })
  }

  const formErrors = () => (
    <div className="ctm_errors">
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  )

  const initialValues = {
    role: 'closer',
    first_name: '',
    last_name: '',
    email: '',
    send_welcome_message: ['true']
  }

  return (
    <Row>
      <Col sm={24} md={20} lg={12}>
        {errors.length > 0 && (
          <Alert
            message="Errors detected"
            description={formErrors()}
            onClose={() => setErrors([])}
            type="error"
            showIcon
            closable
          />
        )}
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
          className="add_new_ctm_form"
          requiredMark={false}
        >
          <Form.Item
            label="Access Level"
            name="role"
            rules={[{ required: true, message: 'Please select an access level' }]}
          >
            <Select
              {...{
                placeholder: 'Select an access level',
                options: ACCESS_LEVELS.map(({ value, label, description }) => ({
                  value,
                  label: (
                    <React.Fragment>
                      <strong>{label}</strong>
                      <div className="description">{description}</div>
                    </React.Fragment>
                  )
                }))
              }}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'Please input your first name' }]}
              >
                <Input type="text" aria-label="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Please input your last name' }]}
              >
                <Input type="text" aria-label="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email' }]}
          >
            <Input type="text" aria-label="Email" />
          </Form.Item>
          {cqcRestrictUsers && (
            <Form.Item name="qc_funding_report" valuePropName="checked" className="mbm">
              <Checkbox>QC Report Access</Checkbox>
            </Form.Item>
          )}
          <Form.Item name="send_welcome_message">
            <Checkbox.Group>
              <Checkbox value="true">Email login information to this user</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item className="text-right add_team_member_footer">
            <Divider type="horizontal" />
            {loading && (
              <ButtonLink callback={() => {}} href={companyTeamsPath} className="reset-link csv_cancel">
                Cancel
              </ButtonLink>
            )}
            <Button type="primary" htmlType="submit" disabled={!loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default AddNewCtm
