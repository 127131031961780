import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Popover, Button, Row, Col } from 'antd'
import './styles.modules.scss'

export const ClientAndParticipantPopover: FC<{ newCommentUiFlipperEnabled: boolean }> = ({
  newCommentUiFlipperEnabled
}): ReactElement => {
  const content = (
    <div className="client-and-participant-popover-content">
      <div className="content-block">
        <p className="content-header">Client team member</p>
        <p className="content-description">
          The individual contact from your client who’s responsible for the order. You can choose the client team member
          when you create or edit an order.
        </p>
      </div>
      <div className="content-block">
        <p className="content-header">Participant</p>
        <p className="content-description">
          The loan officer, escrow officer, realtor, or anyone else interested in the closing. You can add participant
          email addresses when you create or edit an order.
        </p>
      </div>
      {!newCommentUiFlipperEnabled && (
        <div className="content-block">
          <p className="content-description">
            Note: client team members and participants will never see notary comments.
          </p>
        </div>
      )}
    </div>
  )

  return (
    <Row justify="end" className="client-and-participant-popover">
      <Col>
        <Popover placement="bottomRight" content={content}>
          <Button className="popover-button" icon={<Icons.QuestionCircle className="icon" />} type="link">
            What is a client or participant?
          </Button>
        </Popover>
      </Col>
    </Row>
  )
}
