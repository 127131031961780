import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { GLOBAL } from 'messages/GLOBAL'
import { ButtonLink } from 'components/Shared/ButtonLink'
import { AvatarProps } from 'components/Shared/Avatar'
import { handleRailsPartials } from 'utils/handleRailsPartials'
import { OrderSignerDetailsView } from './OrderSignerDetailsView'
import { OrderSignerDetailsEdit, UserDetails, UserDetailsErrors } from './OrderSignerDetailsEdit'

import s from './styles.module.scss'

interface Signer {
  firstname: string
  lastname: string
  signer_email: string
  mobile_phone: string
  work_phone: string
  home_phone: string
}

interface CoSigner {
  co_first_name: string
  co_last_name: string
  co_signer_email: string
  co_mobile_phone: string
  co_work_phone: string
  co_home_phone: string
}

interface SignerErrors {
  firstname: Array<string>
  lastname: Array<string>
  signer_email: Array<string>
  mobile_phone: Array<string>
  work_phone: Array<string>
  home_phone: Array<string>
}

interface CoSignerErrors {
  co_first_name: Array<string>
  co_last_name: Array<string>
  co_signer_email: Array<string>
  co_mobile_phone: Array<string>
  co_work_phone: Array<string>
  co_home_phone: Array<string>
}

interface OrderSignerDetailsProps {
  updateSignerRoute: string
  isCosigner?: boolean
  isEditable?: boolean
  firstName?: string
  lastName?: string
  email?: string
  mobilePhone?: string
  workPhone?: string
  homePhone?: string
  avatarProps?: AvatarProps
}

interface OrderSignerDetailsState {
  isBeingEdited: boolean
  isLoading: boolean
  fields: UserDetails
  errors: UserDetailsErrors
}

export class OrderSignerDetails extends Component<OrderSignerDetailsProps, OrderSignerDetailsState> {
  constructor(props: OrderSignerDetailsProps) {
    super(props)

    this.state = {
      isBeingEdited: false,
      isLoading: false,
      fields: {
        firstName: this.props.firstName || '',
        lastName: this.props.lastName || '',
        email: this.props.email || '',
        mobilePhone: this.props.mobilePhone || '',
        homePhone: this.props.homePhone || '',
        workPhone: this.props.workPhone || ''
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        mobilePhone: [],
        workPhone: [],
        homePhone: []
      }
    }

    this.showEdit = this.showEdit.bind(this)
    this.hideEdit = this.hideEdit.bind(this)
    this.getEditButton = this.getEditButton.bind(this)
    this.getChildren = this.getChildren.bind(this)
    this.submitUpdateSigner = this.submitUpdateSigner.bind(this)
  }

  getEditButton() {
    const editText = this.isUserPresent() ? GLOBAL.CONTROLS.EDIT : GLOBAL.CONTROLS.ADD

    if (!this.props.isEditable) {
      return null
    }

    // Only show the edit button if it's not being edited.
    return !this.state.isBeingEdited ? (
      <ButtonLink callback={this.showEdit} className={s.panel__editButton}>
        {editText}
      </ButtonLink>
    ) : null
  }

  getChildren() {
    // If no user is present and we aren't in edit mode, don't show anything.
    if (!this.isUserPresent() && !this.state.isBeingEdited) {
      return null
    }

    // If the user is being edited, show the edit pane.
    return !this.state.isBeingEdited ? (
      <div className={s.panel__content}>
        <OrderSignerDetailsView
          avatarProps={this.props.avatarProps}
          firstName={this.state.fields.firstName}
          lastName={this.state.fields.lastName}
          email={this.state.fields.email}
          mobilePhone={this.state.fields.mobilePhone}
          workPhone={this.state.fields.workPhone}
          homePhone={this.state.fields.homePhone}
        />
      </div>
    ) : (
      <div className={s.panel__content}>
        <OrderSignerDetailsEdit
          isLoading={this.state.isLoading}
          submitUpdateSigner={this.submitUpdateSigner}
          hideEdit={this.hideEdit}
          fields={this.state.fields}
          errors={this.state.errors}
        />
      </div>
    )
  }

  isUserPresent() {
    // Checks if not all fields are empty, which means the user has at least one field populated.
    return !_.isEmpty(_.filter(this.state.fields, (field) => !!field))
  }

  showEdit() {
    this.setState({ isBeingEdited: true })
  }

  hideEdit() {
    this.setState({ isBeingEdited: false })
  }

  shouldRender() {
    // When the signer doesn't exist and the user cannot edit, don't render this component
    return this.isUserPresent() || this.props.isEditable
  }

  translateSignerModelFieldsToReact(signer: Signer): UserDetails {
    return {
      firstName: signer.firstname,
      lastName: signer.lastname,
      email: signer.signer_email,
      mobilePhone: signer.mobile_phone,
      workPhone: signer.work_phone,
      homePhone: signer.home_phone
    }
  }

  translateCoSignerModelFieldsToReact(coSigner: CoSigner): UserDetails {
    return {
      firstName: coSigner.co_first_name,
      lastName: coSigner.co_last_name,
      email: coSigner.co_signer_email,
      mobilePhone: coSigner.co_mobile_phone,
      workPhone: coSigner.co_work_phone,
      homePhone: coSigner.co_home_phone
    }
  }

  translateSignerErrorsModelFieldsToReact(signer: SignerErrors): UserDetailsErrors {
    return {
      firstName: signer.firstname,
      lastName: signer.lastname,
      email: signer.signer_email,
      mobilePhone: signer.mobile_phone,
      workPhone: signer.work_phone,
      homePhone: signer.home_phone
    }
  }

  translateCoSignerErrorsModelFieldsToReact(coSigner: CoSignerErrors): UserDetailsErrors {
    return {
      firstName: coSigner.co_first_name,
      lastName: coSigner.co_last_name,
      email: coSigner.co_signer_email,
      mobilePhone: coSigner.co_mobile_phone,
      workPhone: coSigner.co_work_phone,
      homePhone: coSigner.co_home_phone
    }
  }

  translateSignerReactFieldsToModel(userDetails: UserDetails): Signer {
    return {
      firstname: userDetails.firstName,
      lastname: userDetails.lastName,
      signer_email: userDetails.email,
      mobile_phone: userDetails.mobilePhone,
      work_phone: userDetails.workPhone,
      home_phone: userDetails.homePhone
    }
  }

  translateCoSignerReactFieldsToModel(userDetails: UserDetails): CoSigner {
    return {
      co_first_name: userDetails.firstName,
      co_last_name: userDetails.lastName,
      co_signer_email: userDetails.email,
      co_mobile_phone: userDetails.mobilePhone,
      co_work_phone: userDetails.workPhone,
      co_home_phone: userDetails.homePhone
    }
  }

  submitUpdateSigner(formFields: UserDetails, e: React.FormEvent<HTMLFormElement>) {
    // Create the expected payload
    const payload = {
      order: {
        ...(this.props.isCosigner
          ? this.translateCoSignerReactFieldsToModel(formFields)
          : this.translateSignerReactFieldsToModel(formFields))
      }
    }

    // Clear previous errors so that new ones can populate.
    this.setState({
      errors: {},
      isLoading: true
    })

    // Send the updateSigner action
    axios
      .post(this.props.updateSignerRoute, payload, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        // On success, Update the state with the new fields.
        const fields = this.props.isCosigner
          ? this.translateCoSignerModelFieldsToReact(response.data)
          : this.translateSignerModelFieldsToReact(response.data)
        this.setState({
          fields,
          isLoading: false
        })
        // Hide the edit form.
        this.hideEdit()

        // Refresh Rails partials, if any
        handleRailsPartials(response.data)
      })
      .catch((error) => {
        // On error, Update the state with the new errors.
        const errors = this.props.isCosigner
          ? this.translateCoSignerErrorsModelFieldsToReact(error.response.data.errors)
          : this.translateSignerErrorsModelFieldsToReact(error.response.data.errors)
        this.setState({
          errors,
          isLoading: false
        })
      })

    e.preventDefault()
  }

  render() {
    const title = this.props.isCosigner ? GLOBAL.COSIGNER : GLOBAL.CONSUMER

    if (!this.shouldRender()) {
      return null
    }

    return (
      <div className={s.panel}>
        <div className={s.panel__heading}>
          {title}
          {this.getEditButton()}
        </div>
        {this.getChildren()}
      </div>
    )
  }
}

export default OrderSignerDetails
