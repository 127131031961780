import React, { FC, ReactElement } from 'react'
import { Button } from 'antd'
import { OrderFormProps } from './OrderFormProps'
import { Provider } from 'react-redux'
import AddSignerDetail from './AddSignerDetail'
import LocationAndTime from './LocationAndTime'
import OrderInformationForm from './OrderInformation/OrderInformationForm'
import { store } from '../../store'
import '../Shared/Ant/App.less'

export const OrderForm: FC<OrderFormProps> = (props): ReactElement => {
  return (
    <Provider store={store}>
      <LocationAndTime />
      <OrderInformationForm
        order={props.order}
        isOrderTransactionTypeFlipperEnabled={props.isOrderTransactionTypeFlipperEnabled}
        areDocsInTransit={props.areDocsInTransit}
        isTitleCompany={props.isTitleCompany}
        isEscrowAccessible={props.isEscrowAccessible}
        changeDocumentDelivery={props.changeDocumentDelivery}
        closeOfEscrowFeaturePolicy={props.closeOfEscrowFeaturePolicy}
        userRole={props.userRole}
      />
      <AddSignerDetail
        order={props.order}
        isTitleCompany={props.isTitleCompany}
        isClosingGenerated={props.isClosingGenerated}
      />
      <Button type="primary">Create Order</Button>
    </Provider>
  )
}
