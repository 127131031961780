import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Menu, Dropdown, Checkbox, Radio } from 'antd'
import { Option, SettingOptionsParams } from './types'
import { valuesToPrint } from './utils'
import { updateResourceSync } from '../../../actions/updateResourceSync'
import { set } from 'lodash'
import { Requests } from '../../../api/requests'
import { PreviewEmail } from './PreviewEmail'

export const SettingOptions: FC<SettingOptionsParams> = ({
  id,
  companyId,
  setting,
  confirmBrowserExit
}): ReactElement => {
  const { key, title, type, values, options } = setting
  const request = Requests.emailSettings({ id, companyId })

  const menuOptions = options.map(
    (option: Option, index: number) =>
      (type === 'checkbox' && (
        <Menu.Item key={index}>
          <Checkbox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheckBoxChange(key, e)}
            value={option.value}
            className="setting-options"
            checked={option.checked}
          >
            {option.toPrint}
          </Checkbox>
        </Menu.Item>
      )) ||
      (type === 'radio' && (
        <Menu.Item key={index}>
          <Radio
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onRadioChange(key, e)}
            value={option.value}
            className="setting-options"
            checked={option.checked}
          >
            {option.toPrint}
          </Radio>
        </Menu.Item>
      ))
  )

  const onRadioChange = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    updateResourceSync(set({}, request.type, set({}, [request.id, key], e.target.value)))
    confirmBrowserExit.enable()
  }

  const onCheckBoxChange = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!Array.isArray(values)) return

    const changedValues = [...values]
    const foundIndex = changedValues.findIndex((element) => element === e.target.value)

    if (e.target.checked) {
      changedValues.push(e.target.value)
    } else {
      changedValues.splice(foundIndex, 1)
    }

    updateResourceSync(set({}, request.type, set({}, [request.id, key], changedValues)))
    confirmBrowserExit.enable()
  }

  const menu = <Menu>{menuOptions}</Menu>

  return (
    <div className="settings-block-row-options">
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons.CaretDown className="icon" />
          <span className="selected-values">{valuesToPrint(values, options)}</span>
        </a>
      </Dropdown>
      {setting.previewImage !== '' && <PreviewEmail title={title} previewImage={setting.previewImage} />}
    </div>
  )
}
