import { Form, Input, Alert } from 'antd'
import React, { FC, ReactElement, useState } from 'react'
import { apiCall, Requests } from '../../../api'
import { ClientTeamMember } from '../../../models/client/clientTeamMember'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import './styles.modules.scss'
import { ExistingUserForm } from './ExistingUserForm'
import { DefaultUserForm } from './DefaultUserForm'
import { User } from '../../../models/user/user'
import { snakeCaseToCamelCase } from '../../../utils/snakeCaseToCamelCase'

export const AddClientTeamMemberModalForm: FC = (): ReactElement => {
  const [isExistingUser, setIsExistingUser] = useState(false)
  const [existingUser, setExistingUser] = useState<User>()
  const [clientName, setClientName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [createUserErrorMessages, setCreateUserErrorMessages] = useState<Array<string>>([])

  const showDefaultFormIfEmailBlank = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setEmailErrorMessage('')
      setIsExistingUser(false)
    }
  }

  const doesUserExist = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErrorMessage('')
    const email = e.target.value

    if (email === '') {
      setIsExistingUser(false)
      return
    }

    // TODO: MNS-2854 - Replace this with prop from parent after form is completely migrated
    let selectedClient = document.getElementById('order_client_id') as HTMLSelectElement

    if (!selectedClient) {
      selectedClient = document.getElementById('client-select') as HTMLSelectElement
    }

    const clientId = parseInt(selectedClient.value)

    // TODO: MNS 2710 - Switch these out to use the Redux store if possible. Had to timebox this
    // because it proved tricky getting the id of the newly created client.
    apiCall.get(Requests.users({ email }).path).then((resp) => {
      if (resp.data.users.length > 0) {
        const user = resp.data.users[0]

        apiCall.get(Requests.clientTeamMembers({ clientId, email }).path).then((resp) => {
          if (resp.data.team_members.length > 0) {
            setEmailErrorMessage('This user is already a client team member!')
          } else {
            setClientName(selectedClient.options[selectedClient.selectedIndex].text)
            setIsExistingUser(true)
            setExistingUser(snakeCaseToCamelCase(user) as User)
          }
        })
      }
    })
  }

  const closeModal = () => {
    const modal = document.getElementById('add-client-team-member-modal') as HTMLInputElement
    const backdrops = document.getElementsByClassName('modal-backdrop') as HTMLCollection

    for (let i = 0; i < backdrops.length; i++) {
      const backdrop = backdrops[i] as HTMLInputElement
      backdrop.style.display = 'none'
    }

    modal.style.display = 'none'
    modal.classList.remove('show')
  }

  const saveClientTeamMember = (clientTeamMember: ClientTeamMember) => {
    // TODO: MNS-2854 - Replace this with prop from parent after form is completely migrated
    let selectedClient = document.getElementById('order_client_id') as HTMLSelectElement

    if (!selectedClient) {
      selectedClient = document.getElementById('client-select') as HTMLSelectElement
    }

    const clientId = parseInt(selectedClient.value)

    const params = {
      ...clientTeamMember,
      clientId: clientId
    }

    // TODO: MNS 2710 - Switch this out to use the Redux store if possible. Had to timebox this
    // because it proved tricky getting the id of the newly created client.
    apiCall
      .post(Requests.clientTeamMembers({ clientId: clientId, email: userEmail }).path, {
        ...camelCaseToSnakeCase(params)
      })
      .then((resp) => {
        // TODO: MNS-2360 - Can be removed after the entire create order form is migrated to React.
        // hide modal in hacky non bootstrap way b/c jquery won't work here

        closeModal()

        // add new client to select dropdown
        const clientTeamMemberSelect = document.getElementById('client-team-member-select') as HTMLSelectElement

        if (clientTeamMemberSelect) {
          const opt = document.createElement('option')
          opt.value = resp.data.id
          opt.innerText = resp.data.full_name
          clientTeamMemberSelect[0] = opt
        }

        // have to re-enable scrolling on the page because select2 disables it
        document.body.style.overflow = 'visible'
      })
      .catch((error) => {
        setCreateUserErrorMessages(error.response.data.errors)
      })
  }

  const showCreateUserErrorMessages = () => {
    const errorListItems = createUserErrorMessages.map((error) => <li key={error}>{error}</li>)

    return (
      <div className="errors-section">
        <ul>{errorListItems}</ul>
      </div>
    )
  }

  return (
    <>
      <Form
        colon={false}
        onFinish={saveClientTeamMember}
        initialValues={{
          manager: false,
          sendWelcomeMessage: true,
          receiveNewOrderNotifications: true,
          ccEmailsTo: ''
        }}
      >
        {createUserErrorMessages.length > 0 && (
          <Alert description={showCreateUserErrorMessages()} type="error" showIcon />
        )}

        <Form.Item
          label="Email address"
          name="email"
          className="form-row"
          rules={[{ required: true, message: 'Email address is required.' }]}
        >
          <Input
            onBlur={doesUserExist}
            onChange={showDefaultFormIfEmailBlank}
            value={userEmail}
            onInput={(e: { target: { value: React.SetStateAction<string> } }) => setUserEmail(e.target.value)}
            allowClear
          />
        </Form.Item>

        {emailErrorMessage !== '' && <Alert description={emailErrorMessage} type="error" showIcon />}

        {isExistingUser && (
          <>
            <ExistingUserForm user={existingUser} clientName={clientName} closeModal={closeModal} />
          </>
        )}

        {!isExistingUser && (
          <>
            <DefaultUserForm closeModal={closeModal} />
          </>
        )}
      </Form>
    </>
  )
}
