import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const StateColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'State',
    dataIndex: 'state',
    defaultSortOrder: defaultSortOrder('state', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'state',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column state-column',
    render: (state: string) => {
      return <>{state && <Typography.Text>{state}</Typography.Text>}</>
    }
  }
}
