import React, { SVGProps } from 'react'

const Warning: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.556 10.927L13.073 1.444C12.481 0.852 11.52 0.852 10.928 1.444L1.44498 10.927C0.852977 11.519 0.852977 12.48 1.44498 13.072L10.928 22.555C11.52 23.147 12.481 23.147 13.073 22.555L22.556 13.072C23.148 12.48 23.148 11.52 22.556 10.927ZM12.5 17H11.5C11.224 17 11 16.776 11 16.5V15.5C11 15.224 11.224 15 11.5 15H12.5C12.776 15 13 15.224 13 15.5V16.5C13 16.776 12.776 17 12.5 17ZM12 13C11.448 13 11 12.552 11 12V8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8V12C13 12.552 12.552 13 12 13Z"
      fill="currentcolor"
    />
  </svg>
)

export default Warning
