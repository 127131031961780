import { UpcomingFilter } from './UpcomingFilter'
import { UpcomingFilterText } from './UpcomingFilterText'
import { isNonEmptyString } from '../../utils/validateString'
import { get, set } from 'lodash'
import { NavigationFilters } from './NavigationFilters'

const isUpcomingFilterSelected = (filter: string, ordersPage: boolean) => {
  return (
    ordersPage &&
    [UpcomingFilter.Presigning, UpcomingFilter.PresigningToday, UpcomingFilter.PresigningTomorrow].includes(
      <UpcomingFilter>filter
    )
  )
}

const upcomingFilterText = (filter: string) => {
  switch (filter) {
    case UpcomingFilter.Presigning:
      return UpcomingFilterText.AllUpcoming
    case UpcomingFilter.PresigningToday:
      return UpcomingFilterText.PresigningToday
    case UpcomingFilter.PresigningTomorrow:
      return UpcomingFilterText.PresigningTomorrow
    default:
      return UpcomingFilterText.AllUpcoming
  }
}

const orderStatusTextStyle = (statusText: string) => {
  if (!isNonEmptyString(statusText)) return ''

  if (statusText.match('Automating')) {
    return 'active-tag'
  } else if (statusText.match('Automator Stopped') || statusText.match('available notar(y|ies)')) {
    return 'stopped-tag'
  }

  return 'status-tag'
}

const parseFilters = (filters: any[]) => {
  const result = {}
  filters.forEach((filter: { a: { name: string | number | symbol }[]; v: { value: any }[] }) => {
    if (get(filter, 'v')) {
      set(result, [filter.a[0].name], filter.v[0].value)
    }
  })
  return result
}

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Mine', value: 'mine' }
]

const overflowCount = 999

const getValueFromCookie = (cookieName: string): string | null => {
  const pattern = new RegExp(`${cookieName}=(\\w+)`)
  const match = document.cookie.match(pattern)
  return match && match[1]
}

const selectedFilter = (filterParam: string): string => {
  if (filterParam) {
    return filterParam
  }

  return getValueFromCookie('last_used_filter') || NavigationFilters.Presigning
}

const DashboardHelper = {
  upcomingFilterText: upcomingFilterText,
  isUpcomingFilterSelected: isUpcomingFilterSelected,
  orderStatusTextStyle: orderStatusTextStyle,
  parseFilters: parseFilters,
  filterOptions: filterOptions,
  overflowCount: overflowCount,
  getValueFromCookie: getValueFromCookie,
  selectedFilter: selectedFilter
}

export default DashboardHelper
