import React, { FC, ReactElement } from 'react'
import { Select } from 'antd'
import { SortNotificationProps } from './types'

const { Option } = Select

export const SortNotification: FC<SortNotificationProps> = ({ selectedValue, handleChange }): ReactElement => {
  const changeSorting = (value: string) => {
    handleChange(value)
  }

  return (
    <span className="sort-notification">
      <span className="select-label">Sort by</span>
      <Select value={selectedValue} onChange={changeSorting} style={{ width: 225, marginRight: 28 }}>
        <Option value="received_at_desc">Message received (newest)</Option>
        <Option value="received_at_asc">Message received (oldest)</Option>
        <Option value="appointment_full_time_desc">Appointment time</Option>
      </Select>
    </span>
  )
}
