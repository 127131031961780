import { actionTypes } from 'redux-resource'
import { apiCall } from '../api'
import { AppDispatch } from '../store'
import { snakeCaseToCamelCase } from '../utils/snakeCaseToCamelCase'
import { camelCaseToSnakeCase } from '../utils/camelCaseToSnakeCase'

export const createResource = (args: { path: string; type: string; params: object }) => (dispatch: AppDispatch) => {
  const { path, type, params } = args

  dispatch({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    resourceType: type,
    requestKey: 'create'
  })

  return apiCall
    .request({
      method: 'post',
      url: path,
      params: camelCaseToSnakeCase(params)
    })
    .then((response) => {
      dispatch({
        type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
        resourceType: type,
        requestKey: 'create',
        resources: [snakeCaseToCamelCase(response.data)]
      })
    })
    .catch(() => {
      dispatch({
        type: actionTypes.CREATE_RESOURCES_FAILED,
        resourceType: type,
        requestKey: 'create'
      })
    })
}
