export const ICONS = {
  ANGLE_LEFT: 'fa fa-angle-left',
  CHECK: 'fa fa-check',
  CHECK_CIRCLE: 'fa fa-check-circle',
  CHECK_CIRCLE_FW: 'fa fa-fw fa-check-circle',
  CHEVRON: 'fa fa-chevron-up',
  CIRCLE_O: 'fa fa-circle-o',
  CLOCK_O: 'fa fa-fw fa-clock-o',
  CLOUD_UPLOAD: 'fa fa-fw fa-cloud-upload',
  COPY: 'fa fa-copy',
  DESKTOP: 'fa fa-desktop',
  DOT_CIRCLE_O: 'fa fa-dot-circle-o',
  DOUBLE_ANGLE_LEFT: 'fa fa-angle-double-left',
  DOUBLE_ANGLE_RIGHT: 'fa fa-angle-double-right',
  DOWNLOAD: 'fa fa-download',
  EDIT: 'fa fa-fw fa-pencil',
  EMAIL: 'fa fa-fw fa-envelope-o',
  ENVELOPE: 'fa fa-envelope',
  EXCLAMATION_CIRCLE: 'fa fa-exclamation-circle',
  EXCLAMATION_TRIANGLE: 'fa fa-exclamation-triangle',
  FILE: 'fa fa-file',
  HOME_PHONE: 'fa fa-home fa-fw',
  MINUS: 'fa fa-minus',
  MOBILE_PHONE: 'fa fa-mobile fa-fw center',
  PHONE: 'fa fa-fw fa-phone',
  PLUS: 'fa fa-plus',
  QUESTION_CIRCLE: 'fa fa-question-circle',
  REFRESH: 'fa fa-refresh',
  ROTATE_LEFT: 'fa fa-rotate-left',
  ROTATE_RIGHT: 'fa fa-rotate-right',
  SHARE: 'fa fa-share',
  SOLID_CIRCLE: 'fa fa-circle',
  SPINNER: 'fa fa-fw fa-circle-o-notch fa-spin',
  TIMES_CIRCLE: 'fa fa-times-circle',
  TRASH: 'fa fa-trash',
  WORK_PHONE: 'fa fa-briefcase fa-fw',
  X: 'fa fa-times'
}
