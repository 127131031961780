import moment from 'moment'

export const formValidatorForAppointmentDate = (date: moment.Moment) => {
  if (!date) {
    return true
  }

  const today = moment()

  if (!date.isSameOrAfter(today, 'day')) {
    throw new Error(`Date must be on or after ${today.format('MM/DD/YYYY')}`)
  }

  return true
}
