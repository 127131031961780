export default {
  ACTION: 'Mark all as read',
  CONFIRM_PROMPT: {
    ORDER_OWNER_DESCRIPTION:
      'This action will only apply to all unread notifications where you’re listed as the order owner.',
    COMPANY_DESCRIPTION: 'This action will apply to all unread notifications across the entire company.',
    OK: 'Mark all as read',
    SUCCESS: 'All notifications marked as read',
    UNDO: 'Undo'
  }
}
