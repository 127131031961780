import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const LastnameColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Signers',
    dataIndex: 'lastname',
    defaultSortOrder: defaultSortOrder('lastname', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'lastname',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column lastname-column',
    render: (lastname: string, order: Order) => {
      return (
        <>
          <div>
            <Typography.Text>{lastname}</Typography.Text>
          </div>
          {order.coLastName && lastname !== order.coLastName && (
            <div>
              <Typography.Text>{order.coLastName}</Typography.Text>
            </div>
          )}
          <div>
            <Typography.Text type="secondary">
              {order.city}, {order.state}
            </Typography.Text>
          </div>
        </>
      )
    }
  }
}
