const USER_ROLES = {
  CONSUMER: 'consumer',
  COMPANY_ADMIN: 'company_admin',
  COMPANY_MEMBER: 'company_member',
  SETTLEMENT_AGENT: 'settlement_agent',
  CLOSING_PARTICIPANT: 'closing_participant',
  SCHEDULER: 'scheduler'
}

const FORM_VALUES = {
  // When we need more location data, look into an open-source package.
  US_STATES: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    // Fake states
    'PR',
    'GU',
    'VI',
    'AA',
    'AE',
    'AP'
  ],
  APPOINTMENT_TIMES: [
    'ASAP',
    'T.B.D.',
    'Morning',
    'Afternoon',
    'Evening',
    '6:00 am',
    '6:15 am',
    '6:30 am',
    '6:45 am',
    '7:00 am',
    '7:15 am',
    '7:30 am',
    '7:45 am',
    '8:00 am',
    '8:15 am',
    '8:30 am',
    '8:45 am',
    '9:00 am',
    '9:15 am',
    '9:30 am',
    '9:45 am',
    '10:00 am',
    '10:15 am',
    '10:30 am',
    '10:45 am',
    '11:00 am',
    '11:15 am',
    '11:30 am',
    '11:45 am',
    '12:00 pm',
    '12:15 pm',
    '12:30 pm',
    '12:45 pm',
    '1:00 pm',
    '1:15 pm',
    '1:30 pm',
    '1:45 pm',
    '2:00 pm',
    '2:15 pm',
    '2:30 pm',
    '2:45 pm',
    '3:00 pm',
    '3:15 pm',
    '3:30 pm',
    '3:45 pm',
    '4:00 pm',
    '4:15 pm',
    '4:30 pm',
    '4:45 pm',
    '5:00 pm',
    '5:15 pm',
    '5:30 pm',
    '5:45 pm',
    '6:00 pm',
    '6:15 pm',
    '6:30 pm',
    '6:45 pm',
    '7:00 pm',
    '7:15 pm',
    '7:30 pm',
    '7:45 pm',
    '8:00 pm',
    '8:15 pm',
    '8:30 pm',
    '8:45 pm',
    '9:00 pm',
    '9:15 pm',
    '9:30 pm',
    '9:45 pm',
    '10:00 pm',
    '10:15 pm',
    '10:30 pm',
    '10:45 pm',
    '11:00 pm',
    '11:15 pm',
    '11:30 pm',
    '11:45 pm',
    '12:00 am',
    '12:15 am',
    '12:30 am',
    '12:45 am',
    '1:00 am',
    '1:15 am',
    '1:30 am',
    '1:45 am',
    '2:00 am',
    '2:15 am',
    '2:30 am',
    '2:45 am',
    '3:00 am',
    '3:15 am',
    '3:30 am',
    '3:45 am',
    '4:00 am',
    '4:15 am',
    '4:30 am',
    '4:45 am',
    '5:00 am',
    '5:15 am',
    '5:30 am',
    '5:45 am'
  ],
  APPOINTMENT_TIMES_PREFIXES: ['at', 'before', 'after'],
  LANGUAGES: [
    'Afrikaans',
    'Albanian',
    'American Sign Language',
    'Arabic',
    'Armenian',
    'Basque',
    'Bengali',
    'Bulgarian',
    'Catalan',
    'Central Khmer',
    'Chinese',
    'Chinese - Cantonese',
    'Chinese - Mandarin',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Fijian',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Gujarati',
    'Haitian Creole',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'IPEN - DocVerify',
    'IPEN - Pavaso',
    'IPEN - Nexsys',
    'Irish',
    'Italian',
    'Japanese',
    'Korean',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Modern Greek (1453-)',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Panjabi',
    'Pashto',
    'Pavaso',
    'Persian',
    'Polish',
    'Portuguese',
    'Quechua',
    'Romanian',
    'RON - Staavy',
    'Russian',
    'Samoan',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tonga (Tonga Islands)',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa'
  ],
  ORDER_SHIPPING_SERVICES: [
    'FedEx - Priority overnight',
    'FedEx - First overnight',
    'Drop off at office',
    'Courier - Pick up from consumer',
    'Courier - Pick up from notary',
    'Courier - Pick up from lender',
    'Courier - Pick up from nearby branch office',
    'None'
  ],
  TRANSACTION_TYPES: [
    'Buyer',
    'Seller',
    'Refinance',
    'Reverse mortgage',
    'Heloc',
    'Debt settlement',
    'Other',
    'Not provided'
  ]
}

const SNAPDOCS_CONTACT_INFO = {
  PHONE: '1-800-224-0511',
  EMAIL: 'help@snapdocs.com'
}

const SCANBACK_RETURN_CHOICE_DEADLINES = {
  SAME_DAY: 'before_same_day_cutoff',
  WITHIN_2_HOURS: 'within_2_hours',
  WITHIN_3_HOURS: 'within_3_hours',
  WITHIN_4_HOURS: 'within_4_hours',
  WITHIN_5_HOURS: 'within_5_hours',
  WITHIN_6_HOURS: 'within_6_hours',
  NOT_SPECIFIED: 'not_specified'
}

const SCANBACK_RETURN_CHOICES = {
  UPLOAD: 'upload_to_snapdocs',
  EMAIL: 'email',
  FAX: 'fax',
  OTHER: 'other',
  NOT_SPECIFIED: 'not_specified'
}

const API_SCHEDULING_V1_BASE_PATH = '/api/scheduling/v1/'
const JSON_HEADERS = { Accept: 'application/json', 'Content-Type': 'application/json' }

const ACCESS_LEVELS = [
  {
    label: 'View & Comment',
    value: 'view_and_comment',
    description: 'Can only view a closing and write comments in the comments section'
  },
  {
    label: 'Closing Editor',
    value: 'closer',
    description: 'Closing Editors can only access and edit closings they’ve been assigned to'
  },
  {
    label: 'Manager',
    value: 'manager',
    description: 'Managers can view and edit all closings, but cannot change company settings'
  },
  {
    label: 'Admin',
    value: 'admin',
    description: "Admins can change your company's settings, as well as view and edit all closings"
  }
]

const USER_ACTIVITY_STATES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export {
  USER_ROLES,
  FORM_VALUES,
  SCANBACK_RETURN_CHOICE_DEADLINES,
  SCANBACK_RETURN_CHOICES,
  SNAPDOCS_CONTACT_INFO,
  API_SCHEDULING_V1_BASE_PATH,
  JSON_HEADERS,
  ACCESS_LEVELS,
  USER_ACTIVITY_STATES
  // Add new global constants here...
}

const GLOBAL = {
  USER_ROLES,
  FORM_VALUES,
  SCANBACK_RETURN_CHOICE_DEADLINES,
  SCANBACK_RETURN_CHOICES,
  SNAPDOCS_CONTACT_INFO,
  API_SCHEDULING_V1_BASE_PATH,
  JSON_HEADERS
  // Add new global constants here...
}

export default GLOBAL
