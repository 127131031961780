import { Form, Input, Button, Divider } from 'antd'
import React, { FC, ReactElement } from 'react'
import { snakeCaseToCamelCase } from '../../../utils/snakeCaseToCamelCase'
import { apiCall, Requests } from '../../../api'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import { Client } from '../../../models/client/client'
import './styles.modules.scss'
import { ButtonLink } from '../../Shared/ButtonLink'

export const AddClientModalForm: FC = (): ReactElement => {
  const closeModal = () => {
    const modal = document.getElementById('add-client-modal') as HTMLInputElement
    const backdrops = document.getElementsByClassName('modal-backdrop') as HTMLCollection

    for (let i = 0; i < backdrops.length; i++) {
      const backdrop = backdrops[i] as HTMLInputElement
      backdrop.style.display = 'none'
    }

    modal.style.display = 'none'
    modal.classList.remove('show')
  }

  const saveClient = (client: Client) => {
    const params = {
      ...snakeCaseToCamelCase(client)
    }

    // TODO: MNS-2710 - Switch this out to use the Redux store if possible. Had to timebox this
    // because it proved tricky getting the id of the newly created client.
    apiCall
      .post(Requests.clients(params).path, {
        ...camelCaseToSnakeCase(params)
      })
      .then((resp) => {
        // TODO: MNS-2360 - Can be removed after the entire create order form is migrated to React.
        // hide modal in hacky non bootstrap way b/c jquery won't work here
        const client = resp.data

        closeModal()

        // add new client to select dropdown
        const clientSelect = document.getElementById('client-select') as HTMLSelectElement

        if (clientSelect) {
          const opt = document.createElement('option')
          opt.value = client.id
          opt.innerText = client.name
          clientSelect[0] = opt
        }

        // have to re-enable scrolling on the page because select2 disables it
        document.body.style.overflow = 'visible'
      })
  }

  return (
    <>
      <Form colon={false} labelCol={{ span: 8 }} onFinish={saveClient}>
        <Form.Item
          label="Company name"
          name="companyName"
          rules={[{ required: true, message: 'Company name can’t be blank' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Instructions to notary"
          name="clientInstructions"
          rules={[{ required: true, message: 'Instructions can’t be blank' }]}
        >
          <Input.TextArea
            rows={4}
            placeholder={"These instructions will be sent to each notary that is assigned to this company's orders"}
          />
        </Form.Item>

        <Form.Item>
          You can manage client information on the{' '}
          <a href="/clients" target="_blank">
            client
          </a>{' '}
          page.
        </Form.Item>

        <Divider />

        <Form.Item name="submit">
          <ButtonLink callback={closeModal} className="cancel-button">
            Cancel
          </ButtonLink>
          <Button type="primary" htmlType="submit" className="ant-btn-lg">
            Create client
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
