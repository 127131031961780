import React from 'react'
import classnames from 'classnames'
import s from './Spinner.module.scss'

type SpinnerProps = {
  classNames: string
  isLoading: boolean
}

export const Spinner: React.FC<SpinnerProps> = ({ classNames, isLoading }): React.ReactElement => {
  const classes = classnames(s.spinner, classNames, {
    [s.spinner_hidden]: !isLoading
  })

  return <div data-test="spinner" className={classes} />
}
