import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip } from 'antd'
import './styles.modules.scss'

export const VerificationStatusColumn = () => {
  return {
    title: <Icons.Check data-test="verification-status-column-title" />,
    dataIndex: 'verified',
    className: 'table-column verification-status-column',
    render: (verified: boolean) => {
      return (
        <>
          {verified && (
            <Tooltip title="Verified by Snapdocs">
              <Icons.CheckCircle className="color-ant-green-4 notary-verified-icon" data-test="notary-verified-icon" />
            </Tooltip>
          )}
        </>
      )
    }
  }
}
