import { combineReducers } from '@reduxjs/toolkit'
import { resourceReducer } from 'redux-resource'
import { reset } from 'redux-resource-plugins'

const resourceReducerWithPlugins = (resourceType: string) => {
  return resourceReducer(resourceType, {
    plugins: [reset]
  })
}

const reducers: Array<string> = [
  'appConfig',
  'company',
  'notifications',
  'notificationSettings',
  'unreadNotificationsCount',
  'dashboardCounts',
  'orderComment',
  'emailSettings',
  'featurePolicies',
  'clients',
  'products',
  'pendingOrderSigners',
  'orders',
  'postClosingReviews',
  'clientTeamMembers',
  'users',
  'adminNotaries',
  'observerEmails',
  'qcReport'
]

const rootReducer = combineReducers(
  reducers.reduce((memo, key) => ({ ...memo, [key]: resourceReducerWithPlugins(key) }), {})
)

export default rootReducer
