import React, { FC, ReactElement } from 'react'
import { Avatar, Col } from 'antd'
import { CompanyNameProps } from './types'

export const CompanyName: FC<CompanyNameProps> = ({ notification }): ReactElement => {
  if (!notification.companyName) {
    return <></>
  }

  return (
    <Col flex="230px">
      <div>
        <Avatar
          size="small"
          shape="square"
          className="company-favicon"
          style={{ margin: '0 15px 0 0', width: '18px', height: '18px' }}
          src={notification.companyFaviconUrl}
        />
        <span>{notification.companyName}</span>
      </div>
    </Col>
  )
}
