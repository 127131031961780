import React, { FC, ReactElement } from 'react'
import { Button, Select, Form } from 'antd'
import { values } from 'lodash'
import { Snapdocs } from '../../../../../../hooks/api'
import { Requests } from '../../../../../../api'
import { SignersStepProps } from '../../types'
import { camelCaseToSnakeCase } from '../../../../../../utils/camelCaseToSnakeCase'

export const SignersStep: FC<SignersStepProps> = ({ params, onFinish, onValuesChange, isLoading }): ReactElement => {
  const [form] = Form.useForm()
  const { response: signers } = Snapdocs.useResources(
    Requests.pendingOrderSigners({ ...camelCaseToSnakeCase(params), pendingOrderId: params.fileNumber })
  )

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <Form.Item label="Signer" name="signer">
          <Select showSearch placeholder="Select signer" optionFilterProp="children">
            {values(signers).map((signer) => (
              <Select.Option value={signer.id} key={signer.id}>
                {signer.firstName} {signer.lastName} {signer.role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Co-signer" name="coSigner">
          <Select showSearch placeholder="Select co-signer" optionFilterProp="children">
            {values(signers).map((coSigner) => (
              <Select.Option value={coSigner.id} key={coSigner.id}>
                {coSigner.firstName} {coSigner.lastName} {coSigner.role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isLoading}>
            Import Order
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
