import React, { FC, ReactElement } from 'react'
import { AutoReminderNoteProps } from '../../types'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip, Tag } from 'antd'
import moment from 'moment-timezone'

export const AutoReminderNote: FC<AutoReminderNoteProps> = ({ reminderSentAt, tooltipTitle }): ReactElement => {
  const reminderSentAtFormatted = (reminderSentAt: string) => {
    const dateTime = moment.utc(reminderSentAt)

    if (moment.utc(reminderSentAt).isSame(moment.utc(), 'day')) {
      return dateTime.format('h:mm a')
    }

    return dateTime.format('M/D/YY h:mm a')
  }

  return (
    <>
      {reminderSentAt && (
        <Tooltip title={`${tooltipTitle} at ${reminderSentAtFormatted(reminderSentAt)}`}>
          <Tag className="status-tag">
            <Icons.ClockCircle className="auto-reminder-icon" />
            Auto-reminded
          </Tag>
        </Tooltip>
      )}
    </>
  )
}
