import React from 'react'
import { get, keyBy, snakeCase } from 'lodash'
import { actionTypes } from 'redux-resource'
import { RootState, store } from '../../store'
import { apiCall } from '../../api'
import { useAppSelector } from '../useAppSelector'
import { reset } from 'redux-resource-plugins'
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase'

const dispatchPending = <T>(args: T) => store.dispatch({ ...args, type: actionTypes.READ_RESOURCES_PENDING })
const dispatchSucceeded = <T>(args: T) => store.dispatch({ ...args, type: actionTypes.READ_RESOURCES_SUCCEEDED })
const dispatchFailed = <T>(args: T) => store.dispatch({ ...args, type: actionTypes.READ_RESOURCES_FAILED })
const slice = (state: RootState, type: string) => get(state, type)

const getResources = <T extends { path: string; type: string }>(state: RootState, config: T) => {
  const { path, type } = config
  const request = get(slice(state, type), ['requests', path])
  const getResource = (id: number) => get(slice(state, type), ['resources', id])

  return Object.prototype.hasOwnProperty.call(config, 'id')
    ? getResource(get(request, ['ids', 0]) || get(config, 'id'))
    : keyBy(get(request, ['ids'], []).map(getResource), 'id')
}

const getRequest = <T extends { path: string; type: string }>(state: RootState, config: T) => {
  return get(slice(state, config.type), ['requests', config.path])
}

const fetchResources = async <T extends { path: string; type: string }>(config: T, force: boolean = false) => {
  const { path, type } = config
  if (!path) return
  if (getRequest(store.getState(), config) && !force) return
  dispatchPending({ requestKey: path, resourceType: type })

  try {
    const response = await apiCall.get(path, { params: get(config, 'params', {}) })
    const data = get(response, ['data', snakeCase(type)])
    const resources = Array.isArray(data)
      ? data.map((resource) => snakeCaseToCamelCase(resource))
      : [snakeCaseToCamelCase(data)]
    const meta = snakeCaseToCamelCase(get(response, ['data', 'meta'], {}))
    dispatchSucceeded({ requestKey: path, resourceType: type, resources, requestProperties: { meta } })

    return get(response, ['data', snakeCase(type)])
  } catch (e) {
    dispatchFailed({ requestKey: path, resourceType: type, requestProperties: { response: e.response.data } })

    return e.response.data
  }
}

const useResources = <T extends { path: string; type: string }>(config: T) => {
  React.useEffect(() => {
    Snapdocs.fetchResources(config)
  })
  return useAppSelector((state: RootState) => ({
    request: getRequest(state, config),
    response: Snapdocs.getResources(state, config)
  }))
}

const dispatchReset = (type: string) => store.dispatch(reset.resetResource(type))

export const Snapdocs = { dispatchReset, getResources, fetchResources, useResources }
