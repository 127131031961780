import React, { FC, ReactElement } from 'react'
import { SettingsBlock } from './SettingsBlock'
import { Setting, SettingsT } from './types'
import { match } from './utils'

const settings: Setting[] = [
  {
    key: 'orderDocumentAddedByClient',
    title: 'Document was added or removed by the client',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'client_add_docs.png',
    dependencies: [],
    notificationSettingKeys: ['clientAddedDocument', 'clientRemovedDocument']
  },
  {
    key: 'orderRedrawDocumentsUpdated',
    title: 'Documents were updated or redrawn by the Lender',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['lenderHasUpdatedTheDocuments']
  },
  {
    key: 'orderDocumentAddedByNotary',
    title: 'Document was added by the notary',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_add_docs.png',
    dependencies: [],
    notificationSettingKeys: ['notaryAddedDocuments']
  },
  {
    key: 'orderRushDocumentsAdded',
    title: 'Rush document was added',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['receives_orders_from_closings']
  }
]

export const DocumentUpdatesSettings: FC<SettingsT> = ({
  emailSettings,
  options,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  return (
    <SettingsBlock
      id={emailSettings.id}
      companyId={emailSettings.companyId}
      title={'Document updates'}
      settings={match(settings, emailSettings, options)}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  )
}
