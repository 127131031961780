import React from 'react'
import PropTypes from 'prop-types'
import { FORM_VALUES } from 'constants/GLOBAL'
import { Micro } from 'components/Shared/Typography'

function TimeSelect({ callbackFn, className, hint, selectedTime, timeOptions, isDisabled }) {
  function selectCallback(event) {
    return callbackFn(event.target.value)
  }

  return (
    <div className={className}>
      <select
        data-test="timeSelect"
        className="form-control"
        onChange={selectCallback}
        defaultValue={selectedTime}
        disabled={isDisabled}
      >
        <option />
        {timeOptions.map((timeOption) => (
          <option key={timeOption}>{timeOption}</option>
        ))}
      </select>

      {hint && <Micro muted>{hint}</Micro>}
    </div>
  )
}

TimeSelect.propTypes = {
  callbackFn: PropTypes.func,
  className: PropTypes.string,
  hint: PropTypes.node,
  selectedTime: PropTypes.string,
  timeOptions: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool
}

TimeSelect.defaultProps = {
  callbackFn: () => {},
  className: '',
  hint: undefined,
  selectedTime: '',
  timeOptions: FORM_VALUES.APPOINTMENT_TIMES,
  isDisabled: false
}

export default TimeSelect
