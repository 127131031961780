import React, { FC, ReactElement } from 'react'
import moment from 'moment-timezone'
import { FormattedAppointmentFullTime, AppointmentFullTimeProps } from './types'

const TIME_MAPPING = {
  morning: '9:00 am',
  afternoon: '3:00 pm',
  evening: '8:00 pm'
}

const parseDateTime = (date?: string, time?: string): FormattedAppointmentFullTime => {
  const formattedAppointmentFullTime: FormattedAppointmentFullTime = {} as FormattedAppointmentFullTime

  switch (time) {
    case 't_b_d':
      formattedAppointmentFullTime.text = 'Signing T.B.D'
      break
    case 'asap':
      formattedAppointmentFullTime.text = 'Signing ASAP'
      break
    default:
      if (!date) {
        formattedAppointmentFullTime.text = 'Signing not set'
      } else {
        if (!time || time === '') {
          formattedAppointmentFullTime.text = `Signing ${moment(date).format('M/DD')}`
        } else {
          if (time === 'morning' || time === 'afternoon' || time === 'evening') {
            time = TIME_MAPPING[time]
          }

          const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm a')
          const today = moment()

          if (dateTime.format('DD/MM/YY') === today.format('DD/MM/YY') && dateTime.diff(today) > 0) {
            formattedAppointmentFullTime.text = `Signing in ${dateTime.diff(moment(), 'hours')} hr(s)`
          } else if (dateTime.diff(today) < 0) {
            formattedAppointmentFullTime.text = `Signed ${dateTime.format('M/D h:mm a')}`
            formattedAppointmentFullTime.passed = '(Passed)'
          } else {
            formattedAppointmentFullTime.text = `Signing ${dateTime.format('M/D h:mm a')}`
          }
        }
      }
      break
  }
  return formattedAppointmentFullTime
}

export const AppointmentFullTime: FC<AppointmentFullTimeProps> = ({ date, time }): ReactElement => {
  const formattedAppointmentFullTime: FormattedAppointmentFullTime = parseDateTime(date, time)

  return (
    <>
      <span className="appointment-time">{formattedAppointmentFullTime.text}</span>
      <br />
      {formattedAppointmentFullTime.passed && (
        <span className="appointment-time-passed">{formattedAppointmentFullTime.passed}</span>
      )}
    </>
  )
}
