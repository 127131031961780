import React, { FC, ReactElement } from 'react'
import { Col, Row, Skeleton, Space } from 'antd'

export const NavigationSkeleton: FC<{}> = (): ReactElement => {
  return (
    <div className="navigation-skeleton">
      <Row className="header">
        <Col>
          <Space direction="vertical" size={7}>
            <Skeleton.Input className="text" active />
            <Skeleton.Input className="radio-button" active />
          </Space>
        </Col>
      </Row>
      <Row className="menu">
        <Col>
          <Space direction="vertical" size={24}>
            <Skeleton.Input className="menu-item" active />
            <Skeleton.Input className="submenu-item" active />
            <Skeleton.Input className="submenu-item" active />
            <Skeleton.Input className="submenu-item" active />
            <Skeleton.Input className="submenu-item" active />
            <Skeleton.Input className="submenu-item" active />
            <Skeleton.Input className="menu-item" active />
            <Skeleton.Input className="menu-item" active />
            <Skeleton.Input className="menu-item" active />
            <Skeleton.Input className="menu-item" active />
            <Skeleton.Input className="menu-item" active />
          </Space>
        </Col>
      </Row>
    </div>
  )
}
