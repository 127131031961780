import React, { FC, ReactElement, useState } from 'react'
import { CommentNotification } from './CommentNotification'
import { StandardNotification } from './StandardNotification'
import { NotificationSkeleton } from './Skeleton/NotificationSkeleton'
import { OrderNotificationProps, Notification } from './types'
import { updateResource } from '../../../actions/updateResource'
import { Snapdocs } from '../../../hooks/api'
import { Requests } from '../../../api'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

export const OrderNotification: FC<OrderNotificationProps> = ({ notification, status }): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [fadeOut, setFadeOut] = useState(false)
  const { path: notificationPath, type: notificationType } = Requests.notification({ id: notification.id })
  const dispatch = useAppDispatch()

  const changeReadStatus = (e: Event | null, notification: Notification, readStatus: boolean) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setIsLoading(true)
    dispatch<any>(
      updateResource({
        path: notificationPath,
        type: notificationType,
        resource: notification,
        params: { read: readStatus }
      })
    ).then(() => {
      Snapdocs.dispatchReset('unreadNotificationsCount')
      if (readStatus && status === 'unread') {
        setFadeOut(true)
      }
      setIsLoading(false)
    })
  }

  const goToOrder = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, notification: Notification) => {
    if (event.metaKey || event.ctrlKey) {
      changeReadStatus(null, notification, true)
      return
    }

    event.preventDefault()
    dispatch<any>(
      updateResource({
        path: notificationPath,
        type: notificationType,
        resource: notification,
        params: { read: true }
      })
    ).then(() => {
      window.location.href = notification.orderPath
    })
  }

  if (fadeOut) {
    return <></>
  }

  if (isLoading) {
    return <NotificationSkeleton />
  }

  let notificationComponent

  if (notification.classification === 'order_comments') {
    notificationComponent = <CommentNotification notification={notification} changeReadStatus={changeReadStatus} />
  } else {
    notificationComponent = <StandardNotification notification={notification} changeReadStatus={changeReadStatus} />
  }

  return (
    <a href={notification.orderPath} onClick={(e) => goToOrder(e, notification)}>
      {notificationComponent}
    </a>
  )
}
