import moment from 'moment-timezone'

export const timeOptions = () => {
  const options = [
    ['ASAP', 'asap'],
    ['T.B.D.', 't_b_d'],
    ['Morning', 'morning'],
    ['Afternoon', 'afternoon'],
    ['Evening', 'evening']
  ]

  const start = moment().startOf('day').add(6, 'hours')
  for (let i = 0; i < 24 * 4; i++) {
    const time = moment(start)
      .add(15 * i, 'minutes')
      .format('h:mm a')
    options.push([time, time])
  }
  return options
}
