export const searchOptions: Record<string, string> = {
  lastname: 'Last Name',
  firstname: 'First Name',
  city: 'City',
  state: 'State',
  clientCompanyName: 'Client',
  clientTeamMemberUserLastName: 'Client Contact Last Name',
  productName: 'Product',
  ownerFullName: 'Order Owner',
  orderNumber: 'Order #',
  escrowNumber: 'File #',
  notaryLastName: 'Notary Last Name',
  status: 'Status',
  completedStatus: 'Signing Status',
  lender: 'Lender',
  appointmentDate: 'Appointment Date'
}
