import React, { FC, ReactElement } from 'react'
import { SchedulerCommentFormProps } from './types'
import { CommentForm } from '../CommentForm'
import { UserRole } from 'models/user/user'
import SchedulerCommentFormMessages from './SchedulerCommentForm.messages'

export const SchedulerCommentForm: FC<SchedulerCommentFormProps> = ({
  commentRecipients,
  orderCommentPath,
  showNotarySmsText,
  csrfToken,
  orderHasParticipants
}): ReactElement => {
  return (
    <CommentForm
      commentRecipients={commentRecipients}
      userRole={UserRole.Scheduler}
      showNotarySmsText={showNotarySmsText}
      toFieldHelpText={SchedulerCommentFormMessages.TO.HELP}
      toFieldSelectText={SchedulerCommentFormMessages.TO.SELECT}
      textFieldPlaceHolder={SchedulerCommentFormMessages.TEXT.PLACEHOLDER}
      orderCommentPath={orderCommentPath}
      csrfToken={csrfToken}
      orderHasParticipants={orderHasParticipants}
    />
  )
}
