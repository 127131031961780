import React from 'react'
import Callout from 'components/Shared/Callout'

const errorBoundary = (Component, message) =>
  class extends React.Component {
    state = {}

    componentDidCatch(error) {
      this.setState({ error })
    }

    render() {
      if (this.state.error) {
        return (
          <Callout color="red">{message || 'Sorry, something went wrong. Please try refreshing the page.'}</Callout>
        )
      }

      return <Component {...this.props} />
    }
  }

export default errorBoundary
