// TODO: Refactor once r_witness_required is fully enabled
// https://snapdocs-eng.atlassian.net/browse/MNS-5923

import React, { FC, ReactElement } from 'react'
import { ProductFormBodyProps } from '../types'
import { OldProductFormBody } from '../OldProductFormBody/OldProductFormBody'
import { NewProductFormBody } from '../NewProductFormBody/NewProductFormBody'

export const ProductFormBody: FC<ProductFormBodyProps> = (props): ReactElement => {
  const { allowWitnessRequired } = props

  if (allowWitnessRequired) {
    return <NewProductFormBody {...props} />
  } else {
    return <OldProductFormBody {...props} />
  }
}
