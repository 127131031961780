import { Form, Checkbox, Switch, Divider, Select } from 'antd'
import React, { FC, ReactElement } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { InputWithDescription } from './InputWithDescription'
import { NewAdvancedSettingsProps } from '../types'

export const NewAdvancedSettings: FC<NewAdvancedSettingsProps> = ({
  companyHasAugmentedClientExperience,
  companyAutoStartAutomator,
  showSpecialInstructions,
  setShowSpecialInstructions,
  showExternalDocs,
  setShowExternalDocs
}): ReactElement => {
  const { Option } = Select

  const toggleShowExternalDocs = () => {
    setShowExternalDocs(!showExternalDocs)
  }

  const toggleShowSpecialInstructions = () => {
    setShowSpecialInstructions(!showSpecialInstructions)
  }

  return (
    <>
      <Divider />

      {companyAutoStartAutomator && (
        <Form.Item label="Auto-start automator" name="autoStartAutomator" valuePropName="checked" className="mb-0">
          <Switch />
        </Form.Item>
      )}

      <Form.Item label="External documents" className="mb-0">
        <InputWithDescription
          InputType={Switch}
          checked={showExternalDocs}
          onChange={toggleShowExternalDocs}
          data-test="show-external-documents-switch"
        >
          <span className="product-item-explanation">
            Transfer documents to notaries{' '}
            <a href="https://support.snapdocs.com/en-us/external-documents-HypS6_yFi" target="blank">
              outside of Snapdocs
            </a>
          </span>
        </InputWithDescription>
      </Form.Item>

      {showExternalDocs && (
        <Form.Item name="autosetDocStatus" wrapperCol={{ offset: 8, span: 16 }}>
          <Select allowClear>
            <Option value="none">None</Option>
            <Option value="notary_picked_up_docs">Notary picked up docs</Option>
            <Option value="overnighted">Docs overnighted</Option>
            <Option value="at_closing">Docs at closing</Option>
            <Option value="emailed_to_notary">Docs emailed to notary</Option>
          </Select>
        </Form.Item>
      )}

      {companyHasAugmentedClientExperience && (
        <Form.Item label="Client assignment" name="allowClientsToPreArrangeNotaries" valuePropName="checked">
          <InputWithDescription InputType={Switch}>
            <span className="product-item-explanation">Allow clients to directly assign pre-arranged notaries</span>
          </InputWithDescription>
        </Form.Item>
      )}

      <Form.Item label="Shared notary info">
        <>
          <Form.Item name="ccNotaryPhone" valuePropName="checked" noStyle>
            <Checkbox>Phone number</Checkbox>
          </Form.Item>
          <Form.Item name="ccNotaryEmail" valuePropName="checked" noStyle>
            <Checkbox>Email</Checkbox>
          </Form.Item>
          <Form.Item name="ccNotaryAddress" valuePropName="checked" noStyle>
            <Checkbox>Address</Checkbox>
          </Form.Item>
          <div className="shared-notary-info">
            Client team member will see these notary details on the Client Confirmation
          </div>
        </>
      </Form.Item>

      <Form.Item label="Additional instructions" className="mb-0">
        <InputWithDescription
          InputType={Switch}
          defaultChecked={showSpecialInstructions}
          onChange={toggleShowSpecialInstructions}
          data-test="show-special-instructions-switch"
        >
          <span className="product-item-explanation">Add extra instructions to the notary for this product</span>
        </InputWithDescription>
      </Form.Item>

      {showSpecialInstructions && (
        <Form.Item name="specialInstructions" wrapperCol={{ offset: 8, span: 16 }}>
          <ReactQuill theme="snow" />
        </Form.Item>
      )}
    </>
  )
}
