import React from 'react'
import classnames from 'classnames'
import defaultImage from 'assets/images/100x100.gif'
import s from './Avatar.module.scss'

export interface AvatarProps {
  hasImage?: boolean
  imageUrl?: string
  initials?: string
  imageSize?: 'sm' | 'md' | 'lg'
  className?: string
}

export const Avatar: React.FC<AvatarProps> = ({ hasImage, imageUrl, initials, imageSize, className }) => {
  const imageSizeClass = imageSize ? s[`avatar_${imageSize}`] : ''

  let imageElement = <img className={s.avatar__img} src={hasImage ? imageUrl : defaultImage} alt="Avatar" />

  if (!hasImage && !!initials) {
    imageElement = <div className={`${s.avatar__img} ${s.avatar__img_placeholder}`}>{initials}</div>
  }

  return <div className={classnames(s.avatar, imageSizeClass, className)}>{imageElement}</div>
}
