import { Icon, Icons } from '../Shared/Ant'
import { Form, Input, Select, Checkbox, Row, Col } from 'antd'
import React, { FC, ReactElement, useState, useEffect } from 'react'
import { ScanbackReturnChoice, ScanbacksProps, ScanbackReturnChoiceName, ScanbackReturnChoiceDeadline } from './types'
import InputMask from 'react-input-mask'
import { formValidatorForPhone } from '../../utils/validatePhone'
import { formValidatorForArrayofThreeEmails } from '../../utils/validateEmail'
import { SCANBACK_RETURN_CHOICE_DEADLINES, SCANBACK_RETURN_CHOICES } from '../../constants/GLOBAL'

const defaultScanbackReturnChoice: ScanbackReturnChoice = {
  id: -1,
  name: '',
  value: '',
  preferred: false
}

export const Scanbacks: FC<ScanbacksProps> = ({
  product,
  scanbackReturnChoices,
  preferredReturnChoice,
  setScanbackReturnChoices,
  setReturnDeadline,
  returnDeadline
}): ReactElement => {
  const { Option } = Select
  const [showAllowedReturnMethods, setShowAllowedReturnMethods] = useState(false)

  const [uploadAllowed, setUploadAllowed] = useState(false)

  const [emailAllowed, setEmailAllowed] = useState(false)
  const [emailAllowedValue, setEmailAllowedValue] = useState('')
  const [showEmailInput, setShowEmailInput] = useState(false)

  const [faxAllowed, setFaxAllowed] = useState(false)
  const [showFaxInput, setShowFaxInput] = useState(false)
  const [faxAllowedValue, setFaxAllowedValue] = useState('')

  const [otherReturnAllowed, setOtherReturnAllowed] = useState(false)
  const [otherAllowedValue, setOtherAllowedValue] = useState('')
  const [showOtherInput, setShowOtherInput] = useState(false)

  const [preferredReturnMethod, setPreferredReturnMethod] = useState(defaultScanbackReturnChoice)

  const toggleUploadAllowed = () => {
    setUploadAllowed(!uploadAllowed)
    addOrRemoveFromReturnChoices(!uploadAllowed, 'upload_to_snapdocs', false)
  }

  const toggleEmailAllowed = () => {
    setEmailAllowed(!emailAllowed)
    addOrRemoveFromReturnChoices(!emailAllowed, 'email', false)
  }

  const toggleFaxAllowed = () => {
    setFaxAllowed(!faxAllowed)
    addOrRemoveFromReturnChoices(!faxAllowed, 'fax', false)
  }

  const toggleOtherReturnAllowed = () => {
    setOtherReturnAllowed(!otherReturnAllowed)
    addOrRemoveFromReturnChoices(!otherReturnAllowed, 'other', false)
  }

  const addOrRemoveFromReturnChoices = (
    create: boolean,
    name: ScanbackReturnChoiceName,
    fromPreferredDropdown: boolean
  ) => {
    if (create) {
      createReturnChoice(name, fromPreferredDropdown)
    } else {
      deleteReturnChoice(name)
    }
  }

  const handleFaxAllowedValue = (faxNumber: string) => {
    setFaxAllowedValue(faxNumber)
    updateChoiceValue('fax', faxNumber)
  }

  const replacePreferredReturnChoice = (name: ScanbackReturnChoiceName) => {
    deleteReturnChoice(preferredReturnMethod.name)
    createReturnChoice(name, true)
  }

  const deleteReturnChoice = (name: string) => {
    const indexToDelete = scanbackReturnChoices.findIndex((choice) => choice.name === name)
    scanbackReturnChoices.splice(indexToDelete, 1)
    setScanbackReturnChoices(scanbackReturnChoices)
  }

  const createReturnChoice = (name: ScanbackReturnChoiceName, fromPreferredDropdown: boolean) => {
    const newChoice: ScanbackReturnChoice = { id: -1, name: name, value: '', preferred: fromPreferredDropdown }
    const updatedChoices = scanbackReturnChoices.concat([newChoice])
    setScanbackReturnChoices(updatedChoices)

    if (fromPreferredDropdown || preferredReturnMethod.name === '') {
      setPreferredReturnMethod(newChoice)
      showPreferredInput(name)
    }
  }

  const updateChoiceValue = (choiceName: ScanbackReturnChoiceName, choiceValue: string) => {
    const updateChoiceIndex = scanbackReturnChoices.findIndex((choice) => choice.name === choiceName)
    scanbackReturnChoices[updateChoiceIndex].value = choiceValue
    setScanbackReturnChoices(scanbackReturnChoices)
  }

  const handleReturnDeadline = (value: ScanbackReturnChoiceDeadline) => {
    setReturnDeadline(value)
  }

  const handleShowAllowedReturnMethods = () => {
    setShowAllowedReturnMethods(true)
  }

  const setAllowedReturnChoices = () => {
    scanbackReturnChoices.forEach(function (choice) {
      if (choice.name === SCANBACK_RETURN_CHOICES.EMAIL) {
        setEmailAllowedValue(choice.value)
        setEmailAllowed(true)
      } else if (choice.name === SCANBACK_RETURN_CHOICES.FAX) {
        setFaxAllowedValue(choice.value)
        setFaxAllowed(true)
      } else if (choice.name === SCANBACK_RETURN_CHOICES.OTHER) {
        setOtherAllowedValue(choice.value)
        setOtherReturnAllowed(true)
      } else if (choice.name === SCANBACK_RETURN_CHOICES.UPLOAD) {
        setUploadAllowed(true)
      }
    })

    if (scanbackReturnChoices.length > 1) {
      handleShowAllowedReturnMethods()
    }
  }

  const handlePreferredReturnMethod = (name: ScanbackReturnChoiceName) => {
    if (showAllowedReturnMethods) {
      updateOrAddPreferredReturnChoice(name)
    } else {
      replacePreferredReturnChoice(name)
    }

    showPreferredInput(name)
  }

  const showPreferredInput = (name: ScanbackReturnChoiceName) => {
    if (name === SCANBACK_RETURN_CHOICES.EMAIL) {
      hidePreferredMethodInput()
      setEmailAllowed(true)
      setShowEmailInput(true)
    } else if (name === SCANBACK_RETURN_CHOICES.FAX) {
      hidePreferredMethodInput()
      setFaxAllowed(true)
      setShowFaxInput(true)
    } else if (name === SCANBACK_RETURN_CHOICES.OTHER) {
      hidePreferredMethodInput()
      setOtherReturnAllowed(true)
      setShowOtherInput(true)
    } else if (name === SCANBACK_RETURN_CHOICES.UPLOAD) {
      hidePreferredMethodInput()
      setUploadAllowed(true)
    } else if (name === SCANBACK_RETURN_CHOICES.NOT_SPECIFIED) {
      hidePreferredMethodInput()
    } else {
      setPreferredReturnMethod(defaultScanbackReturnChoice)
      hidePreferredMethodInput()
    }
  }

  const updateOrAddPreferredReturnChoice = (name: ScanbackReturnChoiceName) => {
    if (scanbackReturnChoices.map((choice) => choice.name).includes(name)) {
      const updateChoiceIndex = scanbackReturnChoices.findIndex((choice) => choice.name === name)
      scanbackReturnChoices[updateChoiceIndex].preferred = true
      const preferred = scanbackReturnChoices[updateChoiceIndex]
      setPreferredReturnMethod(preferred)
      setScanbackReturnChoices(scanbackReturnChoices)
    } else {
      addOrRemoveFromReturnChoices(true, name, true)
    }
  }

  const hidePreferredMethodInput = () => {
    setShowOtherInput(false)
    setShowFaxInput(false)
    setShowEmailInput(false)
  }

  useEffect(() => {
    if (scanbackReturnChoices) {
      setAllowedReturnChoices()
    }

    if (preferredReturnChoice) {
      handlePreferredReturnMethod(preferredReturnChoice.name)
    }
  }, [])

  return (
    <>
      <Form.Item
        label="Scanback return deadline"
        name="scanbackReturnDeadline"
        placeholder="Tell the notary when you need scanbacks..."
      >
        <>
          <Select allowClear onChange={handleReturnDeadline} defaultValue={returnDeadline}>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.SAME_DAY}>ASAP - before same-day cutoff</Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_2_HOURS}>
              ASAP - within 2 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_3_HOURS}>
              ASAP - within 3 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_4_HOURS}>
              ASAP - within 4 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_5_HOURS}>
              ASAP - within 5 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_6_HOURS}>
              ASAP - within 6 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.NOT_SPECIFIED}>Not specified</Option>
          </Select>

          {returnDeadline === SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_2_HOURS && (
            <div className="turnaround-warning">
              <Icon component={Icons.Warning} />
              Due to the fast turnaround time, there may be fewer notaries able to accommodate this request.
            </div>
          )}
        </>
      </Form.Item>

      <Form.Item
        label="Preferred scanback return method"
        placeholder="Tell the notary how to return scanbacks..."
        className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
      >
        <Select allowClear value={preferredReturnMethod.name} onChange={handlePreferredReturnMethod}>
          <Option value={SCANBACK_RETURN_CHOICES.UPLOAD}>
            Upload to Snapdocs&nbsp;
            <span className="snapdocs-secure">(</span>
            <Icon className="snapdocs-secure" component={Icons.Lock} />
            <span className="snapdocs-secure">&nbsp;Secure &amp; easy access)</span>
          </Option>
          <Option value={SCANBACK_RETURN_CHOICES.EMAIL}>Email</Option>
          <Option value={SCANBACK_RETURN_CHOICES.FAX}>Fax</Option>
          <Option value={SCANBACK_RETURN_CHOICES.OTHER}>Other...</Option>
          <Option value={SCANBACK_RETURN_CHOICES.NOT_SPECIFIED}>Not specified</Option>
        </Select>
      </Form.Item>

      {showEmailInput && (
        <Form.Item
          label="Email"
          name={SCANBACK_RETURN_CHOICES.EMAIL}
          initialValue={emailAllowedValue}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          rules={[
            {
              required: { emailAllowed },
              message:
                'Must be a valid email or list of no more than 3 valid emails separated by commas (e.g. sam@example.com, sara@example.com)',
              validator: formValidatorForArrayofThreeEmails
            }
          ]}
        >
          <Input
            placeholder="scans@example.com, scans@example.com"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateChoiceValue('email', e.currentTarget.value)}
          />
        </Form.Item>
      )}

      {showFaxInput && (
        <Form.Item
          label="Fax"
          name={SCANBACK_RETURN_CHOICES.FAX}
          initialValue={faxAllowedValue}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          rules={[
            {
              required: { faxAllowed },
              message: 'Fax number must be 10 characters.',
              validator: formValidatorForPhone
            }
          ]}
        >
          <InputMask
            mask="(999) 999-9999"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFaxAllowedValue(e.currentTarget.value)}
          />
        </Form.Item>
      )}

      {showOtherInput && (
        <Form.Item
          label="Other"
          name={SCANBACK_RETURN_CHOICES.OTHER}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          defaultValue={otherAllowedValue}
          rules={[{ required: { otherReturnAllowed } }]}
        >
          <Input
            placeholder="Add other return method..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateChoiceValue('other', e.currentTarget.value)}
          />
        </Form.Item>
      )}

      {!showAllowedReturnMethods && (
        <Row>
          <Col className="mb-16 allow-other-return-methods" offset={8}>
            <a onClick={handleShowAllowedReturnMethods}>Allow other return methods?</a>
          </Col>
        </Row>
      )}

      {showAllowedReturnMethods && (
        <Form.Item label="Allowed return methods" placeholder="Add other return method...">
          <div className="allowed-input">
            <Checkbox
              checked={uploadAllowed}
              onClick={toggleUploadAllowed}
              disabled={preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.UPLOAD}
            />{' '}
            Upload to Snapdocs
            {preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.UPLOAD && <span> (preferred)</span>}
          </div>

          <div className="allowed-input">
            <div>
              <Checkbox
                checked={emailAllowed}
                onClick={toggleEmailAllowed}
                disabled={preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.EMAIL}
              />{' '}
              Email
              {preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.EMAIL && <span> (preferred)</span>}
            </div>
            {emailAllowed && preferredReturnMethod.name !== SCANBACK_RETURN_CHOICES.EMAIL && (
              <Form.Item
                noStyle
                label="Email"
                name={SCANBACK_RETURN_CHOICES.EMAIL}
                className="allowed-input"
                initialValue={emailAllowedValue}
                rules={[
                  {
                    required: { emailAllowed },
                    message: 'Enter up to three comma separated emails',
                    validator: formValidatorForArrayofThreeEmails
                  }
                ]}
              >
                <Input
                  placeholder="scans@example.com, scans@example.com"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateChoiceValue('email', e.currentTarget.value)
                  }
                />
              </Form.Item>
            )}
          </div>

          <div className="allowed-input">
            <div>
              <Checkbox
                checked={faxAllowed}
                onClick={toggleFaxAllowed}
                disabled={preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.FAX}
              />{' '}
              Fax
              {preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.FAX && <span> (preferred)</span>}
            </div>
            {faxAllowed && preferredReturnMethod.name !== SCANBACK_RETURN_CHOICES.FAX && (
              <Form.Item
                name={SCANBACK_RETURN_CHOICES.FAX}
                className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
                initialValue={faxAllowedValue}
                rules={[
                  {
                    required: { faxAllowed },
                    message: 'Fax number must be 10 characters.',
                    validator: formValidatorForPhone
                  }
                ]}
              >
                <InputMask
                  mask="(999) 999-9999"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFaxAllowedValue(e.currentTarget.value)}
                />
              </Form.Item>
            )}
          </div>

          <div>
            <Checkbox
              checked={otherReturnAllowed}
              onClick={toggleOtherReturnAllowed}
              disabled={preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.OTHER}
            />{' '}
            Other
            {preferredReturnMethod.name === SCANBACK_RETURN_CHOICES.OTHER && <span> (preferred)</span>}
          </div>
          {otherReturnAllowed && (
            <Form.Item noStyle rules={[{ required: { otherReturnAllowed }, type: 'number' }]}>
              <Input
                placeholder="Other"
                defaultValue={otherAllowedValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateChoiceValue('other', e.currentTarget.value)}
              />
            </Form.Item>
          )}
        </Form.Item>
      )}

      <Form.Item
        label="Additional scanback instructions for notary"
        name="scanbacksInstructions"
        initialValue={product.scanbacksInstructions || ''}
      >
        <Input.TextArea style={{ height: 120 }} />
      </Form.Item>
    </>
  )
}
