import { Icons } from '../../Shared/Ant'
import { Row, Col, Form, InputNumber, Select } from 'antd'
import React, { FC, ReactElement } from 'react'
import { NewStateSpecificPricingProps } from '../types'
import { FORM_VALUES } from '../../../constants/GLOBAL'
import { ReactQuery } from '../../../api'

export const NewStateSpecificPricing: FC<NewStateSpecificPricingProps> = ({
  companyId,
  witnessRequired,
  totalWitnessFee
}): ReactElement => {
  const form = Form.useFormInstance()
  const stateSpecificPricingArray = Form.useWatch('stateSpecificPricingArray', form)

  const { featurePolicies, featurePoliciesLoading } = ReactQuery.useFeaturePoliciesRequest(companyId)

  if (featurePoliciesLoading) {
    return <></>
  }

  return (
    <Form.List name="stateSpecificPricingArray">
      {(fields: { name: number; key: number; fieldKey: number }[], { add, remove }: any) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => {
            const stateSpecificPricing = stateSpecificPricingArray && stateSpecificPricingArray[index]
            const notaryFee = stateSpecificPricing?.notaryFee || 0
            const clientFee = stateSpecificPricing?.clientFee || 0
            const notaryFeeWithWitnessFee = notaryFee + totalWitnessFee
            const clientFeeWithWitnessFee = clientFee + totalWitnessFee

            return (
              <div key={key}>
                <Row data-test={`state-specific-pricing-row-${index}`} justify="left">
                  <Col span={4} offset={8}>
                    <div>State</div>
                    <Form.Item name={[name, 'state']} {...restField}>
                      <Select showSearch>
                        {FORM_VALUES.US_STATES.map((us_state: string) => (
                          <Select.Option key={us_state} value={us_state}>
                            {us_state}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <div>Pay Notary</div>
                    <Form.Item type="money" name={[name, 'notaryFee']} {...restField}>
                      <InputNumber step="0.01" min={0} prefix="$" />
                    </Form.Item>
                  </Col>

                  {!featurePolicies.titleCompany && (
                    <Col span={5}>
                      <div>Charge Client</div>
                      <Form.Item type="money" name={[name, 'clientFee']} {...restField}>
                        <InputNumber step="0.01" min={0} prefix="$" />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={2} className="vertical-center">
                    <Icons.Close onClick={() => remove(name)} className="close-icon" data-test="state-close-icon" />
                  </Col>
                </Row>

                {witnessRequired && (
                  <Row className="state-pricing-label-row">
                    <Col span={6} offset={8}>
                      <div>Total with witness fee:</div>
                    </Col>
                    <Col span={3} style={{ textAlign: 'right' }}>
                      <div style={{ maxWidth: '75%' }}>{`$${notaryFeeWithWitnessFee.toFixed(2)}`}</div>
                    </Col>
                    {!featurePolicies.titleCompany && (
                      <Col span={5} style={{ textAlign: 'right' }}>
                        <div style={{ maxWidth: '85%' }}>{`$${clientFeeWithWitnessFee.toFixed(2)}`}</div>
                      </Col>
                    )}
                    <Col span={2}></Col>
                  </Row>
                )}
              </div>
            )
          })}
          <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="shared-notary-info">
            <a onClick={() => add()}>+ Add another state</a>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}
