import React, { FC, ReactElement } from 'react'
import { Col, Row, Skeleton, Space } from 'antd'

export const NotificationSkeleton: FC<{}> = (): ReactElement => {
  return (
    <div className="order-notification">
      <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Col flex="250px">
          <Space>
            <Skeleton.Avatar active style={{ width: 20, height: 20 }} shape="circle" />
            <Skeleton.Input style={{ width: 107, height: 16 }} active />
          </Space>
        </Col>
        <Col flex="auto">
          <Space direction="vertical">
            <Skeleton.Input style={{ width: 107, height: 16 }} active />
            <Skeleton.Input style={{ width: 506, height: 16 }} active />
          </Space>
        </Col>
        <Col flex="200px">
          <div className="text-right">
            <Space direction="vertical">
              <Skeleton.Input style={{ width: 107, height: 16 }} active />
              <Skeleton.Input style={{ width: 200, height: 16 }} active />
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  )
}
