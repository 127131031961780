import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEqual, upperFirst } from 'lodash'
import s from './Select.module.scss'

class Select extends React.Component {
  state = {
    errors: []
  }

  componentDidMount() {
    this.setErrors(this.props.errors)
  }

  onChange = (e) => {
    this.setErrors()

    this.props.callback(this.props.name, e.target.value)
  }

  setErrors(newErrors) {
    this.setState({ errors: newErrors || [] })
  }

  componenetDidUpdate(prevProps) {
    if (!isEqual(this.props.errors, prevProps.errors)) {
      this.setErrors(this.props.errors)
    }
  }

  render() {
    const wrapperClasses = classnames({
      field_with_errors: this.state.errors.length
    })

    const labelElement = this.props.label ? (
      <label className={s.formElement__label} htmlFor={this.props.id}>
        {this.props.label}
      </label>
    ) : null

    const hintElement = this.props.hint ? (
      <div className={s.formElement__hint} htmlFor={this.props.id}>
        {this.props.hint}
      </div>
    ) : null

    const errorElements = this.state.errors.map((error) => (
      <div className={s.formElement__error} key={error}>
        {upperFirst(error)}
      </div>
    ))

    return (
      <div className={classnames(this.props.className, s.formElement)}>
        {labelElement}

        <div className={wrapperClasses}>
          <select
            id={this.props.id}
            className={classnames('form-control', {
              [s.selectPlaceholder]: !this.props.value
            })}
            name={this.props.name}
            value={this.props.value || ''}
            onChange={this.onChange}
            disabled={this.props.disabled}
          >
            <option value="">{this.props.placeholder}</option>

            {this.props.options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>

          {errorElements}
          {hintElement}
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      disabled: PropTypes.bool
    })
  ),
  callback: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool
}

Select.defaultProps = {
  value: '',
  options: [],
  callback: () => null,
  id: null,
  label: null,
  placeholder: '',
  hint: '',
  errors: [],
  className: '',
  disabled: false
}

export default Select
