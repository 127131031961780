import React, { FC, ReactElement, useEffect, useState } from 'react'
import axios from 'axios'
import './styles.modules.scss'
import '../Shared/Ant/App.less'
import { camelCase, compact, get, isNil, isEmpty, cloneDeep, omitBy, set } from 'lodash'
import { Icon, Icons } from '../Shared/Ant'
import { Button, Table, Input } from 'antd'
import { MyTeamProps, CompanyTeamMember, SorterProp } from './types'
import { NameColumn } from './Columns/NameColumn'
import { AccessLevelColumn } from './Columns/AccessLevelColumn'
import { StatusColumn } from './Columns/StatusColumn'
import { LastActiveColumn } from './Columns/LastActiveColumn'
import { QCAccessColumn } from './Columns/QCAccessColumn'
import EditTeamMemberModal from './EditTeamMemberModal/EditTeamMemberModal'
import ExportCsvModal from './ExportCsvModal'
import * as qs from 'qs'
import { tableChange } from '../../utils/table/tableChange'
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase'
import Close from '../../components/Shared/Icons/Close/Close'

const { Search } = Input

const MyTeam: FC<MyTeamProps> = ({
  companyTeamMembersPath,
  bulkCtmCreationPath,
  exportCsvPath,
  companyName,
  csrfToken,
  currentUserId,
  cqcRestrictUsers
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState([])
  const [meta, setMeta] = useState({})
  const [searchParams, setSearchParams] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [editModalVisible, setEditModalVisible] = React.useState(false)
  const [exportModalVisible, setExportModalVisible] = React.useState(false)
  const [selectedTeamMember, setSelectedTeamMember] = React.useState<CompanyTeamMember>()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const sorter = get(searchParams, ['q', 's'], '').split(' ')
  const sortColumn = sorter.length > 1 ? camelCase(sorter[0]) : ''
  const sortOrder = sorter.length > 1 ? sorter[1] : ''

  useEffect(() => {
    fetchTeamMemberData()
  }, [searchParams])

  const fetchTeamMemberData = async () => {
    const teamMembersQueryPath = `${companyTeamMembersPath}?${qs.stringify(searchParams, { arrayFormat: 'brackets' })}`
    setLoading(true)
    await axios
      .get(teamMembersQueryPath)
      .then((response) => {
        setTeamMembers(response.data.team_members.map((teamMember: any) => snakeCaseToCamelCase(teamMember)))
        setMeta(snakeCaseToCamelCase(response.data.meta))
      })
      .finally(() => setLoading(false))
  }

  const updateQCAccess = async (adding: boolean) => {
    const path = `${companyTeamMembersPath}/bulk_update_qc_access`
    const data = { adding, ctm_ids: selectedRowKeys }
    await axios
      .post(path, data, {
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken
        }
      })
      .then(() => fetchTeamMemberData())
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 64,
    onChange: setSelectedRowKeys,
    selections: isEmpty(selectedRowKeys)
      ? null
      : [
          {
            key: 'grant',
            text: 'Grant QC report access',
            onSelect: () => updateQCAccess(true)
          },
          {
            key: 'revoke',
            text: 'Revoke QC report access',
            onSelect: () => updateQCAccess(false)
          }
        ],
    // prevents opening edit modal when clicking selection column
    renderCell: (_1: any, _2: any, _3: any, node: React.ReactNode) => (
      <div onClick={(e) => e.stopPropagation()} className="selectionCell">
        {node}
      </div>
    )
  }

  const setFilter = (filter: Record<string, string>) => {
    const params = cloneDeep(searchParams)
    Object.assign(params, filter)
    set(params, 'page', '1')
    setSearchParams(omitBy(params, isNil))
  }

  const submitSearch = (searchTerm: string) => {
    setFilter({ search: searchTerm })
  }

  const resetSearch = () => {
    setSearchValue('')
    submitSearch('')
  }

  const tableColumns = compact([
    NameColumn(sortColumn, sortOrder),
    AccessLevelColumn(sortColumn, sortOrder, setFilter),
    cqcRestrictUsers && QCAccessColumn(sortColumn, sortOrder, setFilter),
    StatusColumn(sortColumn, sortOrder, setFilter),
    LastActiveColumn(sortColumn, sortOrder)
  ])

  const onTableChange = (
    pagination: { current: number },
    filters: Record<keyof CompanyTeamMember, string[]>,
    sorter: SorterProp
  ) => {
    let params = cloneDeep(searchParams)
    params = tableChange({ pagination, filters, sorter, params })
    setSearchParams(params)
  }

  const addTeamMember = () => {
    window.location.href = bulkCtmCreationPath
  }

  const editTeamMember = (teamMember: CompanyTeamMember) => {
    setSelectedTeamMember(teamMember)
    setEditModalVisible(true)
  }

  const hideEditModal = (updateSaved: boolean) => {
    setSelectedTeamMember(undefined)
    setEditModalVisible(false)

    if (updateSaved) {
      fetchTeamMemberData()
    }
  }

  const openExportModal = () => {
    setExportModalVisible(true)
  }

  const hideExportModal = () => {
    setExportModalVisible(false)
  }

  return (
    <div className="my-team mrxxxl">
      <h3 className="mbxxl mtxl">My Team</h3>
      <div className="flex space-between">
        <div className="flex">
          <Search
            placeholder="Search name or email..."
            size="large"
            style={{ width: 300 }}
            value={searchValue}
            onChange={(e: { target: { value: string } }) => setSearchValue(e.target.value)}
            onSearch={submitSearch}
            allowClear
          />
          {get(searchParams, 'search')?.length ? (
            <Button type="default" className="mll" onClick={resetSearch} icon={<Icon component={Close} />} size="large">
              Reset search
            </Button>
          ) : null}
        </div>
        <div className="flex">
          <Button
            type="primary"
            size="large"
            className="mrl"
            icon={<Icon component={Icons.Plus} />}
            onClick={addTeamMember}
          >
            Add team member
          </Button>
          <Button size="large" type="default" icon={<Icon component={Icons.Download} />} onClick={openExportModal}>
            Export current view
          </Button>
        </div>
      </div>
      <Table
        bordered
        size="small"
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={teamMembers}
        columns={tableColumns}
        onChange={onTableChange}
        loading={{
          spinning: loading,
          tip: 'Loading'
        }}
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          showQuickJumper: false,
          showSizeChanger: false,
          current: get(meta, 'currentPage'),
          pageSize: get(meta, 'perPage'),
          total: get(meta, 'totalEntries')
        }}
        rowSelection={cqcRestrictUsers && rowSelection}
        onRow={(teamMember: CompanyTeamMember) => {
          return { onClick: () => editTeamMember(teamMember) }
        }}
      />
      {selectedTeamMember && (
        <EditTeamMemberModal
          visible={editModalVisible}
          teamMember={selectedTeamMember}
          companyName={companyName}
          onCancel={hideEditModal}
          companyTeamMembersPath={companyTeamMembersPath}
          csrfToken={csrfToken}
          currentUserId={currentUserId}
          cqcRestrictUsers={cqcRestrictUsers}
        />
      )}
      <ExportCsvModal
        visible={exportModalVisible}
        exportCsvPath={exportCsvPath}
        onCancel={hideExportModal}
        totalRecords={get(meta, 'totalEntries')}
        searchParams={qs.stringify(searchParams, { arrayFormat: 'brackets' })}
      />
    </div>
  )
}

export default MyTeam
