import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const NotaryIdColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'ID',
    dataIndex: 'id',
    defaultSortOrder: defaultSortOrder('id', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'id',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.NUM_ASC,
      descText: SORT_TEXTS.NUM_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column notary-id-column',
    render: (id: number) => {
      return <div className="notary-id-column">{id && <Typography.Text>{id}</Typography.Text>}</div>
    }
  }
}
