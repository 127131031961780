import React, { FC, ReactElement, useState } from 'react'
import { Button } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { createResource } from '../../../../../actions/createResource'
import { Requests } from '../../../../../api/requests'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { ApprovePostClosingProps } from '../../types'
import { Order } from '../../../../../models/order/order'
import { PostClosingStatus } from '../../../../../models/order/postClosingStatus'
import { set } from 'lodash'
import { updateResourceSync } from '../../../../../actions/updateResourceSync'

export const ApprovePostClosing: FC<ApprovePostClosingProps> = ({ order }): ReactElement => {
  const dispatch = useAppDispatch()
  const ordersType = Requests.orders({}).type
  const [isLoading, setIsLoading] = useState(false)

  const onApprove = (order: Order) => {
    setIsLoading(true)
    dispatch(
      createResource(
        Requests.postClosingReviews({
          orderId: order.id,
          closingStatus: PostClosingStatus.Approved
        })
      )
    ).then(() => {
      updateResourceSync(set({}, ordersType, set({}, order.id, { postClosingStatus: PostClosingStatus.Approved })))
      setIsLoading(false)
    })
  }

  return (
    <Button size="small" onClick={() => onApprove(order)} loading={isLoading}>
      Approve
    </Button>
  )
}
