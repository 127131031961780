import React from 'react'
import { Typography } from 'antd'
import { Requests } from '../../../../../api/requests'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const OrderNumberColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Order #',
    dataIndex: 'orderNumber',
    defaultSortOrder: defaultSortOrder('orderNumber', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'orderNumber',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.NUM_ASC,
      descText: SORT_TEXTS.NUM_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column order-number-column',
    render: (orderNumber: number, order: Order) => {
      return (
        <>
          <div>
            <a href={Requests.order({ id: order.id }).path}>{orderNumber}</a>
          </div>
          <div>
            <Typography.Text type="secondary">{order.escrowNumber}</Typography.Text>
          </div>
        </>
      )
    }
  }
}
