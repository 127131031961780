import React, { FC, ReactElement, useState } from 'react'
import { Form } from 'antd'
import qs from 'qs'
import { get, set, invoke, snakeCase } from 'lodash'
import { BoardFilterForm } from './BoardFilterForm'
import { BoardFilterProps } from './types'
import { Moment } from 'moment-timezone'
import { subStringSearch } from '../../../utils/matchStrings'

const parseFilters = (filters: any) => {
  const result = {}
  for (let i = 0; i < filters.length; i++) {
    if (get(filters[i], 'v')) {
      set(result, [filters[i].a[0].name], filters[i].v[0].value)
    }
  }
  return result
}

export const BoardFilter: FC<BoardFilterProps> = ({ searchOptions, defaultFilter }): ReactElement => {
  const [form] = Form.useForm()
  const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const [filters, setFilters] = useState(parseFilters(get(searchParams, ['q', 'c'], [])))
  const addFilter = () => {
    for (const option in searchOptions) {
      const snakeCasedOption = snakeCase(option)
      if (!get(filters, snakeCasedOption)) {
        setFilters({ ...filters, ...set({}, snakeCasedOption, undefined) })
        break
      }
    }
  }

  const removeFilter = (filterToRemove: string) => {
    onSearch(form.getFieldsValue(), filterToRemove)
  }

  const onSearch = <T extends { options: string[]; values: string[] | Moment[] }>(
    formValues: T,
    filterToRemove: string = ''
  ) => {
    const params = []
    const options = get(formValues, 'options', [])
    const values = get(formValues, 'values')

    for (let i = 0; i < options.length; i++) {
      if (options[i] === filterToRemove) continue
      params.push({
        a: [{ name: options[i] }],
        v: [{ value: subStringSearch(options[i], 'date') ? invoke(values[i], 'format', 'MM/DD/YY') : values[i] }]
      })
    }

    set(searchParams, ['q', 'c'], params)
    set(searchParams, 'commit', 'Search')
    set(searchParams, 'utf8', '✓')
    window.location.search = qs.stringify(searchParams)
  }

  const onFieldsChange = <T extends { name: string[] | number[] }[]>(changedFields: T) => {
    const fieldName = get(changedFields[changedFields.length - 1], 'name')

    if (fieldName && fieldName[0] === 'options') {
      const values = get(form.getFieldsValue(), 'values')
      values[fieldName[1]] = undefined
      form.setFieldsValue({ values })
    }
  }

  const onValuesChange = <T extends { options: string[]; values: string[] }>(changedValues: T) => {
    if (!get(changedValues, 'options')) {
      return
    }

    const fieldsValue = form.getFieldsValue()
    const options = get(fieldsValue, 'options', [])
    const values = get(fieldsValue, 'values', [])
    const newFilters = {}

    for (let i = 0; i < options.length; i++) {
      set(newFilters, options[i], values[i])
    }
    setFilters(newFilters)
  }

  return (
    <>
      <Form
        form={form}
        role="form"
        onFinish={<T extends { options: string[]; values: string[] | Moment[] }>(formValues: T) => onSearch(formValues)}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
      >
        {Object.keys(filters).length === 0 && (
          <BoardFilterForm
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            currentFilter=""
            index={0}
            disabled={false}
            searchOptions={searchOptions}
            defaultFilter={defaultFilter}
          />
        )}
        {Object.keys(filters).length > 0 &&
          Object.keys(filters).map((filter, index) => (
            <BoardFilterForm
              filters={filters}
              addFilter={addFilter}
              removeFilter={removeFilter}
              currentFilter={filter}
              index={index}
              disabled={index + 1 !== Object.keys(filters).length}
              key={index}
              searchOptions={searchOptions}
              defaultFilter={defaultFilter}
            />
          ))}
      </Form>
    </>
  )
}
