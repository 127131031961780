export const validateZip = (input: string): string => {
  const strippedInput = input.replace(/[^0-9-]/g, '')
  const zipLength = 5

  if (strippedInput.length > zipLength) {
    const zipBase = strippedInput.slice(0, 5).replace(/-/g, '')
    const zipExtension = strippedInput.slice(5, 10).replace(/-/g, '')

    return `${zipBase}-${zipExtension}`
  }

  return strippedInput.replace(/-/g, '')
}
