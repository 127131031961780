import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './H4.module.scss'

function H4({ className, children, bold, muted, inline, ...props }) {
  const classes = classnames(s.h4, className, {
    [s.bold]: bold,
    [s.muted]: muted,
    [s.inline]: inline
  })

  return (
    <div className={classes} role="heading" aria-level="4" {...props}>
      {children}
    </div>
  )
}

H4.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  bold: PropTypes.bool,
  muted: PropTypes.bool,
  inline: PropTypes.bool
}

H4.defaultProps = {
  className: null,
  children: null,
  bold: false,
  muted: false,
  inline: false
}

export default H4
