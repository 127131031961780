import React, { FC, ReactElement, useState } from 'react'
import { Modal, Icons } from '../../Shared/Ant'
import { Button, Row, Col } from 'antd'
import { PreviewEmailParams } from './types'
import { previewPath } from './utils'

export const PreviewEmail: FC<PreviewEmailParams> = ({ title, previewImage }): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <a className={'preview-link'} onClick={() => setIsModalVisible(true)}>
        <Icons.Open className={'preview-icon'} />
        Preview
      </a>
      <Modal
        width={'35%'}
        title={title}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
            Dismiss
          </Button>
        }
      >
        <Row>
          <Col>
            <img style={{ maxWidth: '100%' }} src={previewPath(previewImage)} />
          </Col>
        </Row>
      </Modal>
    </>
  )
}
