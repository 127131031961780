import React, { FC, ReactElement, useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Checkbox, Form, InputNumber, notification, Select, Space, Spin, Typography } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { AutofundSelection, CqcAutofundSettingsProps, DisabledFields, SettlementOffice } from './types'
import './styles.modules.scss'
import '../../Shared/Ant/App.less'
import { cityStateZip } from '../../../messages/utils/address'

export const CqcAutofundSettings: FC<CqcAutofundSettingsProps> = ({
  autofundSelectionPath,
  settlementOfficesPath,
  csrfToken
}): ReactElement => {
  const [form] = Form.useForm()
  const [autofundSelection, setAutofundSelection] = useState({} as AutofundSelection)
  const [settlementOffices, setSettlementOffices] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    axios.get(autofundSelectionPath).then((response) => {
      setAutofundSelection(response?.data?.data?.attributes || {})
    })

    axios.get(settlementOfficesPath).then((response) => {
      setSettlementOffices(response?.data?.data?.map((office: any) => office.attributes) || [])
    })
  }, [autofundSelectionPath, settlementOfficesPath])

  useEffect(() => {
    reset()
  }, [autofundSelection.id])

  const {
    id,
    minReturnRatePct,
    maxErrorRatePct,
    minMonthlyTransactions,
    rejectHybridToWetConversions,
    rejectSettlementOfficeIds
  } = autofundSelection

  const initialValues = {
    minReturnRatePct,
    maxErrorRatePct,
    minMonthlyTransactions,
    rejectHybridToWetConversions,
    rejectSettlementOfficeIds
  }

  const initiallyDisabledFields = {
    minReturnRatePct: isNil(minReturnRatePct),
    maxErrorRatePct: isNil(maxErrorRatePct),
    minMonthlyTransactions: isNil(minMonthlyTransactions),
    rejectSettlementOfficeIds: isNil(rejectSettlementOfficeIds) || isEmpty(rejectSettlementOfficeIds)
  } as DisabledFields

  const [disabledFields, setDisabledFields] = useState(initiallyDisabledFields)

  const toggleDisabledField = (field: keyof DisabledFields, isArrayField: boolean = false) => {
    if (!disabledFields[field]) {
      form.setFieldsValue({ [field]: isArrayField ? [] : null })
    }

    setDisabledFields({ ...disabledFields, [field]: !disabledFields[field] })
  }

  const settlementOfficeOptions = settlementOffices.map((office: SettlementOffice) => {
    const { name, email, address, city, state, zip } = office

    return {
      value: office.id,
      label: (
        <Space direction="vertical" size={0} className="officeLabelContainer">
          <Typography.Text strong>{name}</Typography.Text>
          <Typography.Text>{email}</Typography.Text>
          <Typography.Text>{address}</Typography.Text>
          <Typography.Text>{cityStateZip(city, state, zip)}</Typography.Text>
        </Space>
      )
    }
  })

  const reset = async () => {
    await form.resetFields()
    setDisabledFields(initiallyDisabledFields)
  }

  const save = (values: AutofundSelection) => {
    setSubmitLoading(true)
    const requestMethod = id ? axios.patch : axios.post
    const body = { data: { id, type: 'autofundSelections', attributes: { ...values } } }
    const headers = {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-Token': csrfToken
      }
    }

    requestMethod(autofundSelectionPath, body, headers)
      .then((response) => {
        setAutofundSelection(response?.data?.data?.attributes || {})
        notification.success({ message: 'Settings updated.', className: 'notification' })
      })
      .catch(() => {
        notification.error({ message: 'Failed to save settings.', className: 'notification' })
      })
      .finally(() => setSubmitLoading(false))
  }

  return (
    <>
      <Typography.Title level={3} className="mtxl mbxxl">
        Auto-fund Configuration Settings
      </Typography.Title>
      <Spin spinning={isEmpty(autofundSelection)} indicator={null}>
        <Form
          form={form}
          disabled={isEmpty(autofundSelection)}
          initialValues={initialValues}
          layout="horizontal"
          requiredMark={false}
          className="autoFundSettingsForm"
          onFinish={save}
        >
          <div className="mbl">
            <Typography.Text type="secondary">
              If a Settlement Company meets the following criteria, they will automatically be approved to auto-fund
              select closings
            </Typography.Text>
          </div>
          <Form.Item
            required
            name="minReturnRatePct"
            className="mbl"
            label={
              <Checkbox
                checked={!disabledFields.minReturnRatePct}
                onChange={() => toggleDisabledField('minReturnRatePct')}
                className="formCheckbox"
              >
                <Typography.Text className="formLabel">Minimum scanback return rate</Typography.Text>
              </Checkbox>
            }
          >
            <InputNumber
              disabled={disabledFields.minReturnRatePct}
              formatter={(value: string) => (value ? `${value}%` : '')}
              parser={(value: string) => value!.replace('%', '')}
              min="0"
              max="100"
              className="formInput"
            />
          </Form.Item>
          <Form.Item
            required
            name="maxErrorRatePct"
            className="mbl"
            label={
              <Checkbox
                checked={!disabledFields.maxErrorRatePct}
                onChange={() => toggleDisabledField('maxErrorRatePct')}
                className="formCheckbox"
              >
                <Typography.Text className="formLabel">Maximum scanback error rate</Typography.Text>
              </Checkbox>
            }
          >
            <InputNumber
              disabled={disabledFields.maxErrorRatePct}
              formatter={(value: string) => (value ? `${value}%` : '')}
              parser={(value: string) => value!.replace('%', '')}
              min="0"
              max="100"
              className="formInput"
            />
          </Form.Item>
          <Form.Item
            required
            name="minMonthlyTransactions"
            className="mbl"
            label={
              <Checkbox
                checked={!disabledFields.minMonthlyTransactions}
                onChange={() => toggleDisabledField('minMonthlyTransactions')}
                className="formCheckbox"
              >
                <Typography.Text className="formLabel">Minimum transactions per month</Typography.Text>
              </Checkbox>
            }
          >
            <InputNumber disabled={disabledFields.minMonthlyTransactions} min="0" className="formInput" />
          </Form.Item>
          <div className="mtxxl mbl">
            <Typography.Text type="secondary">Disable auto-funding under the following conditions</Typography.Text>
          </div>
          <Form.Item name="rejectHybridToWetConversions" className="mbl" valuePropName="checked">
            <Checkbox>
              <Typography.Text>Hybrid to wet conversion</Typography.Text>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="rejectSettlementOfficeIds"
            labelCol={{ span: 24 }}
            label={
              <Checkbox
                checked={!disabledFields.rejectSettlementOfficeIds}
                onChange={() => toggleDisabledField('rejectSettlementOfficeIds', true)}
                className="formCheckbox"
              >
                <Typography.Text className="formLabel">
                  A closing with any of the following settlement partners assigned
                </Typography.Text>
              </Checkbox>
            }
          >
            <Select
              mode="multiple"
              className="rejectSettlementOfficeIds"
              disabled={disabledFields.rejectSettlementOfficeIds || isEmpty(settlementOffices)}
              options={settlementOfficeOptions}
            />
          </Form.Item>
          <Space className="mtxxl">
            <Form.Item>
              <Button disabled={submitLoading} type="default" onClick={reset}>
                Reset
              </Button>
            </Form.Item>
            <Form.Item>
              <Button loading={submitLoading} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Spin>
    </>
  )
}

export default CqcAutofundSettings
