import React, { FC, ReactElement } from 'react'
import { AddSignerDetailProps } from './addSignerDetailProps'
import { AddSignerDetailForm } from './AddSignerDetailForm'
import { Provider } from 'react-redux'
import { store } from '../../../store'

export const AddSignerDetail: FC<AddSignerDetailProps> = (props): ReactElement => {
  return (
    <Provider store={store}>
      <AddSignerDetailForm {...props} />
    </Provider>
  )
}
