import React, { SVGProps } from 'react'

const CaretUp: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.35818 14.7499L11.1415 7.38655C11.6163 6.87115 12.3849 6.87115 12.8585 7.38655L19.6418 14.7499C20.4069 15.5803 19.8653 17 18.7833 17H5.21672C4.13474 17 3.59315 15.5803 4.35818 14.7499Z"
      fill="currentcolor"
    />
  </svg>
)

export default CaretUp
