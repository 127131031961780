import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip, Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { AppointmentConfirmedByType } from '../../../../../models/order/appointmentConfirmedByType'
import OrderHelper from '../../../../../helpers/OrderHelper'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const AppointmentFullTimeColumn = (
  sortColumn: string,
  sortOrder: string,
  orderAppointmentConfirmation: boolean
) => {
  const confirmedTooltipTitle = (appointmentConfirmedByType: AppointmentConfirmedByType | null) => {
    switch (appointmentConfirmedByType) {
      case AppointmentConfirmedByType.CompanyTeamMember:
        return 'Appointment has been confirmed with the consumer'
      case AppointmentConfirmedByType.Notary:
        return 'Notary confirmed appointment with the consumer'
      default:
        return 'Consumer confirmed appointment'
    }
  }

  return {
    title: 'Appointment',
    dataIndex: 'appointmentFullTime',
    defaultSortOrder: defaultSortOrder('appointmentFullTime', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'appointmentFullTime',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.TIME_ASC,
      descText: SORT_TEXTS.TIME_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column appointment-full-time-column',
    render: (appointmentFullTime: string, order: Order) => {
      return (
        <>
          <div>
            {orderAppointmentConfirmation && OrderHelper.isAppointmentConfirmed(order) && (
              <Tooltip title={confirmedTooltipTitle(order.appointmentConfirmedByType)} placement="bottom">
                <Icons.Calendar className="status-icon" />
              </Tooltip>
            )}
            {orderAppointmentConfirmation && OrderHelper.isAppointmentChangeRequested(order) && (
              <Tooltip title="Change Requested" placement="bottom">
                <Icons.ExclamationCircle className="status-icon" />
              </Tooltip>
            )}
            <Typography.Text>{OrderHelper.appointmentTimeForShortPrint(order)}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">{OrderHelper.timeRemainingToAppointmentTime(order)}</Typography.Text>
          </div>
        </>
      )
    }
  }
}
