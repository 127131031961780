import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const CityColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'City',
    dataIndex: 'city',
    defaultSortOrder: defaultSortOrder('city', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'city',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column city-column',
    render: (city: string) => {
      return <>{city && <Typography.Text>{city}</Typography.Text>}</>
    }
  }
}
