import { Icons } from '../Shared/Ant'
import { Form, Select, Row, Col, InputNumber } from 'antd'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Requests } from '../../api/requests'
import { Snapdocs } from '../../hooks/api/snapdocs'
import { StateSpecificPricingRowProps } from './types'

export const StateSpecificPricingRow: FC<StateSpecificPricingRowProps> = ({
  statesList,
  state,
  clientFee,
  notaryFee,
  stateSpecificPricing,
  setStateSpecificPricing,
  companyId,
  witnessRequired,
  totalWitnessFee
}): ReactElement => {
  const [usState, setUsState] = useState('')
  const [updatedClientFee, setClientFee] = useState('')
  const [updatedNotaryFee, setNotaryFee] = useState('')

  const updateNotaryFee = (updatedNotaryFee: string) => {
    setNotaryFee(updatedNotaryFee)
    stateSpecificPricing[state].notaryFee = updatedNotaryFee
    setStateSpecificPricing({ ...stateSpecificPricing })
  }

  const updateClientFee = (updatedClientFee: string) => {
    setClientFee(updatedClientFee)
    stateSpecificPricing[state].clientFee = updatedClientFee
    setStateSpecificPricing({ ...stateSpecificPricing })
  }

  const updateState = (newState: string) => {
    delete Object.assign(stateSpecificPricing, { [newState]: stateSpecificPricing[state] })[state]
    setUsState(newState)
    setStateSpecificPricing({ ...stateSpecificPricing })
  }

  const deleteRow = () => {
    delete stateSpecificPricing[state]
    setStateSpecificPricing({ ...stateSpecificPricing })
  }

  const { request: featurePoliciesRequest, response: featurePolicies } = Snapdocs.useResources(
    Requests.featurePolicies({ companyId })
  )

  const calculateNotaryFeeWithTotalWitnessFee = (): number => {
    return (parseFloat(notaryFee) || 0) + totalWitnessFee
  }

  const calculateClientFeeWithTotalWitnessFee = (): number => {
    return (parseFloat(clientFee) || 0) + totalWitnessFee
  }

  useEffect(() => {
    setUsState(state)
    setClientFee(clientFee)
    setNotaryFee(notaryFee)
  }, [])

  if (Requests.hasLoaded(featurePoliciesRequest)) {
    return (
      <>
        <Row data-test="state-specific-pricing-row" justify="left">
          <Col span={4} offset={8}>
            <div>State</div>
            <Form.Item>
              <Select showSearch value={usState} onSelect={updateState}>
                {statesList.map((us_state: string) => (
                  <Select.Option key={us_state} value={us_state}>
                    {us_state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <div>Pay Notary</div>
            <Form.Item type="money">
              <InputNumber
                step="0.01"
                value={updatedNotaryFee}
                min={0}
                formatter={(value: any) => `$${value}`}
                parser={(value: any) => value.replace('$', '')}
                onChange={updateNotaryFee}
              />
            </Form.Item>
          </Col>

          {!featurePolicies.titleCompany && (
            <Col span={5}>
              <div>Charge Client</div>
              <Form.Item type="money">
                <InputNumber
                  step="0.01"
                  value={updatedClientFee}
                  min={0}
                  formatter={(value: any) => `$${value}`}
                  parser={(value: any) => value.replace('$', '')}
                  onChange={updateClientFee}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={2} className="vertical-center">
            <Icons.Close onClick={deleteRow} className="close-icon" />
          </Col>
        </Row>

        {witnessRequired && (
          <Row className="state-pricing-label-row">
            <Col span={6} offset={8}>
              <div>Total with witness fee:</div>
            </Col>
            <Col span={3} style={{ textAlign: 'right' }}>
              <div style={{ maxWidth: '75%' }}>{`$${calculateNotaryFeeWithTotalWitnessFee().toFixed(2)}`}</div>
            </Col>
            {!featurePolicies.titleCompany && (
              <Col span={5} style={{ textAlign: 'right' }}>
                <div style={{ maxWidth: '85%' }}>{`$${calculateClientFeeWithTotalWitnessFee().toFixed(2)}`}</div>
              </Col>
            )}
            <Col span={2}></Col>
          </Row>
        )}
      </>
    )
  }

  return <></>
}
