import { MASKS } from '../constants/MASKS'

export const validatePhone = (input: string): string => {
  const strippedInput = input.replace(/[_]/g, '')
  const expectedInputFormat = MASKS.PHONE

  // Return the input if it matches the length we expect.
  if (strippedInput && strippedInput.length === expectedInputFormat.length) {
    return input
  }

  // Otherwise, return empty.
  return ''
}

export const formValidatorForPhone = (_: any, value: string) => {
  if (validatePhone(value)) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Number must be 10 characters.'))
}
