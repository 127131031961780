import React, { ReactElement } from 'react'
import { Button } from 'antd'

export const ApplyButton = (): ReactElement => {
  return (
    <Button type="primary" data-test="applyFilterAndSort" htmlType="submit">
      Apply
    </Button>
  )
}
