import React, { SVGProps } from 'react'

const Flag: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.98448 3.00001C3.7196 3.00415 3.46719 3.11304 3.28264 3.30279C3.09809 3.49254 2.99647 3.74764 3.0001 4.01211V13.9966V19.9873C2.99823 20.1196 3.02271 20.251 3.07212 20.3738C3.12153 20.4965 3.1949 20.6083 3.28794 20.7025C3.38098 20.7967 3.49186 20.8716 3.61411 20.9226C3.73637 20.9737 3.86757 21 4.0001 21C4.13263 21 4.26383 20.9737 4.38609 20.9226C4.50834 20.8716 4.61922 20.7967 4.71226 20.7025C4.8053 20.6083 4.87867 20.4965 4.92808 20.3738C4.97749 20.251 5.00197 20.1196 5.0001 19.9873V13.9966H11.7638C12.1428 13.9966 12.4883 14.21 12.6583 14.5485L13.1056 15.4416C13.2746 15.7801 13.6211 15.9935 14.0001 15.9935H20.0001C20.5521 15.9935 21.0001 15.5462 21.0001 14.9951V6.00902C21.0001 5.45787 20.5521 5.01057 20.0001 5.01057H15.2364C14.8574 5.01057 14.5119 4.79716 14.3419 4.45869L13.8946 3.56554C13.7256 3.22707 13.3791 3.01366 13.0001 3.01366H4.1544C4.09825 3.00429 4.0414 2.99973 3.98448 3.00001Z"
      fill="currentcolor"
    />
  </svg>
)

export default Flag
