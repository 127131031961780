// Returns formatted "City, ST"
export const cityState = (city?: string, state?: string): string => {
  const messageArgs: string[] = []

  if (city) messageArgs.push(city)
  if (state) messageArgs.push(state)

  return messageArgs.join(', ')
}

// Returns formatted "City, ST Zip"
export const cityStateZip = (city?: string, state?: string, zip?: string): string => {
  const messageArgs: string[] = []
  const cityStateMessage = cityState(city, state)

  if (cityStateMessage) messageArgs.push(cityStateMessage)
  if (zip) messageArgs.push(zip)

  return messageArgs.join(' ')
}
