import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Shared/FormElements/Select/index'
import { FORM_VALUES } from 'constants/GLOBAL'

function StateSelect({ className, name, value, errors, callback, disabled }) {
  return (
    <Select
      className={className}
      name={name}
      value={value}
      placeholder="State"
      options={FORM_VALUES.US_STATES.map((usState) => ({
        value: usState,
        label: usState
      }))}
      errors={errors}
      callback={callback}
      disabled={disabled}
    />
  )
}

StateSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

StateSelect.defaultProps = {
  className: '',
  name: 'state',
  value: '',
  errors: [],
  disabled: false
}

export default StateSelect
