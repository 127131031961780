import { Row, Col, Avatar, Divider, Button, Form } from 'antd'
import React, { FC, ReactElement } from 'react'
import { createResource } from '../../../actions/createResource'
import { Requests } from '../../../api'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { ExistingUserFormProps } from './props/existingUserFormProps'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import { ButtonLink } from '../../Shared/ButtonLink'
import './styles.modules.scss'

export const ExistingUserForm: FC<ExistingUserFormProps> = ({ user, clientName, closeModal }): ReactElement => {
  const dispatch = useAppDispatch()

  const addClientTeamMember = () => {
    // TODO: MNS-2854 - Replace this with prop from parent after form is completely migrated
    let selectedClient = document.getElementById('order_client_id') as HTMLSelectElement

    if (!selectedClient) {
      selectedClient = document.getElementById('client-select') as HTMLSelectElement
    }

    const clientId = parseInt(selectedClient.value)

    const params = {
      id: user!.id,
      firstName: user!.firstName,
      lastName: user!.lastName
    }

    dispatch(
      createResource(
        Requests.clientTeamMembers({
          clientId: clientId,
          email: user!.email,
          ...camelCaseToSnakeCase(params)
        })
      )
    ).then(() => {
      // TODO: MNS-2360 - Can be removed after the entire create order form is migrated to React.
      // hide modal in hacky non bootstrap way b/c jquery won't work here

      closeModal()

      // add new client to select dropdown
      const clientTeamMemberSelect = document.getElementById('client-team-member-select') as HTMLSelectElement

      if (clientTeamMemberSelect) {
        const opt = document.createElement('option')
        opt.value = user!.id.toString()
        opt.innerText = user!.fullName
        clientTeamMemberSelect[0] = opt
      }

      // have to re-enable scrolling on the page because select2 disables it
      document.body.style.overflow = 'visible'
    })
  }
  return (
    <>
      <Row className="form-row">
        It looks like this user already exists. Would you like to add {user?.fullName} to {clientName}?
      </Row>
      <Row className="avatar-section form-row">
        <Col>
          <Avatar className="avatar" size="large" src={user?.photo} />
        </Col>
        <Col>
          <Row>{user?.fullName}</Row>
          <Row>{user?.email}</Row>
        </Col>
      </Row>

      <Divider />

      <Form.Item>
        <ButtonLink callback={closeModal} className="cancel-button">
          Cancel
        </ButtonLink>
        <Button type="primary" onClick={() => addClientTeamMember()} className="ant-btn-lg">
          Add User
        </Button>
      </Form.Item>
    </>
  )
}
