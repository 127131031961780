import { useEffect, useState } from 'react'

export const useConfirmBrowserExit = (defaultEnabled = false, message = 'Do you want to leave this page?') => {
  const [enabled, setEnabled] = useState(defaultEnabled)

  const listener = (e: BeforeUnloadEvent) => {
    if (!enabled) return

    e.preventDefault()
    return (e.returnValue = message)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [enabled])

  return {
    enable(): void {
      setEnabled(true)
    },
    disable(): void {
      setEnabled(false)
    }
  }
}
