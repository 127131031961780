import React, { SVGProps } from 'react'

const ClockCircle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM14.586 16L11.293 12.707C11.105 12.519 11 12.265 11 12V7C11 6.448 11.448 6 12 6C12.552 6 13 6.448 13 7V11.586L16 14.586C16.39 14.976 16.39 15.61 16 16C15.61 16.39 14.976 16.39 14.586 16Z"
      fill="currentcolor"
    />
  </svg>
)

export default ClockCircle
