import React, { SVGProps } from 'react'

const Thunderbolt: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1C11.8901 1.00001 11.7828 1.03127 11.6925 1.08958C11.6023 1.14789 11.5334 1.23045 11.4952 1.32617L5.16617 12.4688C5.05808 12.6278 5.00049 12.8119 5 13C5 13.2652 5.11346 13.5196 5.31542 13.7071C5.51739 13.8946 5.79131 14 6.07692 14H11.4615V22.5C11.4615 22.6326 11.5183 22.7598 11.6193 22.8536C11.7202 22.9473 11.8572 23 12 23C12.0991 23.0001 12.1963 22.9748 12.2809 22.9269C12.3655 22.879 12.4343 22.8104 12.4796 22.7285L18.8549 11.502L18.8528 11.5C18.9483 11.3482 18.9991 11.1758 19 11C19 10.7348 18.8865 10.4804 18.6846 10.2929C18.4826 10.1054 18.2087 10 17.9231 10H12.5385V1.5C12.5385 1.36739 12.4817 1.24021 12.3808 1.14645C12.2798 1.05268 12.1428 1 12 1Z"
      fill="currentcolor"
    />
  </svg>
)

export default Thunderbolt
