import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'

import { ICONS } from 'icons/index'
import { ORDER_DOC_RETURN_TRACKING as MESSAGES } from 'messages/ORDER_DOC_RETURN_TRACKING'
import { Spinner } from 'components/Shared/Spinner/Spinner'
import { DeliveryDate } from './DeliveryDate/DeliveryDate'
import { Timeline } from './Timeline/Timeline'
import { Event } from './Timeline/organizeTimelineEvents'
import s from './FedexDocReturn.module.scss'

export interface ShippingInfo {
  trackingNumber: string
  updatedAtTime: string
  originalEstimatedDeliveryTime: string
  currentEstimatedDeliveryTime: string
  events: Array<Event>
}

interface FedexDocReturnProps {
  shippingInfo: ShippingInfo
  updateShippingInfoPath: string
}

interface FedexDocReturnState {
  shippingInfo: ShippingInfo
  isLoading: boolean
  hasError: boolean
}

export class FedexDocReturn extends Component<FedexDocReturnProps, FedexDocReturnState> {
  constructor(props: FedexDocReturnProps) {
    super(props)

    this.state = {
      shippingInfo: {
        ...this.props.shippingInfo
      },
      isLoading: false,
      hasError: false
    }

    this.updateShippingInfo = this.updateShippingInfo.bind(this)
    this.getRefreshButton = this.getRefreshButton.bind(this)
    this.getStatusBanner = this.getStatusBanner.bind(this)
  }

  componentDidMount() {
    // Get the latest from the shipping info api
    if (this.props.updateShippingInfoPath) {
      this.updateShippingInfo()
    }
  }

  getRefreshButton() {
    // Don't show the refresh button if we're in the middle of an update.
    if (this.state.isLoading) {
      return null
    }

    return (
      <i
        role="button"
        tabIndex={0}
        onClick={this.updateShippingInfo}
        onKeyPress={this.updateShippingInfo}
        className={ICONS.REFRESH}
      />
    )
  }

  getStatusBanner() {
    if (this.state.isLoading) {
      return (
        <div className={s.statusBanner}>
          <Spinner classNames={s.statusBanner__spinner} isLoading />
          {MESSAGES.FEDEX_DOC_RETURN.LOADING}
        </div>
      )
    }

    if (this.state.hasError) {
      return <div className={s.statusBanner}>{MESSAGES.FEDEX_DOC_RETURN.UPDATE_ERROR}</div>
    }

    return null
  }

  updateShippingInfo() {
    this.setState({
      isLoading: true,
      hasError: false
    })

    axios
      .get(this.props.updateShippingInfoPath)
      .then((response) => {
        this.setState({
          shippingInfo: response.data.shippingInfo,
          isLoading: false
        })
      })
      .catch(() => {
        this.setState({
          hasError: true,
          isLoading: false
        })
      })
  }

  shouldRender() {
    // Make sure there's Shipping Info and a Tracking Number.
    return !_.isEmpty(this.state.shippingInfo) && this.state.shippingInfo.trackingNumber
  }

  render() {
    if (!this.shouldRender()) {
      return <div>{MESSAGES.FEDEX_DOC_RETURN.NOT_AVAILABLE}</div>
    }

    return (
      <div>
        {this.getStatusBanner()}

        <DeliveryDate
          originalEstimatedDeliveryTime={this.state.shippingInfo.originalEstimatedDeliveryTime}
          currentEstimatedDeliveryTime={this.state.shippingInfo.currentEstimatedDeliveryTime}
          actualDeliveryTime={_.get(_.find(this.state.shippingInfo.events, ['type', 'documents_delivered']), 'time')}
          isPickedUp={!!_.find(this.state.shippingInfo.events, ['type', 'documents_received_by_carrier'])}
        />

        <div className={s.trackingInfo}>
          <span className={s.trackingInfo__number}>
            {MESSAGES.FEDEX_DOC_RETURN.TRACKING_NUMBER(this.state.shippingInfo.trackingNumber)}
          </span>
          <span className={s.trackingInfo__lastUpdated}>
            {MESSAGES.FEDEX_DOC_RETURN.LAST_UPDATED(this.state.shippingInfo.updatedAtTime)}
            {this.getRefreshButton()}
          </span>
        </div>

        <Timeline
          originalEstimatedDeliveryTime={this.state.shippingInfo.originalEstimatedDeliveryTime}
          currentEstimatedDeliveryTime={this.state.shippingInfo.currentEstimatedDeliveryTime}
          events={this.state.shippingInfo.events}
        />

        <a
          className={s.trackingLink}
          href={MESSAGES.FEDEX_DOC_RETURN.TRACKING_URL(this.state.shippingInfo.trackingNumber)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {MESSAGES.FEDEX_DOC_RETURN.TRACK_VIA_FEDEX}
        </a>
      </div>
    )
  }
}
