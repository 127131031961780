import { Icons } from '../../Shared/Ant'
import { Form, Input } from 'antd'
import React, { ReactElement } from 'react'

export const DocumentChecklist = (): ReactElement => (
  <Form.List name="documentChecklist">
    {(fields: { name: number; key: number; fieldKey: number }[], { add, remove }: any) => (
      <>
        {fields.map((field, index) => (
          <Form.Item key={field.key} wrapperCol={{ offset: 8, span: 16 }}>
            <div className="shared-notary-info">Item #{index + 1}</div>

            <Form.Item {...field} noStyle>
              <Input
                suffix={
                  fields.length > 1 ? (
                    <Icons.Close
                      onClick={() => remove(field.name)}
                      className="close-icon"
                      data-test={`remove-checklist-${index + 1}`}
                    />
                  ) : null
                }
              />
            </Form.Item>
          </Form.Item>
        ))}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="shared-notary-info">
          <div>
            <a onClick={() => add()}>+ Add another item</a>
          </div>
          Notaries see these instructions prominently before downloading documents.
        </Form.Item>
      </>
    )}
  </Form.List>
)
