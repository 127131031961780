import React, { FC, ReactElement } from 'react'
import { SchedulerCommentForm } from './SchedulerCommentForm/SchedulerCommentForm'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CommentFormWrapperProps } from './types'
import { UserRole } from 'models/user/user'
import { ClientCommentForm } from './ClientCommentForm/ClientCommentForm'
import { NotaryCommentForm } from './NotaryCommentForm/NotaryCommentForm'
import { ObserverCommentForm } from './ObserverCommentForm/ObserverCommentForm'

const queryClient = new QueryClient()

export const CommentFormWrapper: FC<CommentFormWrapperProps> = ({
  commentRecipients,
  orderCommentPath,
  showNotarySmsText,
  userRole,
  csrfToken,
  orderHasParticipants
}): ReactElement => {
  if (userRole === UserRole.Scheduler) {
    return (
      <QueryClientProvider client={queryClient}>
        <SchedulerCommentForm
          commentRecipients={commentRecipients}
          showNotarySmsText={showNotarySmsText}
          orderCommentPath={orderCommentPath}
          csrfToken={csrfToken}
          orderHasParticipants={orderHasParticipants}
        />
      </QueryClientProvider>
    )
  } else if (userRole === UserRole.Client) {
    return (
      <QueryClientProvider client={queryClient}>
        <ClientCommentForm
          commentRecipients={commentRecipients}
          showNotarySmsText={showNotarySmsText}
          orderCommentPath={orderCommentPath}
          csrfToken={csrfToken}
          orderHasParticipants={orderHasParticipants}
        />
      </QueryClientProvider>
    )
  } else if (userRole === UserRole.Notary) {
    return (
      <QueryClientProvider client={queryClient}>
        <NotaryCommentForm
          commentRecipients={commentRecipients}
          showNotarySmsText={showNotarySmsText}
          orderCommentPath={orderCommentPath}
          csrfToken={csrfToken}
          orderHasParticipants={orderHasParticipants}
        />
      </QueryClientProvider>
    )
  } else if (userRole === UserRole.Observer) {
    return (
      <QueryClientProvider client={queryClient}>
        <ObserverCommentForm
          commentRecipients={commentRecipients}
          showNotarySmsText={showNotarySmsText}
          orderCommentPath={orderCommentPath}
          csrfToken={csrfToken}
          orderHasParticipants={orderHasParticipants}
        />
      </QueryClientProvider>
    )
  }

  return <></>
}
