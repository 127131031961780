import React, { SVGProps } from 'react'

const InfoCircle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 17C11.448 17 11 16.552 11 16V12C11 11.448 11.448 11 12 11C12.552 11 13 11.448 13 12V16C13 16.552 12.552 17 12 17ZM12.5 9H11.5C11.224 9 11 8.776 11 8.5V7.5C11 7.224 11.224 7 11.5 7H12.5C12.776 7 13 7.224 13 7.5V8.5C13 8.776 12.776 9 12.5 9Z"
      fill="currentcolor"
    />
  </svg>
)

export default InfoCircle
