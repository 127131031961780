import React from 'react'
import { Typography } from 'antd'
import { Order } from '../../../../../models/order/order'
import { Requests } from '../../../../../api/requests'

export const ScanbacksColumn = () => {
  return {
    title: 'Scanbacks',
    dataIndex: 'scanbacks',
    className: 'table-column scanbacks-column',
    render: (text: string, order: Order) => {
      return (
        <>
          <div>
            <Typography.Text type="secondary">{order.scanbacksRequired ? 'Required' : 'Not required'}</Typography.Text>
          </div>
          <div>
            {order.scanbacksCount > 0 && (
              <a
                type="link"
                data-remote="true"
                href={Requests.attachmentPreview({ orderId: order.id, attachmentId: order.scanbacksLastId }).path}
              >
                Review {order.scanbacksCount} {order.scanbacksCount > 1 ? 'docs' : 'doc'}
              </a>
            )}
          </div>
        </>
      )
    }
  }
}
