/* eslint @typescript-eslint/no-unused-vars: "off" */
import React from 'react'
import classnames from 'classnames'
import s from './Button.module.scss'

const ALLOWED_COLORS = {
  primary: ['blue', 'green', 'red', 'orange'],
  secondary: ['blue', 'red'],
  utility: ['blue'],
  inverse: ['blue']
}

enum ButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Utility = 'utility',
  Inverse = 'inverse'
}

interface ButtonProps {
  style?: string
  color?: string
  size?: string
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  onClick?(): void
  href?: string | null | undefined
  isLoading: boolean
  className?: string
  tooltip?: string
  tooltipFor?: string
  wrapperClassName?: string
  buttonRef?: string
}

export const Button: React.FC<ButtonProps> = ({
  children,
  style,
  color,
  size,
  type,
  fullWidth,
  onClick,
  href,
  isLoading,
  className,
  tooltip,
  tooltipFor,
  wrapperClassName,
  buttonRef,
  ...props
}) => {
  const shouldDisableButton = isLoading
  const buttonClasses = classnames(className, s.button, s[`button_${style}`], s[`button_${size}`], {
    [s[`button_${style}_${color}`]]: style !== 'utility',
    [s.button_isLoading]: isLoading
  })

  // Our button wrapper lets us give the button a "not-allowed cursor".
  const buttonWrapperClasses = classnames(
    s.button__wrapper,
    { [s.button__wrapper_fullWidth]: fullWidth },
    wrapperClassName
  )

  // If our button is disabled, remove the onClick function.
  const onClickAction = !shouldDisableButton ? onClick : () => null

  // If this is a link, return a link
  if (href) {
    const linkUrl = !shouldDisableButton ? href : undefined
    return (
      <div className={buttonWrapperClasses} data-tip={tooltip}>
        <a className={buttonClasses} href={linkUrl} onClick={onClickAction} ref={buttonRef} {...props}>
          {children}
        </a>
      </div>
    )
  }

  return (
    <div className={buttonWrapperClasses} data-tip={tooltip} data-for={tooltipFor}>
      <button className={buttonClasses} onClick={onClickAction} type={type} ref={buttonRef} {...props}>
        {children}
      </button>
    </div>
  )
}

export default Button
