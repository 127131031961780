export const SORT_TEXTS = {
  TIME_ASC: 'Sort soonest to latest',
  TIME_DESC: 'Sort latest to soonest',
  CHAR_ASC: 'Sort A to Z',
  CHAR_DESC: 'Sort Z to A',
  NUM_ASC: 'Sort low to high',
  NUM_DESC: 'Sort high to low',
  CANCEL: 'Cancel sorting'
}

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc'
}
