import qs from 'qs'

export const getQueryParamsState = () => {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true })
}

export const setQueryParamsState = (params: object) => {
  window.history.pushState({}, '', '?' + qs.stringify(params))
  const event = new CustomEvent('queryParamsChanged', { detail: params })
  document.dispatchEvent(event)
}
