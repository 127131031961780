// TODO: Remove once r_witness_required is fully enabled
// https://snapdocs-eng.atlassian.net/browse/MNS-5923

import { Form, Checkbox, Switch, Divider, Select } from 'antd'
import React, { FC, ReactElement, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { AdvancedSettingsProps } from './types'

export const AdvancedSettings: FC<AdvancedSettingsProps> = ({
  product,
  ccNotaryPhone,
  setCcNotaryPhone,
  ccNotaryEmail,
  setCcNotaryEmail,
  ccNotaryAddress,
  setCcNotaryAddress,
  companyHasAugmentedClientExperience,
  hasSpecialInstructions,
  setHasSpecialInstructions,
  externalDocs,
  setExternalDocs,
  showExternalDocs,
  setShowExternalDocs,
  clientAssignment,
  setClientAssignment,
  companyAutoStartAutomator
}): ReactElement => {
  const { Option } = Select

  const toggleExternalDocs = () => {
    setShowExternalDocs(!showExternalDocs)
  }

  const toggleCcNotaryPhone = () => {
    setCcNotaryPhone(!ccNotaryPhone)
  }

  const toggleCcNotaryEmail = () => {
    setCcNotaryEmail(!ccNotaryEmail)
  }

  const toggleCcNotaryAddress = () => {
    setCcNotaryAddress(!ccNotaryAddress)
  }

  const toggleHasSpecialInstructions = () => {
    setHasSpecialInstructions(!hasSpecialInstructions)
  }

  const toggleClientAssignment = () => {
    setClientAssignment(!clientAssignment)
  }

  const handleExternalDocs = (external_docs: string) => {
    setExternalDocs(external_docs)
  }

  useEffect(() => {
    setExternalDocs(product.autosetDocStatus)
    setShowExternalDocs(product.autosetDocStatus !== 'none' && product.autosetDocStatus != null)
  }, [])

  return (
    <>
      <Divider />
      {companyAutoStartAutomator && (
        <Form.Item label="Auto-start automator" name="autoStartAutomator" valuePropName="checked" className="mb-0">
          <Switch />
        </Form.Item>
      )}
      <Form.Item label="External documents" name="autosetDocStatus" className="mb-0">
        <>
          <Switch checked={showExternalDocs} onChange={toggleExternalDocs} />
          <span className="product-item-explanation">
            Transfer documents to notaries{' '}
            <a href="https://support.snapdocs.com/en-us/external-documents-HypS6_yFi" target="blank">
              outside of Snapdocs
            </a>
          </span>
        </>
      </Form.Item>

      {showExternalDocs && (
        <Form.Item name="Select External Doc" wrapperCol={{ offset: 8, span: 16 }}>
          <Select allowClear defaultValue={externalDocs} onChange={handleExternalDocs}>
            <Option value="none">None</Option>
            <Option value="notary_picked_up_docs">Notary picked up docs</Option>
            <Option value="overnighted">Docs overnighted</Option>
            <Option value="at_closing">Docs at closing</Option>
            <Option value="emailed_to_notary">Docs emailed to notary</Option>
          </Select>
        </Form.Item>
      )}

      {companyHasAugmentedClientExperience && (
        <Form.Item label="Client assignment" name="allowClientsToPreArrangeNotaries">
          <>
            <Switch checked={clientAssignment} onChange={toggleClientAssignment} />
            <span className="product-item-explanation">Allow clients to directly assign pre-arranged notaries</span>
          </>
        </Form.Item>
      )}

      <Form.Item label="Shared notary info" name="sharedNotaryInfo">
        <>
          <Checkbox defaultChecked={ccNotaryPhone} onChange={toggleCcNotaryPhone}>
            Phone number
          </Checkbox>
          <Checkbox defaultChecked={ccNotaryEmail} onChange={toggleCcNotaryEmail}>
            Email
          </Checkbox>
          <Checkbox defaultChecked={ccNotaryAddress} onChange={toggleCcNotaryAddress}>
            Address
          </Checkbox>
          <div className="shared-notary-info">
            Client team member will see these notary details on the Client Confirmation
          </div>
        </>
      </Form.Item>

      <Form.Item label="Additional instructions" name="allowClientsToPreArrangeNotaries" className="mb-0">
        <>
          <Switch defaultChecked={hasSpecialInstructions} onChange={toggleHasSpecialInstructions} />
          <span className="product-item-explanation">Add extra instructions to the notary for this product</span>
        </>
      </Form.Item>

      {hasSpecialInstructions && (
        <Form.Item name="specialInstructions" wrapperCol={{ offset: 8, span: 16 }}>
          <ReactQuill theme="snow" />
        </Form.Item>
      )}
    </>
  )
}
