import React from 'react'
import { Icon } from '../../Shared/Ant'
import { Checkbox, Form, Typography } from 'antd'
import '../styles.modules.scss'
import { defaultSortOrder } from '../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../constants/COLUMN_SORTER'
import { CompanyTeamMember } from '../types'
import { ResetButton } from '../ColumnFilterButtons/ResetButton'
import { ApplyButton } from '../ColumnFilterButtons/ApplyButton'
import Filter from '../../../components/Shared/Icons/Filter/Filter'

type AccessLevel = {
  label: string
  description: string
}

const ACCESS_LEVELS: Record<string, AccessLevel> = {
  view_and_comment: {
    label: 'View & Comment',
    description: 'View and enter messages on closings assigned to them.'
  },
  closer: {
    label: 'Closing Editor',
    description: 'View and edit closings assigned to them.'
  },
  manager: {
    label: 'Manager',
    description: 'View and edit all closings.'
  },
  admin: {
    label: 'Admin',
    description: 'View and edit all closings, and change company settings.'
  }
}

export const AccessLevelColumn = (
  sortColumn: string,
  sortOrder: string,
  setFilter: (filter: Record<keyof object, string[]>) => void
) => {
  const [form] = Form.useForm()
  const [isColumnFiltered, setIsColumnFiltered] = React.useState(false)

  const handleReset = async (confirm: () => void) => {
    setFilter({ role: null })
    setIsColumnFiltered(false)
    form.resetFields()
    confirm()
  }

  const handleSubmit = async (confirm: () => void) => {
    const { role } = await form.validateFields()
    if (role?.length > 0) {
      setFilter({ role: role })
      setIsColumnFiltered(true)
    } else {
      setFilter({ role: null })
      setIsColumnFiltered(false)
    }
    confirm()
  }

  return {
    title: 'Access level',
    dataIndex: 'accessLevel',
    defaultSortOrder: defaultSortOrder('accessLevel', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'accessLevel',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    filterDropdown: ({ confirm }: any) => (
      <Form form={form} onFinish={() => handleSubmit(confirm)}>
        <div className="team-table-filter">
          <strong>Filter access level</strong>
          <Form.Item name="role" className="mtxl mbn filter-role-form" valuePropName="checked">
            <Checkbox.Group>
              {Object.keys(ACCESS_LEVELS).map((key: string) => (
                <Checkbox value={key} key={key}>
                  <div className="role-description-container">
                    <strong>{ACCESS_LEVELS[key].label}</strong>
                    <span className="role-description">{ACCESS_LEVELS[key].description}</span>
                  </div>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <div className="display_flex justify_content_space_between">
            <ResetButton onClick={() => handleReset(confirm)} />
            <ApplyButton />
          </div>
        </div>
      </Form>
    ),
    filterIcon: <Icon component={Filter} className={isColumnFiltered ? 'filtered-column-icon' : ''} />,
    render: (text: string, companyTeamMember: CompanyTeamMember) => {
      return (
        <>
          <Typography.Text>{ACCESS_LEVELS[companyTeamMember.role]?.label}</Typography.Text>
        </>
      )
    }
  }
}
