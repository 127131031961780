/* eslint no-useless-escape: "off" */
export const validateEmails = () => {
  return {
    validator: (_: any, emails: string) => {
      if (emails === '') return Promise.resolve()

      const emailList: string[] = emails.replace(/\s/g, '').split(',')
      const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      for (const email of emailList) {
        if (!regex.test(email)) {
          return Promise.reject(new Error('The field should be a valid email or comma separated list of emails'))
        }
      }

      return Promise.resolve()
    }
  }
}
