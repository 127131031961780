import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEqual, upperFirst } from 'lodash'
import s from './styles.module.scss'

class CheckboxInput extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.errors, nextProps.errors)) {
      return {
        errors: nextProps.errors
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      errors: this.props.errors,
      checked: this.props.checked
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({
      errors: [],
      checked: e.target.checked
    })

    this.props.callback(this.props.name, e.target.checked)
  }

  render() {
    const labelElement = (
      <label className={classnames(s.label, 'display_flex', 'align_items_center')} htmlFor={this.props.id}>
        <input
          className="form-check-input man"
          type="checkbox"
          name={this.props.name}
          checked={this.state.checked}
          onChange={this.handleChange}
        />
        {this.props.label && <span className="mlm">{this.props.label}</span>}
      </label>
    )

    const errorElements = this.state.errors.map((error) => (
      <div className={s.error} key={error}>
        {upperFirst(error)}
      </div>
    ))

    const wrapperClasses = classnames({
      field_with_errors: this.state.errors.length
    })

    return (
      <div className={`${this.props.className}`}>
        {labelElement}
        <div className={wrapperClasses}>{errorElements}</div>
      </div>
    )
  }
}

CheckboxInput.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  callback: PropTypes.func,
  checked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
}

CheckboxInput.defaultProps = {
  errors: [],
  callback: () => null,
  checked: false,
  id: null,
  label: null,
  className: ''
}

export default CheckboxInput
