import React, { FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { Settings } from './Settings'
import { NoticesParams } from './types'
import '../../Shared/Ant/App.less'

export const Notices: FC<NoticesParams> = ({
  emailSettingsId,
  notificationSettingsId,
  companyId,
  newCommentUiFlipperEnabled
}): ReactElement => {
  return (
    <Provider store={store}>
      <Settings
        emailSettingsId={emailSettingsId}
        notificationSettingsId={notificationSettingsId}
        companyId={companyId}
        newCommentUiFlipperEnabled={newCommentUiFlipperEnabled}
      />
    </Provider>
  )
}
