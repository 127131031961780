import React from 'react'
import { Typography, Image } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'
import { Notary } from '../../types'

export const NotaryLastNameColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Name',
    dataIndex: 'lastName',
    defaultSortOrder: defaultSortOrder('lastName', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'lastName',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column notary-last-name-column',
    render: (text: string, notary: Notary) => {
      return (
        <>
          {notary.photoSmUrl && (
            <span className="notary-photo-container">
              <Image className="notary-photo" src={notary.photoSmUrl} alt="Notary" />
            </span>
          )}
          {notary.fullName && <Typography.Text className="notary-name">{notary.fullName}</Typography.Text>}
        </>
      )
    }
  }
}
