import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { FormOutlined } from '@ant-design/icons'
import { NotificationIconProps } from './types'

export const NotificationIcon: FC<NotificationIconProps> = ({ classification }): ReactElement => {
  let icon: React.ReactElement

  switch (classification) {
    case 'order_comments':
      icon = <Icons.Message className={'icon'} />
      break
    case 'reschedules':
      icon = <Icons.Calendar className={'icon'} />
      break
    case 'document_updates':
      icon = <Icons.File className={'icon'} />
      break
    case 'did_not_sign':
      icon = <Icons.CloseCircle className={'icon'} />
      break
    case 'new_pre_assigned_order':
      icon = <Icons.User className={'icon'} />
      break
    case 'order_updates':
      icon = <FormOutlined className={'icon'} />
      break
    default:
      icon = <Icons.Message className={'icon'} />
      break
  }
  return icon
}
