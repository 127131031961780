import { actionTypes } from 'redux-resource'
import { apiCall } from '../api'
import { AppDispatch } from '../store'
import { snakeCaseToCamelCase } from '../utils/snakeCaseToCamelCase'

export const readResource = <T extends { id: number }>(args: { path: string; type: string; resource: T }) => (
  dispatch: AppDispatch
) => {
  const { path, type, resource } = args

  dispatch({
    type: actionTypes.READ_RESOURCES_PENDING,
    resourceType: type,
    resources: [resource.id]
  })

  return apiCall
    .request({
      method: 'get',
      url: path
    })
    .then((response) => {
      dispatch({
        type: actionTypes.READ_RESOURCES_SUCCEEDED,
        resourceType: type,
        resources: [snakeCaseToCamelCase(response.data)]
      })
    })
    .catch(() => {
      dispatch({
        type: actionTypes.READ_RESOURCES_FAILED,
        resourceType: type,
        resources: resource.id
      })
    })
}
