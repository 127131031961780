// TODO: Remove once r_witness_required is fully enabled
// https://snapdocs-eng.atlassian.net/browse/MNS-5923

import { Form, Input, InputNumber, Checkbox, Button, Switch, Tooltip, Divider } from 'antd'
import React, { FC, ReactElement, useState, useEffect } from 'react'
import { ProductFormBodyProps, ScanbackReturnChoice, Product } from '../types'
import { AdvancedSettings } from '../AdvancedSettings'
import { Scanbacks } from '../Scanbacks'
import { StateSpecificPricing } from '../StateSpecificPricing'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { Requests } from '../../../api/requests'
import { snakeCaseToCamelCase } from '../../../utils/snakeCaseToCamelCase'

export const OldProductFormBody: FC<ProductFormBodyProps> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  submitBtnText,
  returnChoices,
  preferredReturnChoice,
  fromOrderForm,
  saveProduct
}): ReactElement => {
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
  const [showDocumentChecklist, setShowDocumentChecklist] = useState(false)
  const [documentChecklist, setDocumentChecklist] = useState(new Array<string>())
  const [scanbackReturnChoices, setScanbackReturnChoices] = useState(new Array<ScanbackReturnChoice>())
  const [hybridSigning, setHybridSigning] = useState(false)
  const [autoPriced, setAutoPriced] = useState(false)
  const [scanbacksRequired, setScanbacksRequired] = useState(false)
  const [attorneyRequired, setAttorneyRequired] = useState(false)
  const [returnDeadline, setReturnDeadline] = useState('')
  const [hasSpecialInstructions, setHasSpecialInstructions] = useState(false)
  const [externalDocs, setExternalDocs] = useState('')
  const [showExternalDocs, setShowExternalDocs] = useState(false)
  const [clientAssignment, setClientAssignment] = useState(false)
  const [showStateSpecificPricing, setShowStateSpecificPricing] = useState(false)
  const [stateSpecificPricing, setStateSpecificPricing] = useState({})
  const [showAdjustPrice, setShowAdjustPrice] = useState(false)
  const [showVisibilityForAllClients, setShowVisibilityForAllClients] = useState(false)
  const [companyHasAugmentedClientExperience, setCompanyHasAugmentedClientExperience] = useState(false)
  const [visibleForAllClients, setVisibleForAllClients] = useState(false)
  const [footerOffset, setFooterOffset] = useState(12)
  const [ccNotaryPhone, setCcNotaryPhone] = useState(product.ccNotaryPhone)
  const [ccNotaryEmail, setCcNotaryEmail] = useState(product.ccNotaryEmail)
  const [ccNotaryAddress, setCcNotaryAddress] = useState(product.ccNotaryAddress)

  const toggleScanbacksRequired = () => {
    setScanbacksRequired(!scanbacksRequired)
  }

  const toggleAttorneyRequired = () => {
    setAttorneyRequired(!attorneyRequired)
  }

  const toggleShowAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings)
  }

  const toggleShowStateSpecificPricing = () => {
    setShowStateSpecificPricing(!showStateSpecificPricing)
  }

  const toggleShowDocumentChecklist = () => {
    if (showDocumentChecklist && documentChecklist.length === 0) {
      setDocumentChecklist([''])
    }

    setShowDocumentChecklist(!showDocumentChecklist)
  }

  const toggleHybridSigning = () => {
    setHybridSigning(!hybridSigning)
  }

  const toggleAutoPriced = () => {
    setAutoPriced(!autoPriced)
  }

  const toggleVisibleForAllClients = () => {
    setVisibleForAllClients(!visibleForAllClients)
  }

  const onEditChecklistItem = (newValue: string, index: number) => {
    if (!documentChecklist.includes('')) {
      documentChecklist.push('')
    }
    documentChecklist[index] = newValue
    setDocumentChecklist([...documentChecklist])
  }

  const maybeRemoveFromChecklist = (item: string, index: number) => {
    if (item.length === 0 && index > 0) {
      const indexToDelete = documentChecklist.indexOf(item)
      documentChecklist.splice(indexToDelete, 1)
    }
    setDocumentChecklist([...documentChecklist])
  }

  const { request: companyRequest, response: company } = Snapdocs.useResources(Requests.company({ id: companyId }))
  const { request: featurePoliciesRequest, response: featurePolicies } = Snapdocs.useResources(
    Requests.featurePolicies({ companyId })
  )

  const onFinish = (values: Product) => {
    values.autoPriced = autoPriced
    values.documentChecklist = documentChecklist
    values.hybridSigning = hybridSigning
    values.scanbacksRequired = scanbacksRequired
    values.scanbackReturnDeadline = returnDeadline
    values.allowClientsToPreArrangeNotaries = clientAssignment
    values.scanbackReturnChoices = scanbackReturnChoices
    values.ccNotaryAddress = ccNotaryAddress
    values.ccNotaryEmail = ccNotaryEmail
    values.ccNotaryPhone = ccNotaryPhone
    values.attorneyRequired = attorneyRequired

    if (showExternalDocs) {
      values.autosetDocStatus = externalDocs
    } else {
      values.autosetDocStatus = 'none'
    }

    if (showStateSpecificPricing) {
      values.stateSpecificPricing = stateSpecificPricing
    } else {
      values.stateSpecificPricing = {}
    }

    saveProduct(
      snakeCaseToCamelCase(values),
      showDocumentChecklist,
      hasSpecialInstructions,
      showStateSpecificPricing,
      visibleForAllClients
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (Requests.hasLoaded(companyRequest)) {
      setShowVisibilityForAllClients(company.showVisibilityForAllClients && product.id === null && !fromOrderForm)
      setShowAdjustPrice(company.showAdjustPrice)
      setCompanyHasAugmentedClientExperience(company.hasAugmentedClientExperience)
    }

    setScanbackReturnChoices(returnChoices)
    setHybridSigning(product.hybridSigning)
    setAutoPriced(product.autoPriced)
    setScanbacksRequired(product.scanbacksRequired)
    setReturnDeadline(product.scanbackReturnDeadline)
    setHasSpecialInstructions(!!product.specialInstructions)
    setClientAssignment(product.allowClientsToPreArrangeNotaries)
    setStateSpecificPricing(product.stateSpecificPricing)

    if (product.stateSpecificPricing) {
      setShowStateSpecificPricing(Object.keys(product.stateSpecificPricing).length > 0)
    }

    if (product.documentChecklist) {
      setShowDocumentChecklist(product.documentChecklist.length > 0)
      if (product.documentChecklist.length > 0) {
        setDocumentChecklist(product.documentChecklist)
      } else {
        setDocumentChecklist([''])
      }
    }

    setAttorneyRequired(product.attorneyRequired)

    if (fromOrderForm) {
      setFooterOffset(10)
      setVisibleForAllClients(true)
    }
  }, [companyRequest])

  if (Requests.hasLoaded(featurePoliciesRequest)) {
    return (
      <>
        <Form
          id="addProductForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ ...product }}
          colon={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Product name"
            name="productName"
            rules={[{ required: true, message: 'Please input your product name.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Pay notary" name="vendorFee" className={showAdjustPrice ? 'mb-0' : ''} type="money">
            <InputNumber
              step="0.01"
              min={0}
              formatter={(value: any) => `$${value}`}
              parser={(value: any) => value.replace('$', '')}
            />
          </Form.Item>

          {showAdjustPrice && (
            <Form.Item name="autoPriced" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox checked={autoPriced} onChange={toggleAutoPriced}>
                Adjust notary fee based on location
                <Tooltip title="Intelligently adjust notary fees for difficult locations based on local market rates. Never exceeds $10 more than your base fee.">
                  <a href="#" className="product-item-tooltip">
                    (?)
                  </a>
                </Tooltip>
              </Checkbox>
            </Form.Item>
          )}

          {!featurePolicies.titleCompany && (
            <Form.Item label="Charge client" name="clientFee" type="money">
              <InputNumber
                step="0.01"
                min={0}
                formatter={(value: any) => `$${value}`}
                parser={(value: any) => value.replace('$', '')}
              />
            </Form.Item>
          )}

          {(companyHasAugmentedClientExperience || allowStateSpecificPricing) && (
            <>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Switch checked={showStateSpecificPricing} onChange={toggleShowStateSpecificPricing} />
                <span className="product-item-explanation">Charge client differently per state</span>
              </Form.Item>

              {showStateSpecificPricing && (
                <StateSpecificPricing
                  stateSpecificPricing={stateSpecificPricing}
                  setStateSpecificPricing={setStateSpecificPricing}
                  companyId={companyId}
                  witnessRequired={false}
                  totalWitnessFee={0}
                />
              )}
            </>
          )}

          {featurePolicies.hybridClosing && (
            <Form.Item label="Hybrid signing" name="hybridSigning">
              <Switch checked={hybridSigning} onChange={toggleHybridSigning} />
              <span className="product-item-explanation">Consumer has the option to e-sign non critical documents</span>
              <Tooltip title="This has the advantage of cheaper, faster notary appointment with fewer papers to print and sign.">
                <a href="#" className="product-item-tooltip">
                  (?)
                </a>
              </Tooltip>
            </Form.Item>
          )}

          {showVisibilityForAllClients && (
            <Form.Item label="Visible?" name="visibleForAllClients">
              <Switch defaultChecked={visibleForAllClients} onChange={toggleVisibleForAllClients} />
              <span className="product-item-explanation">Set product default visibility for all clients</span>
            </Form.Item>
          )}

          <Form.Item label="Document checklist?" name="documentChecklistCheckbox" valuePropName="checked">
            <Switch checked={showDocumentChecklist} onChange={toggleShowDocumentChecklist} />
            <span className="product-item-explanation">Show document-related checklist to the notary</span>
          </Form.Item>

          {showDocumentChecklist && (
            <>
              {documentChecklist.map(function (item: string, index: number) {
                return (
                  <Form.Item key={'form-item-' + index} wrapperCol={{ offset: 8, span: 16 }}>
                    <div className="shared-notary-info">Item #{index + 1}</div>
                    <Input
                      key={'item-' + index}
                      defaultValue={item}
                      onBlur={() => maybeRemoveFromChecklist(item, index)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onEditChecklistItem(e.currentTarget.value, index)
                      }
                    />
                  </Form.Item>
                )
              })}

              <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="shared-notary-info">
                Notaries see these instructions prominently before downloading documents.
              </Form.Item>
            </>
          )}

          <Divider />

          <Form.Item label="Require attorney?" name="attorneyRequired" valuePropName="checked">
            <Switch onChange={toggleAttorneyRequired} checked={attorneyRequired} />
            <span className="product-item-explanation">Require attorney</span>
          </Form.Item>

          <Form.Item label="Require scanbacks?" name="scanbacksRequired" valuePropName="checked">
            <Switch onChange={toggleScanbacksRequired} checked={scanbacksRequired} />
            <span className="product-item-explanation">Require notary to upload scanbacks after appointment</span>
          </Form.Item>

          {scanbacksRequired && (
            <Scanbacks
              product={product}
              scanbackReturnChoices={scanbackReturnChoices}
              setScanbackReturnChoices={setScanbackReturnChoices}
              preferredReturnChoice={preferredReturnChoice}
              returnDeadline={returnDeadline}
              setReturnDeadline={setReturnDeadline}
            />
          )}

          {showAdvancedSettings && (
            <AdvancedSettings
              product={product}
              companyHasAugmentedClientExperience={companyHasAugmentedClientExperience}
              hasSpecialInstructions={hasSpecialInstructions}
              setHasSpecialInstructions={setHasSpecialInstructions}
              externalDocs={externalDocs}
              setExternalDocs={setExternalDocs}
              showExternalDocs={showExternalDocs}
              setShowExternalDocs={setShowExternalDocs}
              clientAssignment={clientAssignment}
              setClientAssignment={setClientAssignment}
              ccNotaryPhone={ccNotaryPhone}
              setCcNotaryPhone={setCcNotaryPhone}
              ccNotaryEmail={ccNotaryEmail}
              setCcNotaryEmail={setCcNotaryEmail}
              ccNotaryAddress={ccNotaryAddress}
              setCcNotaryAddress={setCcNotaryAddress}
              companyAutoStartAutomator={company.autoStartAutomator}
            />
          )}

          <Form.Item name="submit" wrapperCol={{ offset: footerOffset, span: 16 }}>
            {!showAdvancedSettings && (
              <a className="product-show-advanced" onClick={toggleShowAdvancedSettings}>
                Show advanced settings
              </a>
            )}

            {showAdvancedSettings && (
              <a className="product-show-advanced" onClick={toggleShowAdvancedSettings}>
                Hide advanced settings
              </a>
            )}

            <Button type="primary" htmlType="submit" className="ant-btn-lg product-submit-btn">
              {submitBtnText}
            </Button>
          </Form.Item>
        </Form>
      </>
    )
  }

  return <></>
}
