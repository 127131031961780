import React, { FC, ReactElement, useState } from 'react'
import { Modal } from '../../../../Shared/Ant'
import { Button, Checkbox, Form, Row, Typography } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { createResource } from '../../../../../actions/createResource'
import { Requests } from '../../../../../api/requests'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { readResource } from '../../../../../actions/readResource'
import { PostClosingErrorFormValues, ReportPostClosingErrorProps } from '../../types'
import { RejectedReasons } from '../../../../../models/postClosingReview/rejectedReasons'
import { PostClosingStatus } from '../../../../../models/order/postClosingStatus'

const options = [
  { label: 'Missing stamp', value: RejectedReasons.MissingStamp },
  { label: 'Missing signature', value: RejectedReasons.MissingSignature },
  { label: "Missing copy of borrower's id", value: RejectedReasons.MissingCopyOfBorrowerId },
  { label: 'Missing pages or documents cutoff', value: RejectedReasons.MissingPages },
  { label: 'Inconsistent signatures', value: RejectedReasons.InconsistentSignatures },
  { label: 'Other', value: RejectedReasons.Other }
]

export const ReportPostClosingError: FC<ReportPostClosingErrorProps> = ({ order }): ReactElement => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOk = () => {
    form.validateFields().then((values: PostClosingErrorFormValues) => {
      setIsLoading(true)
      dispatch(
        createResource(
          Requests.postClosingReviews({
            orderId: order.id,
            closingStatus: PostClosingStatus.Rejected,
            ...values
          })
        )
      ).then(() => {
        dispatch(readResource({ ...Requests.order({ id: order.id }), resource: { id: order.id } }))
        setIsLoading(false)
        setIsModalVisible(false)
      })
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  return (
    <>
      <Button size="small" onClick={() => setIsModalVisible(true)}>
        Report error
      </Button>
      <Modal
        title="Add Post Closing Review"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: isLoading }}
        okText="Save"
      >
        <Typography.Text type="secondary">The documents have the following errors:</Typography.Text>
        <Form name="postClosingErrorForm" form={form} initialValues={{ notifyNotary: true, explanation: '' }}>
          <Form.Item name="reason" rules={[{ required: true, message: "Reason can't be blank" }]}>
            <Checkbox.Group>
              {options.map((option, index) => (
                <Row key={index}>
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Typography.Text strong={true}>Additional details</Typography.Text>
          <Form.Item name="explanation">
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item name="notifyNotary" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox>Notify notary</Checkbox>
          </Form.Item>
          <Typography.Text type="secondary">
            The notary will be notified of the above errors and receive any additional details that you add
          </Typography.Text>
        </Form>
      </Modal>
    </>
  )
}
