import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Tag, Typography } from 'antd'
import { TrackingInfo } from '../TrackingInfo'
import { OwnerAvatar } from './OwnerAvatar'
import { AutoReminderNote } from './AutoReminderNote'
import './styles.modules.scss'
import { Order } from '../../../../../models/order/order'
import { NotaryAppointmentRescheduleStatus } from '../../../../../models/order/notaryAppointmentRescheduleStatus'
import OrderHelper from '../../../../../helpers/OrderHelper'
import { isDocumentNeededStatus } from './isDocumentNeededStatus'
import { isNonEmptyString } from '../../../../../utils/validateString'
import DashboardHelper from '../../../../../helpers/DashboardHelper/DashboardHelper'
import { ViewingUsers } from './ViewingUsers'
import { get } from 'lodash'

export const StatusColumn = (
  postClosingTools: boolean,
  isNeedsScanbacksFilterEnabled: boolean,
  userPresences: object
) => {
  return {
    title: 'Status',
    dataIndex: 'status',
    className: 'table-column status-column',
    render: (status: string | null, order: Order) => {
      return (
        <>
          <TrackingInfo order={order} />
          <OwnerAvatar order={order} />
          {postClosingTools && OrderHelper.isPostClosingStatusRejected(order) && !order.closed && (
            <Tag className="rejected-tag">
              <Icons.Flag className="rejected-icon" />
              Post closing: errors
            </Tag>
          )}
          {postClosingTools && OrderHelper.isPostClosingStatusApproved(order) && !order.closed && (
            <Tag className="approved-tag">Post closing: approved</Tag>
          )}
          {isDocumentNeededStatus(order) && <Tag className="documents-note">Needs docs</Tag>}
          {isNeedsScanbacksFilterEnabled && (
            <>
              <Tag className="scanbacks-note">Needs scanbacks</Tag>
              <AutoReminderNote
                reminderSentAt={order.uploadScanbacksReminderSentAt}
                tooltipTitle="The notary was automatically reminded to upload scanbacks"
              />
            </>
          )}
          {!isNeedsScanbacksFilterEnabled && isDocumentNeededStatus(order) && (
            <AutoReminderNote
              reminderSentAt={order.uploadDocsReminderSentAt}
              tooltipTitle="The client was automatically reminded to upload docs"
            />
          )}
          {!isNeedsScanbacksFilterEnabled && isNonEmptyString(order.statusText) && (
            <Tag className={DashboardHelper.orderStatusTextStyle(order.statusText)}>{order.statusText}</Tag>
          )}
          {!order.closed && isNonEmptyString(order.status) && <Typography.Text>{order.status}</Typography.Text>}
          {order.notaryAppointmentRescheduleStatus === NotaryAppointmentRescheduleStatus.Pending && (
            <Tag className="confirming-with-notary-tag">Confirming reschedule with notary</Tag>
          )}
          <ViewingUsers userPresence={get(userPresences, order.id)} />
        </>
      )
    }
  }
}
