import React, { FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BoardFilter } from '../../Shared/Filters/BoardFilter'
import { searchOptions } from './NotaryFilter/searchOptions'
import '../../Shared/Ant/App.less'

export const BoardHeader: FC<{}> = (): ReactElement => {
  return (
    <Provider store={store}>
      <div className="board-header">
        <div className="notary-filter">
          <BoardFilter searchOptions={searchOptions} defaultFilter="last_name" />
        </div>
      </div>
    </Provider>
  )
}
