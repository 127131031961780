import React from 'react'
import { Typography } from 'antd'
import '../styles.modules.scss'
import { defaultSortOrder } from '../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../constants/COLUMN_SORTER'
import { CompanyTeamMember } from '../types'
import moment from 'moment-timezone'

export const LastActiveColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Last active',
    dataIndex: 'lastActive',
    defaultSortOrder: defaultSortOrder('lastActive', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'lastActive',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: 'Sort earliest to latest',
      descText: 'Sort latest to earliest',
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    render: (text: string, companyTeamMember: CompanyTeamMember) => {
      return (
        <>
          {companyTeamMember.lastActionAt && (
            <Typography.Text>{moment(companyTeamMember.lastActionAt).format('MMMM DD, YYYY')}</Typography.Text>
          )}
        </>
      )
    }
  }
}
