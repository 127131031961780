import React, { FC, ReactElement } from 'react'
import './styles.modules.scss'
import '../Shared/Ant/App.less'
import BulkCtmUpload from './BulkCtmUpload'
import AddNewCtm from './AddNewCtm'
import { AddTeamMemberProps } from './types'
import { Tabs, Row, Col } from 'antd'
import { ButtonLink } from '../../components/Shared/ButtonLink'
const { TabPane } = Tabs

const AddTeamMember: FC<AddTeamMemberProps> = ({
  companyTeamsPath,
  bulkCtmUploadPath,
  templatePath,
  addNewCtmPath,
  csrfToken,
  cqcRestrictUsers
}): ReactElement => (
  <div className="add_team_members_form">
    <Row>
      <Col span={20} offset={2}>
        <ButtonLink callback={() => {}} href={companyTeamsPath}>
          Back
        </ButtonLink>
        <h2>Add Team Members</h2>
        <hr></hr>
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Individual Team Member" key="1">
              <AddNewCtm
                companyTeamsPath={companyTeamsPath}
                addNewCtmPath={addNewCtmPath}
                csrfToken={csrfToken}
                cqcRestrictUsers={cqcRestrictUsers}
              />
            </TabPane>
            <TabPane tab="Multiple Team Members" key="2">
              <BulkCtmUpload
                companyTeamsPath={companyTeamsPath}
                bulkCtmUploadPath={bulkCtmUploadPath}
                templatePath={templatePath}
                csrfToken={csrfToken}
              />
            </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  </div>
)

export default AddTeamMember
