import React, { SVGProps } from 'react'

const Message: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.486 2 2 5.91756 2 10.7316C2 15.8337 7.04 19.956 13 19.4175V21.4035C13.02 21.3967 13 22.3736 14 21.841L14.54 21.5053C15.672 20.801 21.347 17.0154 21.943 11.6581C21.98 11.3534 22 11.044 22 10.7316C22 5.91756 17.514 2 12 2ZM8.5 11.7017H7.5C7.224 11.7017 7 11.4844 7 11.2166V10.2465C7 9.97871 7.224 9.76139 7.5 9.76139H8.5C8.776 9.76139 9 9.97871 9 10.2465V11.2166C9 11.4844 8.776 11.7017 8.5 11.7017ZM12.5 11.7017H11.5C11.224 11.7017 11 11.4844 11 11.2166V10.2465C11 9.97871 11.224 9.76139 11.5 9.76139H12.5C12.776 9.76139 13 9.97871 13 10.2465V11.2166C13 11.4844 12.776 11.7017 12.5 11.7017ZM16.5 11.7017H15.5C15.224 11.7017 15 11.4844 15 11.2166V10.2465C15 9.97871 15.224 9.76139 15.5 9.76139H16.5C16.776 9.76139 17 9.97871 17 10.2465V11.2166C17 11.4844 16.776 11.7017 16.5 11.7017Z"
      fill="currentcolor"
    />
  </svg>
)

export default Message
