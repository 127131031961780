import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Table } from 'antd'
import { Requests, ReactQuery } from '../../../api'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { DashboardFilters, OrdersTableProps, SorterProp, UserPresence } from './types'
import { Order } from '../../../models/order/order'
import OrderHelper from '../../../helpers/OrderHelper'
import { camelCase, get, cloneDeep } from 'lodash'
import * as qs from 'qs'
import { PinnedColumn } from './Columns/Pinned/PinnedColumn'
import { OrderNumberColumn } from './Columns/OrderNumber/OrderNumberColumn'
import { LastnameColumn } from './Columns/Lastname/LastnameColumn'
import { AppointmentFullTimeColumn } from './Columns/AppointmentFullTime/AppointmentFullTimeColumn'
import { ClientCompanyNameColumn } from './Columns/ClientCompanyName/ClientCompanyNameColumn'
import { TransactionTypeColumn } from './Columns/TransactionType/TransactionTypeColumn'
import { ProductNameColumn } from './Columns/ProductName/ProductNameColumn'
import { NotaryLastNameColumn } from './Columns/NotaryLastName/NotaryLastNameColumn'
import { PaidColumn } from './Columns/Paid/PaidColumn'
import { DocsColumn } from './Columns/Docs/DocsColumn'
import { StatusColumn } from './Columns/Status/StatusColumn'
import { ScanbacksColumn } from './Columns/Scanbacks/ScanbacksColumn'
import { PackageColumn } from './Columns/Package/PackageColumn'
import { StatusColumnForPostClosing } from './Columns/Status/StatusColummForPostClosing'
import { ChannelEvents } from '../../../models/event/channelEvents'
import { tableChange } from '../../../utils/table/tableChange'
import { OrderPresenceChangeCallback } from './OrderPresenceChangeCallback'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { OwnerFilters } from '../../../helpers/DashboardHelper/OwnerFilters'

export const OrdersTable: FC<OrdersTableProps> = ({
  transactionTypeFlipper,
  companyId,
  searchParams,
  changeSearchParams,
  pusherWrapper,
  isRealTimeDashboardEnabled
}): ReactElement => {
  const sorter = get(searchParams, ['q', 's'], '').split(' ')
  const sortColumn = sorter.length > 1 ? camelCase(sorter[0]) : ''
  const sortOrder = sorter.length > 1 ? sorter[1] : ''
  const ownerFilter = get(searchParams, 'owner', OwnerFilters.All)
  const teamId = get(searchParams, 'company_team_id', '')
  const queryClient = useQueryClient()
  const ordersRequest = Requests.orders(qs.stringify(searchParams))
  const { data: ordersData, isLoading: areOrdersLoading } = useQuery({
    queryKey: [ordersRequest.type, searchParams],
    queryFn: () => ReactQuery.fetchResources(ordersRequest)
  })
  const { request: companyRequest, response: company } = Snapdocs.useResources(Requests.company({ id: companyId }))
  const { response: appConfig } = Snapdocs.useResources(Requests.appConfig())
  const currentFilter = get(searchParams, 'filter') || get(ordersData, ['meta', 'lastUsedFilter'])
  const isSearchFilterEnabled = get(searchParams, 'commit') === DashboardFilters.Search
  const isPostClosingFilterEnabled = currentFilter === DashboardFilters.PostClosingQC
  const isNeedsScanbacksFilterEnabled = currentFilter === DashboardFilters.NeedsScanbacks
  const dashboardCountsRequest = Requests.dashboardCounts({
    owner: ownerFilter,
    teamId: teamId,
    filter: currentFilter
  })
  const [userPresences, setUserPresences] = useState({})

  useEffect(() => {
    if (!appConfig || Object.keys(userPresences).length === 0) return

    const pusherInstance = pusherWrapper(appConfig.pusherAppKey, appConfig.pusherAppCluster)
    pusherInstance.subscribeForEvent(
      appConfig.companyChannel,
      ChannelEvents.OrderPresenceChange,
      <T extends { user: UserPresence; event: string; order_id: string }>(data: T) => {
        OrderPresenceChangeCallback(data, userPresences, setUserPresences)
      }
    )

    return () => pusherInstance.unsubscribeFromTheChannel(appConfig.companyChannel)
  }, [appConfig, userPresences])

  useEffect(() => {
    if (!appConfig) return

    const pusherInstance = pusherWrapper(appConfig.pusherAppKey, appConfig.pusherAppCluster)

    pusherInstance.subscribeForEvent(
      appConfig.schedulingDashboardChannel,
      ChannelEvents.OrderUpdated,
      <T extends { message: { order_id: number; owner_id: number | null } }>(data: T) => {
        if (
          !isRealTimeDashboardEnabled ||
          isSearchFilterEnabled ||
          queryClient.isFetching({ queryKey: [ordersRequest.type, searchParams] })
        )
          return

        if (
          ownerFilter === OwnerFilters.All ||
          (ownerFilter === OwnerFilters.Mine && data.message.owner_id === appConfig.currentUser.id)
        ) {
          queryClient.invalidateQueries({ queryKey: [ordersRequest.type] })
          queryClient.invalidateQueries({ queryKey: [dashboardCountsRequest.type] })
        }
      }
    )

    return () => pusherInstance.unsubscribeFromTheChannel(appConfig.schedulingDashboardChannel)
  }, [appConfig, isSearchFilterEnabled])

  useEffect(() => {
    if (areOrdersLoading) return
    setUserPresences(get(ordersData, ['meta', 'userPresences']))
  }, [areOrdersLoading])

  const productColumn = () => {
    if (transactionTypeFlipper) {
      return TransactionTypeColumn(sortColumn, sortOrder)
    }
    return ProductNameColumn(sortColumn, sortOrder)
  }

  const regularColumns = [
    PinnedColumn(),
    OrderNumberColumn(sortColumn, sortOrder),
    LastnameColumn(sortColumn, sortOrder),
    AppointmentFullTimeColumn(sortColumn, sortOrder, get(company, 'orderAppointmentConfirmation')),
    ClientCompanyNameColumn(sortColumn, sortOrder),
    productColumn(),
    NotaryLastNameColumn(sortColumn, sortOrder, get(ordersData, ['meta', 'verifiedOrderIds'])),
    get(company, 'vendorPay') ? PaidColumn() : {},
    DocsColumn(),
    StatusColumn(get(company, 'postClosingTools'), isNeedsScanbacksFilterEnabled, userPresences)
  ]

  const postClosingColumns = [
    OrderNumberColumn(sortColumn, sortOrder),
    LastnameColumn(sortColumn, sortOrder),
    AppointmentFullTimeColumn(sortColumn, sortOrder, get(company, 'orderAppointmentConfirmation')),
    ClientCompanyNameColumn(sortColumn, sortOrder),
    productColumn(),
    NotaryLastNameColumn(sortColumn, sortOrder, get(ordersData, ['meta', 'verifiedOrderIds'])),
    ScanbacksColumn(),
    PackageColumn(),
    StatusColumnForPostClosing(userPresences)
  ]

  const onTableChange = (
    pagination: { current: number },
    filters: Record<keyof Order, string[]>,
    sorter: SorterProp
  ) => {
    let params = cloneDeep(searchParams)

    params = tableChange({ pagination, filters, sorter, params })
    changeSearchParams(params)
  }

  const onRowClick = (order: Order, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(Requests.order({ id: order.id }).path, '_blank')
      return
    }

    if (isPostClosingFilterEnabled) return ''
    return (window.location.href = Requests.order({ id: order.id }).path)
  }

  const generateRowClassName = (order: Order) => {
    let rowClassName = 'table-row'

    if (order.closed || OrderHelper.isCanceledOrOnHold(order)) {
      rowClassName += ' table-row-inactive'
    }

    if (!isPostClosingFilterEnabled) {
      rowClassName += ' table-row-clickable'
    }

    return rowClassName
  }

  return (
    <Table
      size="small"
      rowKey="id"
      scroll={{ x: 'min-content' }}
      dataSource={get(ordersData, 'resources')}
      columns={isPostClosingFilterEnabled ? postClosingColumns : regularColumns}
      onChange={onTableChange}
      loading={areOrdersLoading || Requests.isLoading(companyRequest)}
      pagination={{
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        current: get(ordersData, ['meta', 'currentPage']),
        pageSize: get(ordersData, ['meta', 'perPage']),
        total: get(ordersData, ['meta', 'totalEntries'])
      }}
      onRow={(order: Order) => {
        return { onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onRowClick(order, event) }
      }}
      rowClassName={(order: Order) => generateRowClassName(order)}
    />
  )
}
