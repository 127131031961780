import React from 'react'
import classnames from 'classnames'
import s from './ButtonLink.module.scss'

interface ButtonLinkProps {
  href?: string
  callback(): void
  className?: string
  style?: 'primary' | 'utility'
  type?: 'button' | 'submit' | 'reset'
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  callback,
  className,
  style,
  type,
  children,
  ...props
}) => {
  const classes = classnames(s.a, {
    [s.a_utility]: style === 'utility'
  })

  // Our button wrapper lets us give the button a "not-allowed cursor".
  const wrapperClasses = classnames(className, s.a__wrapper, {})

  // If a href was provided, render a link.
  // Otherwise, render a button because this link isn't actually a link.
  const buttonLinkElement = href ? (
    <a className={classes} href={href} {...props}>
      {children}
    </a>
  ) : (
    <button type={type} className={classes} onClick={callback} {...props}>
      {children}
    </button>
  )

  return <div className={wrapperClasses}>{buttonLinkElement}</div>
}
