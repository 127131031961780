import React from 'react'
import classnames from 'classnames'
import { ORDER_DOC_RETURN_TRACKING as MESSAGES } from 'messages/ORDER_DOC_RETURN_TRACKING'
import { ICONS } from 'icons'
import s from './DeliveryDate.module.scss'

interface DeliveryDateProps {
  originalEstimatedDeliveryTime?: string
  currentEstimatedDeliveryTime?: string
  actualDeliveryTime?: string
  isPickedUp?: boolean
}

export const DeliveryDate: React.FC<DeliveryDateProps> = ({
  originalEstimatedDeliveryTime,
  currentEstimatedDeliveryTime,
  actualDeliveryTime,
  isPickedUp
}) => {
  const isDelayed = originalEstimatedDeliveryTime !== currentEstimatedDeliveryTime
  const isDelivered = !!actualDeliveryTime

  const classes = classnames({
    [s.isDelayed]: isDelayed,
    [s.isDelivered]: isDelivered
  })

  let messagesForState = { heading: '', date: '' }

  if (isDelivered) {
    messagesForState = {
      heading: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.HEADING.DELIVERED,
      date: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.DATE.DELIVERED(actualDeliveryTime ?? '')
    }
  } else if (!isPickedUp) {
    messagesForState = {
      heading: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.HEADING.DEFAULT,
      date: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.DATE.NOT_PICKED_UP
    }
  } else if (!currentEstimatedDeliveryTime) {
    messagesForState = {
      heading: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.HEADING.DEFAULT,
      date: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.DATE.NOT_AVAILABLE
    }
  } else if (isDelayed) {
    messagesForState = {
      heading: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.HEADING.DELAYED,
      date: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.DATE.DEFAULT(currentEstimatedDeliveryTime)
    }
  } else {
    messagesForState = {
      heading: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.HEADING.DEFAULT,
      date: MESSAGES.FEDEX_DOC_RETURN.DELIVERY_DATE.DATE.DEFAULT(currentEstimatedDeliveryTime)
    }
  }

  return (
    <div className={classes}>
      <div className={s.heading}>
        <i className={ICONS.EXCLAMATION_TRIANGLE} />
        {messagesForState.heading}
      </div>
      <div>{messagesForState.date}</div>
    </div>
  )
}
