import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Typography, Tag } from 'antd'
import './styles.modules.scss'
import { Order } from '../../../../../models/order/order'
import OrderHelper from '../../../../../helpers/OrderHelper'
import { isDocumentNeededStatus } from './isDocumentNeededStatus'
import { ReportPostClosingError } from './ReportPostClosingError'
import { ApprovePostClosing } from './ApprovePostClosing'
import { get } from 'lodash'
import { ViewingUsers } from './ViewingUsers'

export const StatusColumnForPostClosing = (userPresences: object) => {
  return {
    dataIndex: 'status',
    className: 'table-column status-column',
    render: (status: string | null, order: Order) => {
      return (
        <>
          {OrderHelper.isPostClosingStatusPending(order) && (
            <>
              <ReportPostClosingError order={order} />
              <ApprovePostClosing order={order} />
            </>
          )}
          {OrderHelper.isPostClosingStatusRejected(order) && (
            <span>
              <Tag className="rejected-tag">
                <Icons.Flag className="rejected-icon" />
                Post closing: errors
              </Tag>
              <div>
                <Typography.Text type="secondary">{order.mostRecentPostClosingReviewReasons}</Typography.Text>
              </div>
            </span>
          )}
          {OrderHelper.isPostClosingStatusApproved(order) && <Tag className="approved-tag">Post closing: approved</Tag>}
          {isDocumentNeededStatus(order) && <Tag className="documents-note">Needs docs</Tag>}
          <ViewingUsers userPresence={get(userPresences, order.id)} />
        </>
      )
    }
  }
}
