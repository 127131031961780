import React from 'react'
import { Icon } from '../../Shared/Ant'
import { Checkbox, Form, Tag } from 'antd'
import '../styles.modules.scss'
import { defaultSortOrder } from '../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../constants/COLUMN_SORTER'
import { USER_ACTIVITY_STATES } from '../../../constants/GLOBAL'
import { CompanyTeamMember } from '../types'
import { ResetButton } from '../ColumnFilterButtons/ResetButton'
import { ApplyButton } from '../ColumnFilterButtons/ApplyButton'
import Filter from '../../../components/Shared/Icons/Filter/Filter'

export const StatusColumn = (
  sortColumn: string,
  sortOrder: string,
  setFilter: (filter: Record<keyof object, string[]>) => void
) => {
  const [form] = Form.useForm()
  const [isColumnFiltered, setIsColumnFiltered] = React.useState(false)

  const handleReset = async (confirm: () => void) => {
    setFilter({ active: null })
    setIsColumnFiltered(false)
    form.resetFields()
    confirm()
  }

  const handleSubmit = async (confirm: () => void) => {
    const { active } = await form.validateFields()
    if (active?.length > 0) {
      setFilter({ active })
      setIsColumnFiltered(true)
    } else {
      setFilter({ active: null })
      setIsColumnFiltered(false)
    }
    confirm()
  }

  return {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: defaultSortOrder('status', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'status',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    filterDropdown: ({ confirm }: any) => (
      <Form form={form} onFinish={() => handleSubmit(confirm)}>
        <div className="team-table-filter">
          <strong>Filter status</strong>
          <Form.Item name="active" className="mtl mbn" valuePropName="checked">
            <Checkbox.Group
              options={[
                { value: true, label: USER_ACTIVITY_STATES.ACTIVE },
                { value: false, label: USER_ACTIVITY_STATES.INACTIVE }
              ]}
            />
          </Form.Item>
          <div className="display_flex justify_content_space_between">
            <ResetButton onClick={() => handleReset(confirm)} />
            <ApplyButton />
          </div>
        </div>
      </Form>
    ),
    filterIcon: <Icon component={Filter} className={isColumnFiltered ? 'filtered-column-icon' : ''} />,
    render: (text: string, companyTeamMember: CompanyTeamMember) => {
      let color: string, statusText: string
      if (companyTeamMember.active) {
        color = 'green'
        statusText = USER_ACTIVITY_STATES.ACTIVE
      } else {
        color = 'red'
        statusText = USER_ACTIVITY_STATES.INACTIVE
      }
      return (
        <>
          <Tag color={color}>{statusText}</Tag>
        </>
      )
    }
  }
}
