import React, { FC, ReactElement } from 'react'
import { RoutingHeader } from './RoutingHeader'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { RoutingContent } from './RoutingContent'
import { EmailRoutingParams } from './types'
import '../../Shared/Ant/App.less'

export const EmailRouting: FC<EmailRoutingParams> = ({
  companyId,
  emailSettingsId,
  newCommentUiFlipperEnabled
}): ReactElement => {
  return (
    <Provider store={store}>
      <div className="email-routing">
        <RoutingHeader newCommentUiFlipperEnabled={newCommentUiFlipperEnabled} />
        <RoutingContent companyId={companyId} emailSettingsId={emailSettingsId} />
      </div>
    </Provider>
  )
}
