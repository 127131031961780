import React, { SVGProps } from 'react'

const Undo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.49888 8C2.37117 8 2.24344 8.04898 2.14616 8.14648C2.04838 8.24348 2 8.372 2 8.5V15C2 15.2652 2.10512 15.5196 2.29224 15.7071C2.47936 15.8946 2.73314 16 2.99777 16H9.48325C9.61097 16 9.7382 15.9505 9.83598 15.8535C10.0305 15.6585 10.0305 15.3415 9.83598 15.1465L7.20905 12.5137C8.61348 11.2655 10.4512 10.5 12.4766 10.5C15.6185 10.5 18.3313 12.3228 19.6324 14.9688C19.9078 15.5287 20.5471 15.8035 21.1388 15.6055C21.8462 15.3695 22.2057 14.5597 21.8774 13.8887C20.1752 10.4037 16.6103 8 12.4766 8C9.76213 8 7.3057 9.05031 5.44931 10.75L2.85161 8.14648C2.75433 8.04898 2.6266 8 2.49888 8Z"
      fill="currentcolor"
    />
  </svg>
)

export default Undo
