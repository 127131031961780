import React from 'react'
import { TrackingInfo } from '../TrackingInfo'
import { Order } from '../../../../../models/order/order'

export const PackageColumn = () => {
  return {
    title: 'Package',
    dataIndex: 'package',
    className: 'table-column package-column',
    render: (text: string, order: Order) => {
      return <TrackingInfo order={order} />
    }
  }
}
