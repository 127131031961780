export const QC_REPORTS = {
  STATUSES: {
    IN_PROGRESS: 'in_progress',
    ERRORS: 'errors',
    PASSED: 'passed'
  },
  ISSUE_TYPES: {
    missing_signature: 'Missing signature',
    missing_initial: 'Missing initial',
    missing_date: 'Missing date',
    poorly_scanned_or_not_legible: 'Poorly scanned/not legible',
    missing_stamp: 'Missing stamp',
    wrong_data: 'Wrong data',
    missing_page: 'Missing page'
  }
}
