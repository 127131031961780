import React, { FC, ReactElement } from 'react'
import { NotaryCommentFormProps } from './types'
import { CommentForm } from '../CommentForm'
import { UserRole } from 'models/user/user'
import NotaryCommentFormMessages from './NotaryCommentForm.messages'

export const NotaryCommentForm: FC<NotaryCommentFormProps> = ({
  commentRecipients,
  orderCommentPath,
  showNotarySmsText,
  csrfToken,
  orderHasParticipants
}): ReactElement => {
  return (
    <CommentForm
      commentRecipients={commentRecipients}
      userRole={UserRole.Notary}
      showNotarySmsText={showNotarySmsText}
      toFieldHelpText={NotaryCommentFormMessages.TO.HELP}
      toFieldSelectText={NotaryCommentFormMessages.TO.SELECT}
      textFieldPlaceHolder={NotaryCommentFormMessages.TEXT.PLACEHOLDER}
      orderCommentPath={orderCommentPath}
      csrfToken={csrfToken}
      orderHasParticipants={orderHasParticipants}
    />
  )
}
