// This file defines the method handleRailsPartials(fields)
// which allows React to render Rails Partials for parts of
// the page that are not controlled by react
//
// Example Input:
// {
//   update_partial: {
//     partial_content: 'raw html string',
//     partial_css_selector: '#your css selector'
//   }
// }

interface RailsPartial {
  partial_content: string
  partial_css_selector: string
}

interface UpdatePartials {
  update_partial?: RailsPartial
}

export const handleRailsPartials = ({ update_partial }: UpdatePartials) => {
  if (!update_partial) {
    return
  }

  const partial = update_partial

  const partialContent = partial.partial_content
  const partialCssSelector = partial.partial_css_selector
  const htmlContainer = document.querySelector(partialCssSelector)
  if (htmlContainer) {
    htmlContainer.innerHTML = partialContent
  }
}
