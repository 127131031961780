import React, { FC, ReactElement } from 'react'
import { Modal, Icons } from '../Shared/Ant'
import { Typography, Spin } from 'antd'
import axios from 'axios'
import { ExportCsvModalProps } from './types'
import moment from 'moment'

export const ExportCsvModal: FC<ExportCsvModalProps> = ({
  visible,
  exportCsvPath,
  onCancel,
  totalRecords,
  searchParams
}): ReactElement => {
  const [loading, setLoading] = React.useState(false)
  const [errorState, setErrorState] = React.useState(false)
  const [errorCount, setErrorCount] = React.useState(0)

  const handleExport = async () => {
    setLoading(true)
    await axios
      .get(`${exportCsvPath}?${searchParams}`)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `export_team_members-${moment().format('MM-DD-YYYY')}.csv`
        link.click()
        link.remove()
        onCancel()
      })
      .catch(() => {
        setErrorState(true)
        setErrorCount(errorCount + 1)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      okText={errorState ? 'OK' : 'Export'}
      onOk={errorState ? onCancel : handleExport}
      onCancel={onCancel}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading, className: errorState ? 'hidden' : '' }}
      wrapClassName="export-team-members-csv-modal"
      width={380}
      destroyOnClose={true}
      afterClose={() => setErrorState(false)}
    >
      <Typography.Title level={4} className="csv-modal-title">
        {errorState ? (
          <>
            <Icons.CloseCircle className="color-ant-red-4 mrl" />
            <span className="plm">Cannot export current view</span>
          </>
        ) : (
          <>
            <Icons.InfoCircle className="color-ant-blue-4 mrl" />
            {loading ? (
              <span className="plm">Exporting current view...</span>
            ) : (
              <span className="plm">Export current view</span>
            )}
          </>
        )}
      </Typography.Title>
      <Typography.Text>
        {loading && <Spin tip="Preparing export. This can take up to 15 seconds." size="large" className="mtxl" />}
        {!loading &&
          (errorState ? (
            <>
              {errorCount === 1 && <p>Something went wrong. The export was not created. Please try again.</p>}
              {errorCount > 1 && <p>It seems something is not working. Please contact Snapdocs support.</p>}
            </>
          ) : (
            <>
              <p>Exporting your current columns and filters. {totalRecords} rows will be exported. </p>
              <p className="mbn">
                It can take as long as 15 seconds to prepare the export before your browser downloads the .csv file.
              </p>
            </>
          ))}
      </Typography.Text>
    </Modal>
  )
}

export default ExportCsvModal
