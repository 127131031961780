import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const TransactionTypeColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Transaction Type',
    dataIndex: 'transactionTypeTransactionType',
    defaultSortOrder: defaultSortOrder('transactionTypeTransactionType', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'transactionTypeTransactionType',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column transaction-type-column',
    render: (text: string, order: Order) => {
      return <Typography.Text>{order.transactionType}</Typography.Text>
    }
  }
}
