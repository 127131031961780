import React, { SVGProps } from 'react'

const Share: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.727 3.0068C16.8456 3.02492 16.9616 3.08005 17.0629 3.17831L20.7563 6.75764C21.0812 7.0974 21.0812 7.6491 20.7563 7.98886L17.0648 11.5682C16.6588 11.9623 16.0004 11.6613 16.0004 11.0843V8.44011H14.0004C9.0178 8.44011 5.00039 12.64 5.00039 17.8489V19.9397C5.00226 20.0782 4.97778 20.2158 4.92837 20.3443C4.87896 20.4729 4.8056 20.5899 4.71255 20.6885C4.61951 20.7872 4.50863 20.8655 4.38638 20.919C4.26412 20.9725 4.13292 21 4.00039 21C3.86787 21 3.73666 20.9725 3.6144 20.919C3.49215 20.8655 3.38128 20.7872 3.28823 20.6885C3.19519 20.5899 3.12183 20.4729 3.07241 20.3443C3.023 20.2158 2.99852 20.0782 3.00039 19.9397V17.8489C3.00039 11.5101 7.93698 6.34928 14.0004 6.34928H16.0004V3.66223C16.0004 3.22864 16.371 2.95246 16.727 3.0068Z"
      fill="currentcolor"
    />
  </svg>
)

export default Share
