import { useQuery } from '@tanstack/react-query'
import { snakeCaseToCamelCase } from '../utils/snakeCaseToCamelCase'
import { get, snakeCase } from 'lodash'
import { apiCall } from './apiCall'
import { Requests } from './requests'

const STALE_TIME = 1000 * 60 * 60 * 24

const fetchResources = async <T extends { path: string; type: string }>(config: T) => {
  const { path, type } = config

  try {
    const response = await apiCall.get(path, { params: get(config, 'params', {}) })
    const data = get(response, ['data', snakeCase(type)])
    const resources = Array.isArray(data)
      ? data.map((resource) => snakeCaseToCamelCase(resource))
      : snakeCaseToCamelCase(data)
    const meta = snakeCaseToCamelCase(get(response, ['data', 'meta'], {}))

    return { resources, meta }
  } catch (e) {
    return e.response.data
  }
}

const useCompanyRequest = (id: number) => {
  const companyRequest = Requests.company({ id })
  const { data: companyData, isLoading: companyLoading } = useQuery({
    queryKey: [companyRequest.type, { id }],
    queryFn: () => fetchResources(companyRequest),
    staleTime: STALE_TIME
  })
  const company = get(companyData, 'resources', {})

  return { company, companyLoading }
}

const useFeaturePoliciesRequest = (companyId: number) => {
  const featurePoliciesRequest = Requests.featurePolicies({ companyId })
  const { data: featurePoliciesData, isLoading: featurePoliciesLoading } = useQuery({
    queryKey: [featurePoliciesRequest.type, { companyId }],
    queryFn: () => fetchResources(featurePoliciesRequest),
    staleTime: STALE_TIME
  })
  const featurePolicies = get(featurePoliciesData, ['resources', 0], {})

  return { featurePolicies, featurePoliciesLoading }
}

export const ReactQuery = { fetchResources, useCompanyRequest, useFeaturePoliciesRequest }
