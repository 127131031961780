import React from 'react'
import { TimelineEvent } from './TimelineEvent/TimelineEvent'
import { organizeTimelineEvents, Event } from './organizeTimelineEvents'
import s from './styles.module.scss'

interface TimelineProps {
  events: Event[]
  originalEstimatedDeliveryTime: string
  currentEstimatedDeliveryTime: string
}

export const Timeline: React.FC<TimelineProps> = ({
  events,
  originalEstimatedDeliveryTime,
  currentEstimatedDeliveryTime
}) => {
  return (
    <div className={s.timeline}>
      {organizeTimelineEvents(events).map((event) => (
        <TimelineEvent
          key={event.type}
          {...event}
          originalEstimatedDeliveryTime={originalEstimatedDeliveryTime}
          currentEstimatedDeliveryTime={currentEstimatedDeliveryTime}
        />
      ))}
    </div>
  )
}
