import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './Body.module.scss'

function Body({ className, children, bold, muted, inline, ...props }) {
  const classes = classnames(s.body, className, {
    [s.bold]: bold,
    [s.muted]: muted,
    [s.inline]: inline
  })

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  bold: PropTypes.bool,
  muted: PropTypes.bool,
  inline: PropTypes.bool
}

Body.defaultProps = {
  className: null,
  children: null,
  bold: false,
  muted: false,
  inline: false
}

export default Body
