import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import './styles.modules.scss'

export const W9Column = () => {
  return {
    title: 'W9',
    dataIndex: 'w9',
    className: 'table-column w9-status-column',
    render: (w9: boolean) => {
      return w9 && <Icons.Check className="color-ant-green-4 status-check-icon" data-test="w9-status-icon" />
    }
  }
}
