import { Order } from '../../../../../models/order/order'
import moment from 'moment-timezone'

export const isDocumentNeededStatus = (order: Order) => {
  if (order.docsToNotarySent || !order.notaryName || !order.appointmentFullTime) {
    return false
  }

  return moment
    .utc()
    .isBetween(moment.utc(order.appointmentFullTime).subtract(4, 'hours'), moment.utc(order.appointmentFullTime))
}
