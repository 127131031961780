import { Form, Input, Row, Col, Select, Collapse, DatePicker } from 'antd'
import React, { FC, ReactElement } from 'react'
import { FORM_VALUES } from '../../../constants/GLOBAL'
import { ADDRESS_VALIDATION_MSG, APPT_TIME_VALIDATION_MSG } from '../../../constants/messages/VALIDATION_MESSAGES'
import './styles.modules.scss'
import { formValidatorForAppointmentDate } from '../../../utils/antFormValidations/validateAppointmentDate'
import { disabledAppointmentDates } from '../../../utils/antFormValidations/disabledAppointmentDates'
import { FORM_RULES } from '../../../utils/formRules'

export const LocationAndTimeForm: FC = (): ReactElement => {
  const { Panel } = Collapse

  const usStatesOptions = FORM_VALUES.US_STATES.map((usState: string) => ({
    value: usState
  }))

  const appointmentTimesPrefixesOptions = FORM_VALUES.APPOINTMENT_TIMES_PREFIXES.map((prefix: string) => ({
    value: prefix
  }))

  const appointmentTimesOptions = FORM_VALUES.APPOINTMENT_TIMES.map((time: string) => ({
    value: time
  }))

  // TODO: Add Google Places API in MNS-2905

  return (
    <>
      <Form
        className="location-and-time-form"
        initialValues={{
          appointmentTimePrefix: FORM_VALUES.APPOINTMENT_TIMES_PREFIXES[0]
        }}
      >
        <div className="location-and-time-header">Location & time</div>
        <Form.Item name="streetAddress" rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.STREET_ADDRESS }]}>
          <Input placeholder="Street address" autoComplete="new-password" />
        </Form.Item>
        <Form.Item name="additionalAddressInfo">
          <Input placeholder="Additional address info" autoComplete="new-password" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={13}>
            <Form.Item name="city" rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.CITY }]}>
              <Input placeholder="City" autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="state" rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.STATE }]}>
              <Select showSearch allowClear placeholder="St" options={usStatesOptions} autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="zip" rules={FORM_RULES.standard5DigitUSZipCode}>
              <Input placeholder="Zip" autoComplete="new-password" maxLength="5" />
            </Form.Item>
          </Col>
        </Row>

        <Collapse className="property-address-collapse">
          <Panel header="Property address (optional)" key="propertyAddressPanel" className="property-address-panel">
            <div className="property-address-info">If the signing location has not been set</div>
            <Form.Item
              name="propertyStreetAddress"
              rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.STREET_ADDRESS }]}
            >
              <Input placeholder="Street address" autoComplete="new-password" />
            </Form.Item>
            <Row gutter={8}>
              <Col span={13}>
                <Form.Item name="propertyCity" rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.CITY }]}>
                  <Input placeholder="City" autoComplete="new-password" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="propertyState" rules={[{ required: true, message: ADDRESS_VALIDATION_MSG.STATE }]}>
                  <Select
                    showSearch
                    allowClear
                    placeholder="St"
                    options={usStatesOptions}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="propertyZip" rules={FORM_RULES.standard5DigitUSZipCode}>
                  <Input placeholder="Zip" autoComplete="new-password" maxLength="5" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Row gutter={8}>
          <Col span={9}>
            <Form.Item
              name="appointmentDate"
              rules={[
                { required: true, message: APPT_TIME_VALIDATION_MSG.APPOINTMENT_DATE },
                {
                  validator: formValidatorForAppointmentDate
                }
              ]}
            >
              <DatePicker
                className="appointment-date"
                disabledDate={disabledAppointmentDates}
                format="MMM D, YYYY"
                placeholder="Date"
                showToday={false}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="appointmentTimePrefix"
              rules={[{ required: true, message: APPT_TIME_VALIDATION_MSG.APPOINTMENT_TIME_PREFIX }]}
            >
              <Select showSearch allowClear options={appointmentTimesPrefixesOptions} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="appointmentTimes"
              rules={[{ required: true, message: APPT_TIME_VALIDATION_MSG.APPOINTMENT_TIME }]}
            >
              <Select showSearch allowClear options={appointmentTimesOptions} placeholder="Time" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}
