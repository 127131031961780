import { Icons } from '../../Ant'
import React, { FC, ReactElement } from 'react'
import { CaretUpDownProps } from './types'

export const CaretUpDown: FC<CaretUpDownProps> = ({ size, color }): ReactElement => {
  // the number reflects overall icon height
  // this icon consists of 2 separate icons, that is why size multiplied by 2
  // multiplied by .75 to leave some space between two separate icons
  const elementLineHeight = size * 2 * 0.75

  return (
    <span
      className="caret-up-down-icon"
      data-test="caret-up-down-icon"
      style={{
        display: 'inline-block',
        lineHeight: `${elementLineHeight}px`,
        verticalAlign: 'middle'
      }}
    >
      <Icons.CaretDown
        className="caret-down"
        style={{
          verticalAlign: 'bottom',
          width: `${size}px`,
          height: `${size}px`,
          color: color
        }}
      />
      <Icons.CaretUp
        className="caret-up"
        style={{
          verticalAlign: 'top',
          width: `${size}px`,
          height: `${size}px`,
          marginLeft: `-${size}px`,
          marginTop: '-1px',
          color: color
        }}
      />
    </span>
  )
}
