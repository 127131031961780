import React, { FC, ReactElement } from 'react'
import { Avatar, Row, Col, Typography } from 'antd'
import { HeaderProps } from '../types'

export const Header: FC<HeaderProps> = ({ teamMember, companyName }): ReactElement => {
  return (
    <div className="paxl" style={{ borderBottom: '1px solid #DDD' }}>
      <Row className="ptxl">
        <Col>
          <Avatar
            className="avatar mrxl"
            size="large"
            shape="square"
            src={teamMember.photoUrl ? teamMember.photoUrl : ''}
          >
            {!teamMember.photoUrl && teamMember.initials}
          </Avatar>
        </Col>
        <Col>
          <Row align="top">
            <h3 className="mbm mtn">{teamMember.fullName}</h3>
          </Row>
          <Row align="bottom">
            {teamMember.title && <Typography.Text>{teamMember.title},&nbsp;</Typography.Text>}
            <Typography.Text>{companyName}</Typography.Text>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Header
