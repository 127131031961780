export type User = {
  id: number
  email: string
  firstName: string
  lastName: string
  fullName: string
  photo: string | null
}

export enum UserRole {
  Scheduler = 'company_member',
  Client = 'client',
  Notary = 'agent',
  Observer = 'observer'
}
