import React, { FC, ReactElement } from 'react'
import { Icon, Icons } from '../Shared/Ant'
import { Checkbox, Upload, Button, Divider, Alert } from 'antd'
import { BulkCtmUploadProps } from './types'
import { ButtonLink } from '../../components/Shared/ButtonLink'
import './styles.modules.scss'
import axios from 'axios'

const BulkCtmUpload: FC<BulkCtmUploadProps> = ({
  companyTeamsPath,
  bulkCtmUploadPath,
  templatePath,
  csrfToken
}): ReactElement => {
  const [file, setFile] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [sendWelcomeMessage, setSendWelcomeMessage] = React.useState(true)
  const [errors, setErrors] = React.useState<Array<string>>([])
  const UploadComponent = file === undefined ? Upload.Dragger : Upload
  const draggerChildren = (
    <>
      <p className="ant-upload-drag-icon mbm">
        <Icon component={Icons.FolderOpen} />
      </p>
      <p className="ant-upload-text">Drop Team CSV to upload</p>
      <p>
        or <span className="color-ant-blue-4">choose files</span>
      </p>
    </>
  )

  const uploadCsv = () => {
    const formData = new FormData()
    formData.append('file', (file as unknown) as Blob)
    if (sendWelcomeMessage) formData.append('send_welcome_message', (true as unknown) as string)
    setLoading(true)

    axios
      .post(bulkCtmUploadPath, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': csrfToken
        }
      })
      .then(() => {
        window.location.href = companyTeamsPath + '?success_redirect=true'
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 400) {
          setErrors(error.response.data.errors)
        } else {
          setErrors(['Upload unsuccessful. Please try again later.'])
        }
      })
  }

  const csvErrors = () => {
    const errorListItems = errors.map((error) => <li key={error}>{error}</li>)

    return (
      <div className="ctm_errors">
        <p>Errors detected on CSV:</p>
        <ul>{errorListItems}</ul>
        <p className="csv_error_instruction"> Please delete .csv and upload an updated file.</p>
      </div>
    )
  }

  const props = {
    accept: 'text/csv',
    multiple: false,
    beforeUpload: () => false,
    onChange: ({ file }: any) => {
      if (file.status !== 'removed') {
        setFile(file)
      } else {
        setErrors([])
        setFile(undefined)
      }
    },
    fileList: file ? [file] : undefined,
    children: file === undefined ? draggerChildren : null
  }

  const toggleSendWelcomeMessage = () => {
    setSendWelcomeMessage(!sendWelcomeMessage)
  }

  return (
    <div className="csv_form_container">
      <div className="csv_instructions_container">
        <div className="csv_instruction">
          <p className="list_item_instructions">1. Download and Populate CSV Template</p>
          <p className="csv_instruction_content">
            Column headers cannot be changed, all fields are required, and CSV cannot exceed 250 rows.
          </p>
          <p className="csv_instruction_content csv_template">
            <a href={templatePath} target="_blank" rel="noreferrer">
              <Icon component={Icons.Download} /> CSV Template
            </a>
          </p>
        </div>
        <div className="csv_instructions_container">
          <p className="list_item_instructions">2. Upload CSV</p>
          <p className="csv_instruction_content">
            Choose file or drag and drop into the upload area below. Team member status will be created as non-admin and
            can be changed after the upload is complete.
          </p>
        </div>
        <Checkbox checked={sendWelcomeMessage} onChange={toggleSendWelcomeMessage}>
          Email login information to new team members
        </Checkbox>
      </div>
      {errors.length > 0 && file && <Alert description={csvErrors()} type="error" showIcon />}
      <UploadComponent {...props} />
      <div className="text-right add_team_member_footer">
        <Divider type="horizontal" />
        {!loading && (
          <ButtonLink className="reset-link csv_cancel" callback={() => {}} href={companyTeamsPath}>
            Cancel
          </ButtonLink>
        )}
        <Button loading={loading} type="primary" onClick={uploadCsv} disabled={!file || loading || errors.length > 0}>
          {loading ? 'Loading' : 'Save'}
        </Button>
      </div>
    </div>
  )
}

export default BulkCtmUpload
