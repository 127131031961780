import React, { Component } from 'react'
import classnames from 'classnames'
import { GLOBAL } from 'messages/GLOBAL'
import { Button } from 'components/Shared/Button/Button'
import { ButtonLink } from 'components/Shared/ButtonLink'
import { TextInput } from 'components/Shared/FormElements/TextInput'

import s from './styles.module.scss'

export interface UserDetails {
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  workPhone: string
  homePhone: string
}

export interface UserDetailsErrors {
  firstName?: Array<string>
  lastName?: Array<string>
  email?: Array<string>
  mobilePhone?: Array<string>
  workPhone?: Array<string>
  homePhone?: Array<string>
}

interface OrderSignerDetailsEditProps {
  isLoading: boolean
  submitUpdateSigner(fields: UserDetails, e: React.FormEvent<HTMLFormElement>): void
  hideEdit(): void
  fields: UserDetails
  errors: UserDetailsErrors
}

interface OrderSignerDetailsEditState {
  fields: UserDetails
}

export class OrderSignerDetailsEdit extends Component<OrderSignerDetailsEditProps, OrderSignerDetailsEditState> {
  constructor(props: OrderSignerDetailsEditProps) {
    super(props)

    this.state = {
      fields: this.props.fields || {}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.populateFields = this.populateFields.bind(this)
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    // We only pass the event back so that we can preventDefault.
    this.props.submitUpdateSigner(this.state.fields, e)
    e.preventDefault()
  }

  populateFields(name: string, value: string) {
    const fields = {
      ...this.state.fields,
      [name]: value
    }

    this.setState({ fields })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={s.formRow}>
          <TextInput
            className={s.input}
            placeholder={GLOBAL.FIRST_NAME}
            name="firstName"
            value={this.props.fields.firstName}
            callback={this.populateFields}
            errors={this.props.errors.firstName || []}
          />
          <TextInput
            className={s.input}
            placeholder={GLOBAL.LAST_NAME}
            name="lastName"
            value={this.props.fields.lastName}
            callback={this.populateFields}
            errors={this.props.errors.lastName || []}
          />
        </div>
        <div className={s.formRow}>
          <TextInput
            className={s.input}
            placeholder={GLOBAL.EMAIL}
            name="email"
            value={this.props.fields.email}
            callback={this.populateFields}
            errors={this.props.errors.email || []}
          />
        </div>
        <div className={s.formRow}>
          <TextInput
            className={s.input}
            placeholder={GLOBAL.HOME_PHONE}
            name="homePhone"
            value={this.props.fields.homePhone}
            callback={this.populateFields}
            errors={this.props.errors.homePhone || []}
            format="phone"
          />
          <TextInput
            className={s.input}
            placeholder={GLOBAL.MOBILE_PHONE}
            name="mobilePhone"
            value={this.props.fields.mobilePhone}
            callback={this.populateFields}
            errors={this.props.errors.mobilePhone || []}
            format="phone"
          />
        </div>
        <div className={s.formRow}>
          <TextInput
            className={s.input}
            placeholder={GLOBAL.WORK_PHONE}
            name="workPhone"
            value={this.props.fields.workPhone}
            callback={this.populateFields}
            errors={this.props.errors.workPhone || []}
            format="business-phone"
          />
        </div>
        <div className={classnames(s.formRow, s.formRow_controls)}>
          <ButtonLink
            style="primary"
            type="button"
            callback={this.props.hideEdit}
            className={s.formRow_controls__cancelButton}
          >
            {GLOBAL.CONTROLS.CANCEL}
          </ButtonLink>
          <Button type="submit" size="small" isLoading={this.props.isLoading}>
            {GLOBAL.CONTROLS.SAVE}
          </Button>
        </div>
      </form>
    )
  }
}
