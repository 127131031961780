import { Setting, EmailSettingsT, FeaturePolicies, Option } from './types'
import { get, camelCase } from 'lodash'

export const match = (settings: Setting[], emailSettings: EmailSettingsT, options: object) => {
  for (const setting of settings) {
    if (get(setting, 'key')) {
      setting.values = get(emailSettings, setting.key)
      setting.options = transformOptions(get(options, setting.key), setting.values)
    }
  }

  return settings
}

const transformOptions = (options: string[][], values: string[] | number) => {
  const transFormedOptions: Array<Option> = []

  for (const option of options) {
    const transFormedOption: Option = {
      toPrint: option[0],
      checked: false,
      value: option[1]
    }

    if (Array.isArray(values)) {
      for (const value of values) {
        if (value === transFormedOption.value) {
          transFormedOption.checked = true
        }
      }
    } else if (values === transFormedOption.value) {
      transFormedOption.checked = true
    }
    transFormedOptions.push(transFormedOption)
  }
  return transFormedOptions
}

export const valuesToPrint = (values: string[] | number, options: Option[]) => {
  if (!Array.isArray(values)) {
    return `${values} minutes`
  }

  if (values.length > 0) {
    return options
      .filter((option) => option.checked)
      .map((option) => option.toPrint)
      .join(', ')
  }

  return 'None selected'
}

export const previewPath = (name: string) => require(`../../../../assets/images/email_previews/${name}`)

export const hideEmailSetting = (featurePolicies: FeaturePolicies, dependencies: string[]) => {
  let hideSetting: boolean = false

  dependencies.forEach((dependency) => {
    if (!get(featurePolicies, camelCase(dependency), false)) {
      hideSetting = true
    }
  })

  return hideSetting
}
