import React, { SVGProps } from 'react'

const Check: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9803 5.99018C19.7205 5.99792 19.4739 6.1065 19.2928 6.29292L8.99984 16.5859L5.70687 13.2929C5.61473 13.1969 5.50436 13.1203 5.38223 13.0675C5.2601 13.0147 5.12867 12.9869 4.99563 12.9855C4.86259 12.9842 4.73061 13.0094 4.60743 13.0597C4.48426 13.1099 4.37235 13.1843 4.27827 13.2784C4.1842 13.3725 4.10983 13.4844 4.05954 13.6075C4.00926 13.7307 3.98405 13.8627 3.9854 13.9957C3.98676 14.1288 4.01464 14.2602 4.06743 14.3823C4.12021 14.5045 4.19684 14.6148 4.29281 14.707L8.29281 18.707C8.48035 18.8944 8.73467 18.9998 8.99984 18.9998C9.26501 18.9998 9.51933 18.8944 9.70687 18.707L20.7069 7.70698C20.8514 7.56652 20.9501 7.38567 20.99 7.18815C21.03 6.99063 21.0093 6.78565 20.9308 6.60007C20.8523 6.41448 20.7195 6.25695 20.5499 6.14812C20.3803 6.03929 20.1817 5.98424 19.9803 5.99018Z"
      fill="currentcolor"
    />
  </svg>
)

export default Check
