import Bell from './Bell'
import Calendar from './Calendar'
import CaretDown from './CaretDown'
import CaretUp from './CaretUp'
import Check from './Check'
import CheckCircle from './CheckCircle'
import ClockCircle from './ClockCircle'
import Close from './Close'
import CloseCircle from './CloseCircle'
import Delete from './Delete'
import Download from './Download'
import ExclamationCircle from './ExclamationCircle'
import File from './File'
import Flag from './Flag'
import FolderOpen from './FolderOpen'
import InfoCircle from './InfoCircle'
import Lock from './Lock'
import Message from './Message'
import Open from './Open'
import Plus from './Plus'
import Pushpin from './Pushpin'
import QuestionCircle from './QuestionCircle'
import Share from './Share'
import Thunderbolt from './Thunderbolt'
import Undo from './Undo'
import Upload from './Upload'
import User from './User'
import Warning from './Warning'

const Icons = {
  Bell,
  Calendar,
  CaretDown,
  CaretUp,
  Check,
  CheckCircle,
  ClockCircle,
  Close,
  CloseCircle,
  Delete,
  Download,
  ExclamationCircle,
  File,
  Flag,
  FolderOpen,
  InfoCircle,
  Lock,
  Message,
  Open,
  Plus,
  Pushpin,
  QuestionCircle,
  Share,
  Thunderbolt,
  Undo,
  Upload,
  User,
  Warning
}

export { Icons }
