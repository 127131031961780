import { Form, Input, InputNumber, Checkbox, Button, Switch, Tooltip, Divider, Select } from 'antd'
import React, { FC, ReactElement, useState } from 'react'
import { ProductFormBodyProps, StateSpecificPricingInterface, Product } from '../types'
import { NewAdvancedSettings } from './NewAdvancedSettings'
import { InputWithDescription } from './InputWithDescription'
import { DocumentChecklist } from './DocumentChecklist'
import { Scanbacks } from '../Scanbacks'
import { NewStateSpecificPricing } from './NewStateSpecificPricing'
import { ReactQuery } from '../../../api'
import { snakeCaseToCamelCase } from '../../../utils/snakeCaseToCamelCase'

export const NewProductFormBody: FC<ProductFormBodyProps> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  submitBtnText,
  returnChoices,
  preferredReturnChoice,
  fromOrderForm,
  saveProduct
}): ReactElement => {
  const [form] = Form.useForm()

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
  const [showDocumentChecklist, setShowDocumentChecklist] = useState(product.documentChecklist.length > 0)
  const [showStateSpecificPricing, setShowStateSpecificPricing] = useState(
    Object.keys(product.stateSpecificPricing).length > 0
  )
  const [showExternalDocs, setShowExternalDocs] = useState(
    !!product.autosetDocStatus && product.autosetDocStatus !== 'none'
  )
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(!!product.specialInstructions)
  const [scanbackReturnChoices, setScanbackReturnChoices] = useState(returnChoices)
  const [returnDeadline, setReturnDeadline] = useState(product.scanbackReturnDeadline)
  const [visibleForAllClients, setVisibleForAllClients] = useState(fromOrderForm)

  const parseFee = (fee: string | number): number => {
    const parsedFee = typeof fee === 'string' ? parseFloat(fee) : fee
    return parsedFee || 0
  }

  const scanbacksRequired = Form.useWatch('scanbacksRequired', form)
  const witnessRequired = Form.useWatch('witnessRequired', form)
  const witnessCount = Form.useWatch('witnessCount', form)
  const notaryFee = parseFee(Form.useWatch('vendorFee', form))
  const clientFee = parseFee(Form.useWatch('clientFee', form))
  const witnessFee = parseFee(Form.useWatch('witnessFee', form))

  const notaryFeeWithWitnessFee = (notaryFee + witnessFee * witnessCount).toFixed(2)
  const clientFeeWithWitnessFee = (clientFee + witnessFee * witnessCount).toFixed(2)

  const footerOffset = fromOrderForm ? 10 : 18

  const toggleShowAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings)
  }

  const toggleShowStateSpecificPricing = () => {
    setShowStateSpecificPricing(!showStateSpecificPricing)
  }

  const toggleShowDocumentChecklist = () => {
    setShowDocumentChecklist(!showDocumentChecklist)
  }

  const toggleVisibleForAllClients = () => {
    setVisibleForAllClients(!visibleForAllClients)
  }

  const { company, companyLoading } = ReactQuery.useCompanyRequest(companyId)
  const { featurePolicies, featurePoliciesLoading } = ReactQuery.useFeaturePoliciesRequest(companyId)

  interface ProductWithStateSpecificArray extends Product {
    stateSpecificPricingArray: Array<{ state: string; clientFee: number; notaryFee: number }>
  }

  const onFinish = (values: ProductWithStateSpecificArray) => {
    values.scanbackReturnDeadline = returnDeadline
    values.scanbackReturnChoices = scanbackReturnChoices

    if (showStateSpecificPricing) {
      values.stateSpecificPricing = values.stateSpecificPricingArray.reduce((acc, obj) => {
        acc[obj.state] = { clientFee: obj.clientFee, notaryFee: obj.notaryFee }
        return acc
      }, {} as StateSpecificPricingInterface)
    }

    if (!witnessRequired) {
      values.witnessCount = null
      values.witnessFee = null
    }

    if (!showDocumentChecklist) {
      values.documentChecklist = []
    }

    if (!showExternalDocs) {
      values.autosetDocStatus = 'none'
    }

    if (!showSpecialInstructions) {
      values.specialInstructions = ''
    }

    saveProduct(
      snakeCaseToCamelCase(values),
      showDocumentChecklist,
      showSpecialInstructions,
      showStateSpecificPricing,
      visibleForAllClients
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  if (companyLoading || featurePoliciesLoading) {
    return <></>
  }

  const showVisibilityForAllClients = company.showVisibilityForAllClients && product.id === null && !fromOrderForm

  const stateSpecificPricingArray = Object.keys(product.stateSpecificPricing).reduce(
    (acc, key) =>
      acc.concat({
        state: key,
        notaryFee: product.stateSpecificPricing[key].notaryFee,
        clientFee: product.stateSpecificPricing[key].clientFee
      }),
    [] as Object[]
  )

  const initialValues = { ...product, stateSpecificPricingArray }
  if (initialValues.witnessCount === null) initialValues.witnessCount = 1
  if (initialValues.documentChecklist.length === 0) initialValues.documentChecklist = ['']
  if (initialValues.stateSpecificPricingArray.length === 0) initialValues.stateSpecificPricingArray = [{}]

  return (
    <Form
      id="addProductForm"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      colon={false}
      className="product-form-content"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Product name"
        name="productName"
        rules={[{ required: true, message: 'Please input your product name.' }]}
      >
        <Input />
      </Form.Item>

      {featurePolicies.hybridClosing && (
        <Form.Item label="Hybrid signing" name="hybridSigning" valuePropName="checked">
          <InputWithDescription InputType={Switch}>
            <span className="product-item-explanation">Consumer has the option to e-sign non critical documents</span>
            <Tooltip title="This has the advantage of cheaper, faster notary appointment with fewer papers to print and sign.">
              <a href="#" className="product-item-tooltip">
                (?)
              </a>
            </Tooltip>
          </InputWithDescription>
        </Form.Item>
      )}

      {showVisibilityForAllClients && (
        <Form.Item label="Visible?" name="visibleForAllClients">
          <Switch defaultChecked={visibleForAllClients} onChange={toggleVisibleForAllClients} />
          <span className="product-item-explanation">Set product default visibility for all clients</span>
        </Form.Item>
      )}

      <Form.Item label="Document checklist?" name="documentChecklistCheckbox" valuePropName="checked">
        <InputWithDescription
          InputType={Switch}
          defaultChecked={showDocumentChecklist}
          checked={showDocumentChecklist}
          onChange={toggleShowDocumentChecklist}
        >
          <span className="product-item-explanation">Show document-related checklist to the notary</span>
        </InputWithDescription>
      </Form.Item>

      {showDocumentChecklist && <DocumentChecklist />}

      <Form.Item label="Require scanbacks?" name="scanbacksRequired" valuePropName="checked">
        <InputWithDescription InputType={Switch}>
          <span className="product-item-explanation">Require notary to upload scanbacks after appointment</span>
        </InputWithDescription>
      </Form.Item>

      {scanbacksRequired && (
        <Scanbacks
          product={product}
          scanbackReturnChoices={scanbackReturnChoices}
          setScanbackReturnChoices={setScanbackReturnChoices}
          preferredReturnChoice={preferredReturnChoice}
          returnDeadline={returnDeadline}
          setReturnDeadline={setReturnDeadline}
        />
      )}

      <Form.Item label="Require attorney?" name="attorneyRequired" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="Require witness?" name="witnessRequired" valuePropName="checked">
        <Switch />
      </Form.Item>

      {witnessRequired && (
        <Form.Item label="Number of witnesses" name="witnessCount">
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' }
            ]}
          />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <a className="product-show-advanced" onClick={toggleShowAdvancedSettings}>
          {!showAdvancedSettings && 'Show advanced settings'}
          {showAdvancedSettings && 'Hide advanced settings'}
        </a>
      </Form.Item>

      {showAdvancedSettings && (
        <NewAdvancedSettings
          companyHasAugmentedClientExperience={company.hasAugmentedClientExperience}
          companyAutoStartAutomator={company.autoStartAutomator}
          showSpecialInstructions={showSpecialInstructions}
          setShowSpecialInstructions={setShowSpecialInstructions}
          showExternalDocs={showExternalDocs}
          setShowExternalDocs={setShowExternalDocs}
        />
      )}

      <Divider />

      {witnessRequired && (
        <Form.Item label="Witness fee" name="witnessFee" type="money">
          <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
            <span className="product-item-explanation">Per witness</span>
          </InputWithDescription>
        </Form.Item>
      )}

      <Form.Item label="Notary fee" name="vendorFee" className={company.showAdjustPrice ? 'mb-0' : ''} type="money">
        <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
          {witnessRequired && (
            <span className="product-item-explanation">{`$${notaryFeeWithWitnessFee} with witness fee`}</span>
          )}
        </InputWithDescription>
      </Form.Item>

      {company.showAdjustPrice && (
        <Form.Item name="autoPriced" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox className="product-form-label" data-test="auto-priced-checkbox">
            Adjust notary fee based on location
            <Tooltip title="Intelligently adjust notary fees for difficult locations based on local market rates. Never exceeds $10 more than your base fee.">
              <a href="#" className="product-item-tooltip">
                (?)
              </a>
            </Tooltip>
          </Checkbox>
        </Form.Item>
      )}

      {!featurePolicies.titleCompany && (
        <Form.Item label="Charge client" name="clientFee" type="money">
          <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
            {witnessRequired && (
              <span className="product-item-explanation">{`$${clientFeeWithWitnessFee} with witness fee`}</span>
            )}
          </InputWithDescription>
        </Form.Item>
      )}

      {(company.hasAugmentedClientExperience || allowStateSpecificPricing) && (
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Switch
            checked={showStateSpecificPricing}
            onChange={toggleShowStateSpecificPricing}
            data-test="state-pricing-toggle"
          />
          <span className="product-item-explanation">Charge client differently per state</span>
        </Form.Item>
      )}

      {showStateSpecificPricing && (
        <NewStateSpecificPricing
          companyId={companyId}
          witnessRequired={witnessRequired}
          totalWitnessFee={witnessFee * witnessCount}
        />
      )}

      <Form.Item name="submit" wrapperCol={{ offset: footerOffset, span: 16 }}>
        <Button type="primary" htmlType="submit" className="ant-btn-lg product-submit-btn">
          {submitBtnText}
        </Button>
      </Form.Item>
    </Form>
  )
}
