import React, { SVGProps } from 'react'

const Delete: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2L9 3H4C3.448 3 3 3.448 3 4C3 4.552 3.448 5 4 5H20C20.552 5 21 4.552 21 4C21 3.448 20.552 3 20 3H15L14 2H10ZM4.3457 7L5.90234 20.2324C6.02034 21.2404 6.87462 22 7.89062 22H16.1113C17.1273 22 17.9807 21.2394 18.0977 20.2324L19.6543 7H4.3457ZM10 9C10.552 9 11 9.448 11 10V19H10C9.448 19 9 18.552 9 18V10C9 9.448 9.448 9 10 9ZM14 9C14.552 9 15 9.448 15 10V18C15 18.552 14.552 19 14 19H13V10C13 9.448 13.448 9 14 9Z"
      fill="currentcolor"
    />
  </svg>
)

export default Delete
