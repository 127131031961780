import { camelCase, set, get } from 'lodash'

export const snakeCaseToCamelCase = (data: object): object => {
  const camelCased = {}

  for (const key in data) {
    const value = get(data, key)
    const camelCasedKey = key === key.toUpperCase() ? key : camelCase(key)

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      set(camelCased, camelCasedKey, snakeCaseToCamelCase(value))
    } else {
      set(camelCased, camelCasedKey, value)
    }
  }

  return camelCased
}
