import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { MarkAsReadProps } from './types'

export const MarkAsRead: FC<MarkAsReadProps> = ({ notification, changeReadStatus }): ReactElement => {
  return (
    <div className="change-read-status">
      {notification.readBy ? (
        <div onClick={(e) => changeReadStatus(e, notification, false)} className="mark-unread">
          <Icons.Undo className="notification-read-icon" />
          Mark as unread
        </div>
      ) : (
        <div onClick={(e) => changeReadStatus(e, notification, true)} className="mark-read">
          <Icons.Check className="notification-read-icon" />
          Mark as read
        </div>
      )}
    </div>
  )
}
