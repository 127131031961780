export const GLOBAL = {
  CONSUMER: 'Consumer',
  COSIGNER: 'Co-signer',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email address',
  MOBILE_PHONE: 'Mobile phone',
  HOME_PHONE: 'Home phone',
  WORK_PHONE: 'Work phone',
  CONTROLS: {
    EDIT: 'Edit',
    ADD: 'Add',
    CANCEL: 'Cancel',
    SAVE: 'Save'
  }
}
