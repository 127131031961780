import React, { FC, ReactElement } from 'react'
import { Row, Col } from 'antd'
import { ClientAndParticipantPopover } from '../ClientAndParticipantPopover/ClientAndParticipantPopover'

export const RoutingHeader: FC<{ newCommentUiFlipperEnabled: boolean }> = ({
  newCommentUiFlipperEnabled
}): ReactElement => {
  return (
    <Row className="routing-header" align="bottom">
      <Col xs={24} sm={16}>
        <h3>Email routing</h3>
        <p>Configure your company email address, accounting email address, and more.</p>
      </Col>
      <Col xs={24} sm={8}>
        <ClientAndParticipantPopover newCommentUiFlipperEnabled={newCommentUiFlipperEnabled} />
      </Col>
    </Row>
  )
}
