import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import './styles.modules.scss'

export const PinnedColumn = () => {
  return {
    dataIndex: 'pinned',
    className: 'table-column pinned-column',
    render: (pinned: boolean | null) => {
      return <span>{pinned && <Icons.Pushpin className="pinned-icon" />}</span>
    }
  }
}
