import React, { SVGProps } from 'react'

const Bell: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C11.172 2 10.5 2.672 10.5 3.5V4.19531C7.91318 4.86209 6 7.2048 6 10V16L4.46484 17.1562H4.46289C4.32099 17.2466 4.20418 17.3713 4.12327 17.5188C4.04236 17.6663 3.99996 17.8318 4 18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H12H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.8318 19.9576 17.6663 19.8767 17.5188C19.7958 17.3713 19.679 17.2466 19.5371 17.1562L18 16V10C18 7.2048 16.0868 4.86209 13.5 4.19531V3.5C13.5 2.672 12.828 2 12 2ZM10 20C10 21.1 10.9 22 12 22C13.1 22 14 21.1 14 20H10Z"
      fill="currentColor"
    />
  </svg>
)

export default Bell
