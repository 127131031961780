import React, { FC, ReactElement } from 'react'
import { AddClientModalForm } from './AddClientModalForm'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import '../../Shared/Ant/App.less'

export const AddClientModal: FC = (): ReactElement => {
  return (
    <Provider store={store}>
      <AddClientModalForm />
    </Provider>
  )
}
