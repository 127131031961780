import React from 'react'
import { Typography, Row } from 'antd'
import '../styles.modules.scss'
import { defaultSortOrder } from '../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../constants/COLUMN_SORTER'
import { CompanyTeamMember } from '../types'

export const NameColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Name',
    dataIndex: 'name',
    defaultSortOrder: defaultSortOrder('name', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'name',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    render: (text: string, companyTeamMember: CompanyTeamMember) => {
      return (
        <>
          <Row>
            <Typography.Text>{companyTeamMember.fullName}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text className="email">{companyTeamMember.email}</Typography.Text>
          </Row>
        </>
      )
    }
  }
}
