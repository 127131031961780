import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Avatar, Tooltip, Typography } from 'antd'
import { UserPresence } from '../../types'

export const ViewingUsers: FC<{ userPresence: UserPresence[] }> = ({ userPresence }): ReactElement => {
  const [user, setUser] = useState<UserPresence>()
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')

  useEffect(() => {
    if (!userPresence || userPresence.length < 1) {
      setUser(undefined)
      setTitle('')
      setSubTitle('')
      return
    }

    setUser(userPresence[0])
    setTitle(`Currently Viewing: ${userPresence.map((user) => user.name).join(', ')}`)
    setSubTitle(userPresence.length > 1 ? `${userPresence.length - 1} more` : '')
  })

  return (
    <span className="viewing-users" data-test="viewing-users">
      {user && (
        <Tooltip title={title} placement="topRight">
          <Avatar className="owner-avatar" size="small" shape="square" src={user.photo_url ? user.photo_url : ''}>
            {!user.photo_url && <span style={{ textTransform: 'uppercase' }}>{user.initials}</span>}
          </Avatar>
          <div>
            <Typography.Text type="secondary">{subTitle}</Typography.Text>
          </div>
        </Tooltip>
      )}
    </span>
  )
}
