import React from 'react'
import classnames from 'classnames'
import { ICONS } from 'icons'
import { ORDER_DOC_RETURN_TRACKING as MESSAGES } from '../../../../../messages/ORDER_DOC_RETURN_TRACKING'
import { InTransitDelayCallout } from './InTransitDelayCallout/InTransitDelayCallout'
import s from './styles.module.scss'

const TIMELINE_EVENT_TYPES = [
  'shipping_label_created',
  'documents_not_dropped_off',
  'drop_off_missed_cut_off',
  'documents_received_by_carrier',
  'documents_in_transit',
  'delivery_delayed_in_transit',
  'documents_delivered'
] as const

export type TimelineEventType = typeof TIMELINE_EVENT_TYPES[number]

interface TimelineEventProps {
  type: TimelineEventType
  isObserved?: boolean
  isError?: boolean
  time?: string
  originalEstimatedDeliveryTime: string
  currentEstimatedDeliveryTime: string
}

export const TimelineEvent: React.FC<TimelineEventProps> = ({
  type,
  isObserved,
  isError,
  time,
  originalEstimatedDeliveryTime,
  currentEstimatedDeliveryTime
}) => {
  // Get the classes for this timeline event.
  const classes = classnames(s.event, {
    [s.event_complete]: isObserved,
    [s.event_error]: isError
  })

  // Show the timestamp if the time is available.
  const timeStampElement = time ? (
    <div className={s.event__timestamp}>{MESSAGES.FEDEX_DOC_RETURN.EVENT.TIME(time)}</div>
  ) : null

  // Get the right icon.
  const iconClass = isError ? ICONS.EXCLAMATION_TRIANGLE : ICONS.CHECK_CIRCLE

  // Get the error description, if there is one.
  const errorCallouts = {
    delivery_delayed_in_transit: (
      <InTransitDelayCallout original={originalEstimatedDeliveryTime} current={currentEstimatedDeliveryTime} />
    ),
    documents_not_dropped_off: MESSAGES.FEDEX_DOC_RETURN.EVENT.ERROR_CALLOUT.DOCS_NOT_DROPPED_OFF,
    drop_off_missed_cut_off: MESSAGES.FEDEX_DOC_RETURN.EVENT.ERROR_CALLOUT.DROP_OFF_MISSED_CUT_OFF,
    shipping_label_created: undefined,
    documents_received_by_carrier: undefined,
    documents_in_transit: undefined,
    documents_delivered: undefined
  }

  const errorCalloutElement =
    isError && errorCallouts[type] ? <div className={s.event__errorCallout}>{errorCallouts[type]}</div> : null

  return (
    <div className={classes}>
      <div className={s.event__icon}>
        <i className={iconClass} />
      </div>
      <div className={s.event__title}>{MESSAGES.FEDEX_DOC_RETURN.EVENT.TITLE[type]}</div>
      {timeStampElement}
      {errorCalloutElement}
    </div>
  )
}
