import React, { FC, ReactElement } from 'react'
import { Row, Col } from 'antd'
import { SettingsBlockParams } from './types'
import { SettingRow } from './SettingRow'

export const SettingsBlock: FC<SettingsBlockParams> = ({
  id,
  companyId,
  title,
  settings,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  const settingsRows = settings.map((setting, index) => (
    <SettingRow
      id={id}
      companyId={companyId}
      key={index}
      setting={setting}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  ))

  return (
    <div className="settings-block">
      <div className="settings-block-title">
        <Row>
          <Col>
            <h4>{title}</h4>
          </Col>
        </Row>
      </div>
      <div className="settings-block-content">
        <Row className="settings-block-header">
          <Col span={10}>When...</Col>
          <Col span={8}>Send In-app notification to...</Col>
          <Col span={6}>Email...</Col>
        </Row>
        {settingsRows}
      </div>
    </div>
  )
}
