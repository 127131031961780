import React, { FC, ReactElement } from 'react'
import { AddClientTeamMemberModalForm } from './AddClientTeamMemberModalForm'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import '../../Shared/Ant/App.less'

export const AddClientTeamMemberModal: FC = (): ReactElement => {
  return (
    <Provider store={store}>
      <AddClientTeamMemberModalForm />
    </Provider>
  )
}
