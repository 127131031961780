import { Form, Input, Select, Collapse, Switch } from 'antd'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import './styles.modules.scss'
import { apiCall, Requests } from '../../../api'
import { FORM_VALUES, USER_ROLES } from '../../../constants/GLOBAL'
import { Scanbacks } from '../../ProductForm/Scanbacks'
import { Product, ScanbackReturnChoice } from '../../ProductForm/types'
import { OrderInformationFormProps } from './orderInformationFormProps'
import { Escrow } from '../../../models/order/escrow'
import { Snapdocs } from '../../../hooks/api'
import { get } from 'lodash'

const OrderInformationForm: FC<OrderInformationFormProps> = ({
  // Potentially refactor some params in MNS-2738
  order,
  isOrderTransactionTypeFlipperEnabled,
  areDocsInTransit,
  isTitleCompany,
  isEscrowAccessible,
  changeDocumentDelivery,
  closeOfEscrowFeaturePolicy,
  userRole
}): ReactElement => {
  const { Panel } = Collapse
  const initialSelectedProduct = {} as Product
  const initialScanbackReturnChoice = {} as ScanbackReturnChoice

  const [scanbacksRequired, setScanbacksRequired] = useState(false)
  const [shareSpecialInstructions, setShareSpecialInstructions] = useState(true)
  const [isClientSelected, setIsClientSelected] = useState(false)
  const [isProductSelected, setIsProductSelected] = useState(false)
  const [specialInstructionsExist, setSpecialInstructionsExist] = useState(false)
  const [clientSelectOptions, setClientSelectOptions] = useState([])
  const [clientTeamMemberOptions, setClientTeamMemberOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [scanbackReturnChoices, setScanbackReturnChoices] = useState(new Array<ScanbackReturnChoice>())
  const [returnDeadline, setReturnDeadline] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<Product>(initialSelectedProduct)
  const [preferredReturnChoice] = useState<ScanbackReturnChoice>(initialScanbackReturnChoice)

  const { request: clientSelectOptionsRequest, response: clientSelectOptionsResponse } = Snapdocs.useResources(
    Requests.clients({})
  )

  useEffect(() => {
    if (Requests.hasLoaded(clientSelectOptionsRequest)) {
      const newClientOptions = get(clientSelectOptionsRequest, 'ids', []).map((id: number) => ({
        label: clientSelectOptionsResponse[id].name,
        value: clientSelectOptionsResponse[id].id
      }))
      setClientSelectOptions(newClientOptions)
    }
  }, [clientSelectOptionsRequest])

  const languageOptions = FORM_VALUES.LANGUAGES.map((language: string) => ({
    value: language
  }))

  const shippingOptions = FORM_VALUES.ORDER_SHIPPING_SERVICES.map((shippingOption: string) => ({
    value: shippingOption
  }))

  const transactionTypeOptions = FORM_VALUES.TRANSACTION_TYPES.map((transactionTypeOption: string) => ({
    value: transactionTypeOption
  }))

  const toggleScanbacksRequired = () => {
    setScanbacksRequired(!scanbacksRequired)
  }

  const toggleShareSpecialInstructions = () => {
    setShareSpecialInstructions(!shareSpecialInstructions)
  }

  const setProduct = (product: React.SetStateAction<Product>) => {
    setIsProductSelected(true)
    setSelectedProduct(product)
  }

  const toggleSpecialInstructionsExist = (text: string) => {
    text.length > 0 ? setSpecialInstructionsExist(true) : setSpecialInstructionsExist(false)
  }

  const escrowLabel = (userRole: string, closeOfEscrowFeaturePolicy: string) => {
    if (closeOfEscrowFeaturePolicy === 'close_of_escrow') {
      return 'Close of Escrow'
    } else {
      if (userRole === USER_ROLES.COMPANY_MEMBER) {
        return 'Docs Returned By Date'
      } else {
        return 'Need Docs Back By'
      }
    }
  }

  const escrowRows = order.escrow?.map((escrow: Escrow, i: number) => (
    <Form.Item name="closingInstructions" className="form-row" key={i}>
      <Input type="text" aria-label="Closing instructions" value={escrow.closingInstructions} />
    </Form.Item>
  ))

  const selectClient = (clientId: number) => {
    setIsClientSelected(true)

    // TODO: MNS-3572 - Migrate this api call to using Redux store
    const email = ''
    apiCall.get(Requests.clientTeamMembers({ clientId, email }).path).then((resp) => {
      const clientTeamMemberOptions = resp.data.team_members.map((teamMember: { id: number; full_name: string }) => ({
        label: teamMember.full_name,
        value: teamMember.id
      }))
      setClientTeamMemberOptions(clientTeamMemberOptions)
    })

    // TODO: MNS-3572 - Migrate this api call to using Redux store
    apiCall.get(Requests.clientProducts({ clientId }).path).then((resp) => {
      const clientProducts = resp.data.products.map((product: { id: number; name: string }) => ({
        label: product.name,
        value: product.id
      }))
      setProductOptions(clientProducts)
    })
  }

  return (
    <>
      <Form className="order-information-form" layout="vertical">
        <div className="order-information-header">Order information</div>

        <Form.Item
          name="client"
          label="Client"
          rules={[{ required: true, message: 'A client is required' }]}
          className="order-information-input"
        >
          <Select showSearch allowClear options={clientSelectOptions} onChange={selectClient} />
        </Form.Item>

        {isClientSelected && (
          <Form.Item name="clientTeamMember" label="Client contact">
            <Select showSearch allowClear options={clientTeamMemberOptions} />
          </Form.Item>
        )}

        {isOrderTransactionTypeFlipperEnabled && (
          <Form.Item
            name="transactionType"
            label="Transaction type"
            rules={[{ required: true, message: 'A transaction type is required' }]}
          >
            <Select showSearch allowClear options={transactionTypeOptions} />
          </Form.Item>
        )}

        {isClientSelected && (
          <Form.Item
            label="Product name and fee"
            name="product"
            rules={[{ required: true, message: 'A product is required' }]}
          >
            <Select
              showSearch
              allowClear
              options={productOptions}
              onChange={(e: React.SetStateAction<Product>) => setProduct(e)}
            />
          </Form.Item>
        )}

        {isProductSelected && (
          <Form.Item name="scanbacksRequired" valuePropName="checked">
            <Switch onChange={toggleScanbacksRequired} checked={scanbacksRequired} />
            <span className="product-item-explanation">Require notary to upload scanbacks?</span>
          </Form.Item>
        )}

        {scanbacksRequired && (
          <Scanbacks
            product={selectedProduct}
            scanbackReturnChoices={scanbackReturnChoices}
            setScanbackReturnChoices={setScanbackReturnChoices}
            preferredReturnChoice={preferredReturnChoice}
            returnDeadline={returnDeadline}
            setReturnDeadline={setReturnDeadline}
          />
        )}

        {isClientSelected && (
          <>
            {areDocsInTransit && isProductSelected && (
              <span className="product-item-explanation">Document delivery: {order?.shippingMethod}</span>
            )}

            {!areDocsInTransit && isProductSelected && changeDocumentDelivery && (
              <>
                <Form.Item
                  label="Delivery method"
                  name="deliveryMethod"
                  rules={[{ required: true, message: 'A delivery method is required' }]}
                >
                  <Select showSearch allowClear options={shippingOptions} />
                </Form.Item>
              </>
            )}
          </>
        )}

        {!isTitleCompany && (
          <Form.Item label="Lender" name="lender" className="form-row">
            <Input type="text" aria-label="Lender" />
          </Form.Item>
        )}

        <Form.Item label="File #" name="fileNumber" className="form-row">
          <Input type="text" aria-label="File #" />
        </Form.Item>

        {isEscrowAccessible && userRole && closeOfEscrowFeaturePolicy && order && order.escrow.length > 0 && (
          <>
            <div className="escrow-label">{escrowLabel(userRole, closeOfEscrowFeaturePolicy)}</div>
            <div>{escrowRows}</div>
          </>
        )}

        <Form.Item label="Special instructions" name="specialInstructions">
          <Input.TextArea
            placeholder="e.g. Are funds due at closing?"
            onChange={(e: { target: { value: string } }) => toggleSpecialInstructionsExist(e.target.value)}
            autoSize
          />
        </Form.Item>

        {specialInstructionsExist && (
          <Form.Item name="shareSpecialInstructions" valuePropName="checked">
            <Switch onChange={toggleShareSpecialInstructions} checked={shareSpecialInstructions} />
            <span className="product-item-explanation">Share special instructions with notary</span>
          </Form.Item>
        )}

        <Collapse className="order-information-collapse">
          <Panel header="Add language requirement for notary?" key="languagePanel" className="order-information-panel">
            <Form.Item label="Language requirement" name="languageRequirement">
              <Select showSearch allowClear options={languageOptions} />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </>
  )
}

export default OrderInformationForm
