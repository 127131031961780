import React, { FC, ReactElement } from 'react'
import { Button, Select, Form } from 'antd'
import { values } from 'lodash'
import { Snapdocs } from '../../../../../../hooks/api'
import { Requests } from '../../../../../../api'
import { ClientStepProps } from '../../types'

export const ClientStep: FC<ClientStepProps> = ({ onFinish, onValuesChange }): ReactElement => {
  const [form] = Form.useForm()
  const { response: clients } = Snapdocs.useResources(Requests.clients({}))

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <Form.Item label="Client" name="clientId" rules={[{ required: true, message: 'Client is required' }]}>
          <Select showSearch placeholder="Select client" optionFilterProp="children">
            {values(clients).map((client) => (
              <Select.Option value={client.id} key={client.id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Continue
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
