import { Row, Col } from 'antd'
import React, { FC, ReactElement, useEffect } from 'react'
import { StateSpecificPricingProps } from './types'
import { StateSpecificPricingRow } from './StateSpecificPricingRow'
import { FORM_VALUES } from '../../constants/GLOBAL'

export const StateSpecificPricing: FC<StateSpecificPricingProps> = ({
  stateSpecificPricing,
  setStateSpecificPricing,
  companyId,
  witnessRequired,
  totalWitnessFee
}): ReactElement => {
  const addAnotherRow = () => {
    stateSpecificPricing[''] = { clientFee: 0, notaryFee: 0 }
    setStateSpecificPricing({ ...stateSpecificPricing })
  }

  useEffect(() => {
    if (Object.keys(stateSpecificPricing).length === 0) {
      addAnotherRow()
    }
  }, [])

  return (
    <>
      {Object.keys(stateSpecificPricing).map((state: string, index: number) => {
        return (
          <StateSpecificPricingRow
            key={'state-' + state + index}
            statesList={FORM_VALUES.US_STATES}
            stateSpecificPricing={stateSpecificPricing}
            setStateSpecificPricing={setStateSpecificPricing}
            state={state}
            clientFee={stateSpecificPricing[state].clientFee}
            notaryFee={stateSpecificPricing[state].notaryFee}
            companyId={companyId}
            witnessRequired={witnessRequired}
            totalWitnessFee={totalWitnessFee}
          />
        )
      })}
      <Row>
        <Col offset={8} className="add-more-states">
          <a onClick={addAnotherRow}>+ Add Another State</a>
        </Col>
      </Row>
    </>
  )
}
