import React from 'react'
import _ from 'lodash'
import { ICONS } from 'icons'
import { Avatar, AvatarProps } from 'components/Shared/Avatar'
import { SignerDetail } from './SignerDetail'
import s from './styles.module.scss'

interface OrderSignerDetailsViewProps {
  firstName?: string
  lastName?: string
  email?: string
  mobilePhone?: string
  workPhone?: string
  homePhone?: string
  avatarProps?: AvatarProps
}

export const OrderSignerDetailsView: React.FC<OrderSignerDetailsViewProps> = ({
  firstName,
  lastName,
  email,
  mobilePhone,
  workPhone,
  homePhone,
  avatarProps
}) => {
  const userInitials = (_.first(firstName) || '') + _.first(lastName)
  const avatarElement = email ? (
    <div className={s.signerView__avatar}>
      <Avatar initials={userInitials} {...avatarProps} />
    </div>
  ) : null

  return (
    <div className={s.signerView}>
      {avatarElement}
      <div className={s.signerView__details}>
        <SignerDetail value={`${firstName} ${lastName}`} />
        <SignerDetail icon={ICONS.EMAIL} value={email} />
        <SignerDetail icon={ICONS.MOBILE_PHONE} value={mobilePhone} />
        <SignerDetail icon={ICONS.HOME_PHONE} value={homePhone} />
        <SignerDetail icon={ICONS.WORK_PHONE} value={workPhone} />
      </div>
    </div>
  )
}

export default OrderSignerDetailsView
