import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './Callout.module.scss'

function Callout({ children, color, className, ...props }) {
  const classes = classnames(s.callout, s[`callout_${color}`], className)

  if (!children) {
    return null
  }

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

Callout.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['gray', 'red', 'yellow', 'green', 'blue']),
  className: PropTypes.string
}

Callout.defaultProps = {
  children: null,
  color: 'gray',
  className: null
}

export default Callout
