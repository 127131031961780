import { snakeCase, set, get } from 'lodash'

export const camelCaseToSnakeCase = (data: object): object => {
  const snakeCased = {}

  for (const key in data) {
    const value = get(data, key)
    const snakeCasedKey = key === key.toUpperCase() ? key : snakeCase(key)

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      set(snakeCased, snakeCasedKey, camelCaseToSnakeCase(value))
    } else {
      set(snakeCased, snakeCasedKey, value)
    }
  }

  return snakeCased
}
