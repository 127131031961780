import React, { SVGProps } from 'react'

const Calendar: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 1C6.448 1 6 1.448 6 2V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V2C18 1.448 17.552 1 17 1C16.448 1 16 1.448 16 2V3H8V2C8 1.448 7.552 1 7 1ZM5 8H19V18C19 18.552 18.552 19 18 19H6C5.448 19 5 18.552 5 18V8Z"
      fill="currentcolor"
    />
  </svg>
)

export default Calendar
