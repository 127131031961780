import React, { FC, ReactElement } from 'react'
import moment, { Moment } from 'moment-timezone'
import { NotificationCreatedAtProps } from './types'

export const NotificationCreatedAt: FC<NotificationCreatedAtProps> = ({ dateTime }): ReactElement => {
  let formattedDateTime: string
  const time: Moment = moment(dateTime)

  if (time.format('YYYY/MM/DD') === moment().format('YYYY/MM/DD')) {
    formattedDateTime = time.format('LT')
  } else {
    formattedDateTime = time.format('M/DD h:mm a')
  }

  return <div className="notification-created-at">{formattedDateTime}</div>
}
