import React, { FC, ReactElement, useState } from 'react'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { Requests } from '../../../api/requests'
import { RoutingForm } from './RoutingForm'
import { updateResource } from '../../../actions/updateResource'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { RoutingContentParams } from './types'

export const RoutingContent: FC<RoutingContentParams> = ({ companyId, emailSettingsId }): ReactElement => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { request: companyRequest, response: company } = Snapdocs.useResources(Requests.company({ id: companyId }))
  const { request: emailSettingsRequest, response: emailSettings } = Snapdocs.useResources(
    Requests.emailSettings({ id: emailSettingsId, companyId })
  )

  const handleFinish = (values: any) => {
    setIsLoading(true)

    dispatch<any>(
      updateResource({
        path: companyRequest.requestKey,
        type: companyRequest.resourceType,
        resource: company,
        params: {
          company: {
            defaultCompanyEmail: values.defaultCompanyEmail,
            postClosingEmail: values.postClosingEmail,
            mailBcc: values.mailBcc,
            observerNotificationLevel: values.observerNotificationLevel,
            invoiceMail: values.invoiceMail,
            displayNotaryInfoOnClientInvoice: values.displayNotaryInfoOnClientInvoice,
            displayOrderAddressOnClientInvoice: values.displayOrderAddressOnClientInvoice,
            accountingEmail: values.accountingEmail
          }
        }
      })
    ).then(() => {
      dispatch<any>(
        updateResource({
          path: emailSettingsRequest.requestKey,
          type: emailSettingsRequest.resourceType,
          resource: emailSettings,
          params: {
            emailSettings: {
              orderClientOrNotaryReplyTo: values.orderClientOrNotaryReplyTo,
              emailsSentToUsersReplyTo: values.emailsSentToUsersReplyTo,
              orderSendClientInvoiceFrom: values.orderSendClientInvoiceFrom
            }
          }
        })
      ).then(() => {
        setIsLoading(false)
      })
    })
  }

  if (!Requests.hasLoaded(companyRequest) || !Requests.hasLoaded(emailSettingsRequest)) {
    return <></>
  }

  return (
    <div className="routing-content">
      <RoutingForm
        handleFinish={handleFinish}
        emailSettings={emailSettings}
        company={company}
        companyOptions={companyRequest.meta.options}
        emailSettingsOptions={emailSettingsRequest.meta.options}
        isLoading={isLoading}
      />
    </div>
  )
}
