import React, { FC, ReactElement } from 'react'
import { Button } from 'antd'
import { ResetButtonProps } from '../types'

export const ResetButton: FC<ResetButtonProps> = ({ onClick }): ReactElement => {
  return (
    <Button type="default" data-test="resetSortAndFilter" className="mrl" onClick={onClick}>
      Reset
    </Button>
  )
}
