import React, { FC, ReactElement } from 'react'
import moment from 'moment-timezone'
import { initials } from '../../../utils/initials'
import { NotificationReadByProps } from './types'

export const NotificationReadBy: FC<NotificationReadByProps> = ({ readBy, readAt }): ReactElement => {
  return (
    <div className="read-by" title={`Read at ${moment(readAt).format('M/D/YY h:mm a')}`}>
      <span className="initials">{initials(readBy)}</span>
      Read by {readBy}
    </div>
  )
}
