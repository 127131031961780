/* eslint react/no-unescaped-entities: "off" */
import React, { FC, ReactElement } from 'react'
import { MarkAsReadBulkContentProps } from '../types'
import { Snapdocs } from '../../../../hooks/api/snapdocs'
import { Requests } from '../../../../api/requests'
import { get } from 'lodash'
import MESSAGES from './MarkAsReadBulk.messages'

export const MarkAsReadBulkContent: FC<MarkAsReadBulkContentProps> = ({ unreadCount, owner }): ReactElement => {
  const { request, response: appConfig } = Snapdocs.useResources(Requests.appConfig())

  const name = (owner: string) => {
    if (owner === 'mine') {
      return get(appConfig, ['currentUser', 'fullName'])
    }
    return get(appConfig, ['currentCompany', 'name'])
  }

  if (Requests.isLoading(request)) {
    return <></>
  }

  return (
    <div className="mark-as-read-bulk-container">
      <p className="title">
        Mark
        <strong>
          {' '}
          all {unreadCount} of {name(owner)}'s{' '}
        </strong>
        notifications as read?
      </p>
      {owner === 'mine' ? (
        <p>{MESSAGES.CONFIRM_PROMPT.ORDER_OWNER_DESCRIPTION}</p>
      ) : (
        <p>{MESSAGES.CONFIRM_PROMPT.COMPANY_DESCRIPTION}</p>
      )}
    </div>
  )
}
