import React, { FC, ReactElement } from 'react'
import { Row, Col } from 'antd'
import { AppointmentFullTime } from './AppointmentFullTime'
import { NotificationIcon } from './NotificationIcon'
import { NotificationCreatedAt } from './NotificationCreatedAt'
import { NotificationReadBy } from './NotificationReadBy'
import { MarkAsRead } from './MarkAsRead'
import { StandardNotificationProps } from './types'
import { CompanyName } from './CompanyName'

export const StandardNotification: FC<StandardNotificationProps> = ({
  notification,
  changeReadStatus
}): ReactElement => {
  return (
    <div className="order-notification">
      <Row className={`${!notification.readBy ? 'not-read' : 'read'}`} wrap={false}>
        <Col flex="250px">
          <NotificationIcon classification={notification.classification} />
          <AppointmentFullTime date={notification.appointmentDate} time={notification.appointmentTime} />
        </Col>
        <Col flex="auto">
          <div>
            <span className="subject">{notification.subject}</span>
            <span className="ellipse" />
            <span>
              {notification.signer} (#{notification.orderId})
            </span>
          </div>
          <div className="notification-preview">
            <div className="notification-text">{notification.text}</div>
          </div>
        </Col>
        <CompanyName notification={notification} />
        <Col flex="250px">
          <div className="text-right">
            <MarkAsRead key={notification.id} notification={notification} changeReadStatus={changeReadStatus} />
            <div className="notification-created-at">
              <NotificationCreatedAt dateTime={notification.createdAt} />
            </div>
            {notification.readBy && notification.readAt && (
              <NotificationReadBy readBy={notification.readBy} readAt={notification.readAt} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
