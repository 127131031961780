export enum DocsToNotarySent {
  Blank = '',
  SentByClient = 'sent_by_client',
  Sent = 'sent',
  NotaryPickedUpDocs = 'notary_picked_up_docs',
  Overnighted = 'overnighted',
  AtClosing = 'at_closing',
  EmailedToNotary = 'emailed_to_notary',
  DirectLinks = 'direct_links'
}
