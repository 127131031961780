import React, { FC, ReactElement, useState } from 'react'
import { Select, Form, Input, Button, Checkbox } from 'antd'
import { CommentFormProps, CommentFormValues } from './types'
import { UserRole } from 'models/user/user'
import CommentFormMessages from './CommentForm.messages'
import { useMutation } from '@tanstack/react-query'
import { set } from 'lodash'
import axios from 'axios'
import { CommentShared } from 'models/comment/comment'
import '../../Shared/Ant/App.less'

export const CommentForm: FC<CommentFormProps> = ({
  commentRecipients,
  userRole,
  showNotarySmsText,
  toFieldHelpText,
  toFieldSelectText,
  textFieldPlaceHolder,
  orderCommentPath,
  csrfToken,
  orderHasParticipants
}): ReactElement => {
  const [form] = Form.useForm()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [showToFieldHelpText, setShowToFieldHelpText] = useState(false)
  const [showSendEmailNotificationFieldHelpText, setShowSendEmailNotificationFieldHelpText] = useState(false)
  const mutation = useMutation((newComment) => {
    return axios.post(orderCommentPath, newComment, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
  })

  const onFieldsChange = () => {
    handleSetShowToFieldHelpText()

    if (showNotarySmsText && form.getFieldValue('to').includes(CommentShared.WithAgent)) {
      setShowSendEmailNotificationFieldHelpText(true)
    } else {
      setShowSendEmailNotificationFieldHelpText(false)
    }
  }

  const handleSetShowToFieldHelpText = () => {
    if (
      userRole === UserRole.Client &&
      (form.getFieldValue('to').includes(CommentShared.WithCompany) ||
        form.getFieldValue('to').includes(CommentShared.WithAgent) ||
        form.getFieldValue('to').includes(CommentShared.WithConsumer))
    ) {
      setShowToFieldHelpText(true)
    } else if (
      (userRole === UserRole.Notary || userRole === UserRole.Scheduler) &&
      form.getFieldValue('to').includes(CommentShared.WithClient)
    ) {
      setShowToFieldHelpText(true)
    } else if (userRole === UserRole.Observer && form.getFieldValue('to').length > 0) {
      setShowToFieldHelpText(true)
    } else {
      setShowToFieldHelpText(false)
    }
  }

  const showSendEmailNotificationCheckbox = () => {
    return userRole === UserRole.Scheduler
  }

  const onFinish = (values: CommentFormValues) => {
    setSubmitButtonDisabled(true)

    const params = {
      order_comment: {
        send_email_notification: values.sendEmailNotification,
        text: values.text
      }
    }

    values.to.forEach((recipient) => set(params, ['order_comment', recipient], true))

    // @ts-ignore
    mutation.mutate(params, {
      onSuccess: onSuccess
    })
  }

  const onSuccess = (response: any) => {
    form.resetFields()
    setSubmitButtonDisabled(false)
    setShowToFieldHelpText(false)
    setShowSendEmailNotificationFieldHelpText(false)
    document.dispatchEvent(new CustomEvent('newOrderCommentAdded', { detail: response.data }))
  }

  return (
    <Form
      form={form}
      name="form"
      initialValues={{ to: [], text: '', sendEmailNotification: true }}
      onFieldsChange={onFieldsChange}
      className="comment-form"
      onFinish={onFinish}
    >
      <Form.Item noStyle shouldUpdate>
        <Form.Item
          className="to-field"
          name="to"
          label="To"
          labelCol={{ span: 1 }}
          wrapperCol={{ span: 24 }}
          help={orderHasParticipants && showToFieldHelpText ? toFieldHelpText : undefined}
        >
          <Select mode="multiple" allowClear placeholder={toFieldSelectText} options={commentRecipients} />
        </Form.Item>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }: any) =>
          getFieldValue('to').length > 0 && (
            <Form.Item
              className={
                showSendEmailNotificationCheckbox()
                  ? 'send-email-notification-field'
                  : 'send-email-notification-field-no-checkbox'
              }
              name="sendEmailNotification"
              wrapperCol={{ span: 23, offset: 1 }}
              valuePropName="checked"
              help={
                showSendEmailNotificationFieldHelpText ? CommentFormMessages.SEND_EMAIL_NOTIFICATION.HELP : undefined
              }
            >
              {showSendEmailNotificationCheckbox() && (
                <Checkbox>{CommentFormMessages.SEND_EMAIL_NOTIFICATION.CHECKBOX}</Checkbox>
              )}
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name="text" className="text-field">
        <Input.TextArea placeholder={textFieldPlaceHolder} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }: any) =>
          (getFieldValue('to').length > 0 || userRole !== UserRole.Scheduler) && (
            <Form.Item className="submit-field">
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitButtonDisabled || form.getFieldValue('text') === '' || getFieldValue('to').length === 0}
              >
                {CommentFormMessages.SUBMIT.CREATE_COMMENT}
              </Button>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }: any) =>
          userRole === UserRole.Scheduler &&
          getFieldValue('to').length === 0 && (
            <Form.Item className="submit-field">
              <Button
                type="default"
                htmlType="submit"
                disabled={submitButtonDisabled || form.getFieldValue('text') === ''}
              >
                {CommentFormMessages.SUBMIT.CREATE_NOTE}
              </Button>
            </Form.Item>
          )
        }
      </Form.Item>
    </Form>
  )
}
