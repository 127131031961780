import React, { FC, ReactElement, useState } from 'react'
import { Table } from 'antd'
import { Requests } from '../../../api/requests'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { camelCase, get, cloneDeep } from 'lodash'
import { getQueryParamsState, setQueryParamsState } from '../../../utils/queryParams'
import * as qs from 'qs'
import { W9Column } from './Columns/W9/W9Column'
import { NotaryIdColumn } from './Columns/NotaryId/NotaryIdColumn'
import { NotaryLastNameColumn } from './Columns/NotaryLastName/NotaryLastNameColumn'
import { CityColumn } from './Columns/City/CityColumn'
import { StateColumn } from './Columns/State/StateColumn'
import { PaymentsCurrentYearColumn } from './Columns/PaymentsCurrentYear/PaymentsCurrentYearColumn'
import { PaymentsPreviousYearColumn } from './Columns/PaymentsPreviousYear/PaymentsPreviousYearColumn'
import { VerificationStatusColumn } from './Columns/VerificationStatus/VerificationStatusColumn'
import { Notary, SorterProp } from './types'
import { tableChange } from '../../../utils/table/tableChange'

export const NotariesTable: FC<{ companyId: number }> = ({ companyId }): ReactElement => {
  const [searchParams, setSearchParams] = useState(getQueryParamsState())
  const sorter = get(searchParams, ['q', 's'], '').split(' ')
  const sortColumn = sorter.length > 1 ? camelCase(sorter[0]) : ''
  const sortOrder = sorter.length > 1 ? sorter[1] : ''

  const { request: adminNotariesRequest, response: adminNotaries } = Snapdocs.useResources(
    Requests.adminNotaries(qs.stringify(searchParams))
  )
  const { request: featurePoliciesRequest, response: featurePolicies } = Snapdocs.useResources(
    Requests.featurePolicies({ companyId })
  )

  const paymentsColumns = [
    get(featurePolicies, 'showVerifiedDocs') ? VerificationStatusColumn() : {},
    NotaryIdColumn(sortColumn, sortOrder),
    NotaryLastNameColumn(sortColumn, sortOrder),
    CityColumn(sortColumn, sortOrder),
    StateColumn(sortColumn, sortOrder),
    W9Column(),
    PaymentsCurrentYearColumn(sortColumn, sortOrder),
    PaymentsPreviousYearColumn(sortColumn, sortOrder)
  ]

  const onTableChange = (
    pagination: { current: number },
    filters: Record<keyof Notary, string[]>,
    sorter: SorterProp
  ) => {
    let params = cloneDeep(searchParams)

    params = tableChange({ pagination, filters, sorter, params })
    setQueryParamsState(params)
    setSearchParams(params)
  }

  const onRowClick = (notary: Notary) => {
    return (window.location.href = Requests.notary({ id: notary.id }).path)
  }

  const generateRowClassName = (notary: Notary) => {
    return notary.deactivatedStatus
      ? 'table-row table-row-clickable table-row-inactive'
      : 'table-row table-row-clickable'
  }

  return (
    <Table
      size="small"
      rowKey="id"
      scroll={{ x: 'max-content' }}
      dataSource={get(adminNotariesRequest, 'ids', []).map((id: number) => adminNotaries[id])}
      columns={paymentsColumns}
      onChange={onTableChange}
      loading={{
        spinning: Requests.isLoading(adminNotariesRequest) || Requests.isLoading(featurePoliciesRequest),
        tip: 'Loading'
      }}
      pagination={{
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        current: get(adminNotariesRequest, ['meta', 'currentPage']),
        pageSize: get(adminNotariesRequest, ['meta', 'perPage']),
        total: get(adminNotariesRequest, ['meta', 'totalEntries'])
      }}
      onRow={(notary: Notary) => {
        return { onClick: () => onRowClick(notary) }
      }}
      rowClassName={(notary: Notary) => generateRowClassName(notary)}
    />
  )
}
