import React, { FC, ReactElement } from 'react'
import { SettingsBlock } from './SettingsBlock'
import { Setting, SettingsT } from './types'
import { match } from './utils'

const settings: Setting[] = [
  {
    key: 'orderWarningDeadlineReached',
    title: 'It’s 3 hours before the appointment and consumers have not completed eSigning',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['signersNotEsignedDocuments']
  },
  {
    key: 'esigningCompleted',
    title: 'Consumer completes eSigning',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['receives_orders_from_closings']
  },
  {
    key: 'orderConsumerOptedOutOfEsigning',
    title: 'Consumer opts out of eSigning',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['receives_orders_from_closings'],
    notificationSettingKeys: ['consumerOptedOutEsigning']
  }
]

export const ESignSettings: FC<SettingsT> = ({
  emailSettings,
  options,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  return (
    <SettingsBlock
      id={emailSettings.id}
      companyId={emailSettings.companyId}
      title={'eSign'}
      settings={match(settings, emailSettings, options)}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  )
}
