import React, { FC, ReactElement } from 'react'
import CreateOrder from './CreateOrderButton'
import { DashboardHeaderProps } from './types'
import { searchOptions } from './OrderFilter/searchOptions'
import './styles.modules.scss'
import { DashboardFilter } from './OrderFilter/DashboardFilter'

export const DashboardHeader: FC<DashboardHeaderProps> = ({
  companyId,
  searchParams,
  changeSearchParams
}): ReactElement => {
  return (
    <div className="dashboard-header">
      <div className="order-filter" data-testid="order-filter">
        <DashboardFilter
          searchOptions={searchOptions}
          defaultFilter="lastname"
          searchParams={searchParams}
          changeSearchParams={changeSearchParams}
        />
      </div>
      <div className="create-order-button" data-testid="create-order-btn-wrapper">
        <CreateOrder companyId={companyId} />
      </div>
    </div>
  )
}
