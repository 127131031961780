export const searchOptions: Record<string, string> = {
  lastName: 'Last Name',
  firstName: 'First Name',
  city: 'City',
  state: 'State',
  email: 'Email',
  phoneNumber: 'Phone Number',
  id: 'Notary Id',
  companyName: 'Company'
}
