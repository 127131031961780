import { Form, Input, Button, Divider, Checkbox, Row, Col } from 'antd'
import React, { FC, ReactElement } from 'react'
import { formValidatorForWorkPhone } from '../../../utils/antFormValidations/validateWorkPhone'
import InputMask from 'react-input-mask'
import { ButtonLink } from '../../Shared/ButtonLink'
import './styles.modules.scss'
import { DefaultUserFormProps } from './props/defaultUserFormProps'
import { MASKS } from '../../../constants/MASKS'

export const DefaultUserForm: FC<DefaultUserFormProps> = ({ closeModal }): ReactElement => {
  return (
    <>
      <Row gutter={16} className="name-row">
        <Col span={12}>
          <Form.Item
            label="First name"
            name="firstName"
            className="form-row"
            rules={[{ required: true, message: 'First name is required.' }]}
          >
            <Input type="text" aria-label="First name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Last name"
            name="lastName"
            className="form-row"
            rules={[{ required: true, message: 'Last name is required.' }]}
          >
            <Input type="text" aria-label="Last name" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Phone"
        name="phone"
        className="form-row"
        rules={[{ required: true, message: 'Phone is required.', validator: formValidatorForWorkPhone }]}
      >
        <InputMask mask={MASKS.BUSINESS_PHONE} className="phone-input" />
      </Form.Item>
      <Form.Item label="CC emails to" className="form-row" name="ccEmailsTo" rules={[{ required: false }]}>
        <Input aria-label="CC emails to" placeholder="person1@example.com, person2@example.com" />
      </Form.Item>
      <Form.Item name="manager" valuePropName="checked">
        <Checkbox aria-label="Is a manager?">Is a manager?</Checkbox>
      </Form.Item>
      <div>
        Managers can view any order for their company. Non-managers can only view the orders that they request. Managers
        can also grant/revoke their colleagues manager status.
      </div>
      <Form.Item name="sendWelcomeMessage" valuePropName="checked">
        <Checkbox aria-label="Email login information to this user">Email login information to this user</Checkbox>
      </Form.Item>
      <Form.Item name="receiveNewOrderNotifications" valuePropName="checked">
        <Checkbox aria-label="User should receive email confirmations when they create signing requests">
          User should receive email confirmations when they create signing requests
        </Checkbox>
      </Form.Item>

      <Divider />
      <Form.Item>
        <ButtonLink callback={closeModal} className="cancel-button">
          Cancel
        </ButtonLink>
        <Button type="primary" htmlType="submit" className="ant-btn-lg">
          Create User
        </Button>
      </Form.Item>
    </>
  )
}
