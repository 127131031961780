import React, { FC, ReactElement } from 'react'

export const InputWithDescription: FC<any> = ({ InputType, children, ...restProps }): ReactElement => {
  return (
    <>
      <InputType {...restProps} />
      {children}
    </>
  )
}
