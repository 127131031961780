import React from 'react'
import { Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const ClientCompanyNameColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Client',
    dataIndex: 'clientCompanyName',
    defaultSortOrder: defaultSortOrder('clientCompanyName', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'clientCompanyName',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column client-company-name-column',
    render: (text: string, order: Order) => {
      return (
        <>
          <div>
            <Typography.Text>{order.clientName}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">{order.userName}</Typography.Text>
          </div>
        </>
      )
    }
  }
}
