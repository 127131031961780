import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Button, Space, Row, Col, Typography, notification } from 'antd'
import { FooterContentProps } from '../types'
import { USER_ACTIVITY_STATES } from '../../../constants/GLOBAL'
import moment from 'moment-timezone'
import axios from 'axios'

export const FooterContent: FC<FooterContentProps> = ({
  teamMember,
  currentUserId,
  companyTeamMembersPath,
  loading,
  setLoading,
  csrfToken,
  onCancel,
  setErrors
}): ReactElement => {
  const sendResetPasswordEmail = () => {
    const resetPasswordPath = `${companyTeamMembersPath}/${teamMember.id}/reset_password`
    setLoading(true)

    axios
      .get(resetPasswordPath, {
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken
        }
      })
      .then(() => {
        onCancel(false)
        notification.info({
          message: <Typography.Text strong>Reset password email has been sent!</Typography.Text>,
          placement: 'bottomRight',
          className: 'team-member-notification background-ant-green-2',
          icon: <Icons.InfoCircle className="color-ant-green-4" />
        })
      })
      .catch(() => {
        setLoading(false)
        setErrors(['Password reset request failed, please try again later.'])
      })
  }

  const toggleStatus = () => {
    const toggleStatusPath = `${companyTeamMembersPath}/${teamMember.id}/toggle_status`
    setLoading(true)

    axios
      .post(
        toggleStatusPath,
        {},
        {
          headers: {
            Accept: 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      )
      .then(() => {
        onCancel(true)
        notification.info({
          message: <Typography.Text strong>Team member status updated.</Typography.Text>,
          placement: 'bottomRight',
          className: 'team-member-notification background-ant-green-2',
          icon: <Icons.InfoCircle className="color-ant-green-4" />
        })
      })
      .catch(() => {
        setLoading(false)
        setErrors(['Status update failed, please try again later.'])
      })
  }

  return (
    <Space direction="vertical">
      <Row>
        <Typography.Text className="footer-label">Last Updated</Typography.Text>
        <Typography.Text>{moment(teamMember.updatedAt).format('MMMM DD, YYYY')}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text className="footer-label">Profile Created</Typography.Text>
        <Typography.Text>{moment(teamMember.createdAt).format('MMMM DD, YYYY')}</Typography.Text>
      </Row>
      {teamMember.userId !== currentUserId && (
        <>
          <Row>
            <Typography.Text className="footer-label">Password</Typography.Text>
            <Button size="small" onClick={sendResetPasswordEmail} disabled={loading}>
              Send reset password email
            </Button>
          </Row>
          <Row>
            <Typography.Text className="footer-label">Status</Typography.Text>
            <Col>
              <Row>
                <Typography.Text>
                  {teamMember.active ? USER_ACTIVITY_STATES.ACTIVE : USER_ACTIVITY_STATES.INACTIVE}
                </Typography.Text>
              </Row>
              <Row>
                <a className="color-ant-blue-4" onClick={toggleStatus}>
                  {teamMember.active ? 'Deactivate User' : 'Activate User'}
                </a>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Space>
  )
}

export default FooterContent
