import React, { FC, ReactElement } from 'react'
import { Modal } from '../../Shared/Ant'
import { Form, Button, Checkbox, Select, Row, Col, Input, Alert } from 'antd'
import axios from 'axios'
import { EditTeamMemberModalProps } from '../types'
import Header from './Header'
import FooterContent from './FooterContent'
import { ACCESS_LEVELS } from '../../../constants/GLOBAL'
import InputMask from 'react-input-mask'

export const EditTeamMemberModal: FC<EditTeamMemberModalProps> = ({
  visible,
  onCancel,
  teamMember,
  companyName,
  companyTeamMembersPath,
  csrfToken,
  currentUserId,
  cqcRestrictUsers
}): ReactElement => {
  const [form] = Form.useForm()
  const [errors, setErrors] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const onFinish = (values: {
    role: string
    first_name: string
    last_name: string
    email: string
    title: string
    qc_funding_report: boolean
  }) => {
    setLoading(true)
    const updateCtmPath = `${companyTeamMembersPath}/${teamMember.id}`

    axios
      .put(
        updateCtmPath,
        {
          company_team_member: {
            role: values.role,
            qc_funding_report: values.qc_funding_report,
            user_attributes: values
          }
        },
        {
          headers: {
            Accept: 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      )
      .then(() => {
        onCancel(true)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 400) {
          setErrors(error.response.data.errors)
        } else {
          setErrors(['Submit failed, please try again later.'])
        }
      })
  }

  const formErrors = () => (
    <div className="ctm_errors">
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  )

  const initialValues = {
    role: teamMember.role,
    first_name: teamMember.firstName,
    last_name: teamMember.lastName,
    email: teamMember.email,
    title: teamMember.title,
    phone: teamMember.phone,
    qc_funding_report: teamMember.fundingReport
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={null}
      wrapClassName="team-member-modal"
      width={645}
    >
      <Header teamMember={teamMember} companyName={companyName} />
      {errors.length > 0 && (
        <Alert
          message="Errors detected"
          description={formErrors()}
          onClose={() => setErrors([])}
          type="error"
          showIcon
          closable
        />
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="edit-ctm-form"
        requiredMark={false}
      >
        <div className="paxl">
          {currentUserId !== teamMember.userId && (
            <Form.Item
              label="Access Level"
              name="role"
              rules={[{ required: true, message: 'Please select an access level' }]}
            >
              <Select
                {...{
                  disabled: teamMember.isOwner,
                  placeholder: 'Select an access level',
                  options: ACCESS_LEVELS.map(({ value, label, description }) => ({
                    value,
                    label: (
                      <React.Fragment>
                        <strong>{label}</strong>
                        <div className="description">{description}</div>
                      </React.Fragment>
                    )
                  }))
                }}
              />
            </Form.Item>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'Please input a first name' }]}
              >
                <Input type="text" aria-label="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Please input a last name' }]}
              >
                <Input type="text" aria-label="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email' }]}
          >
            <Input type="text" aria-label="Email" />
          </Form.Item>

          <Form.Item label="Title" name="title" rules={[{ required: false }]}>
            <Input type="text" aria-label="Title" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: false,
                message: 'Does not appear to be a valid phone number',
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                validateTrigger: 'onBlur'
              }
            ]}
          >
            <InputMask mask="(999) 999-9999" className="input-mask" />
          </Form.Item>
          {cqcRestrictUsers && (
            <Form.Item name="qc_funding_report" valuePropName="checked">
              <Checkbox>QC Report Access</Checkbox>
            </Form.Item>
          )}
        </div>
        <div className="paxl" style={{ borderTop: '1px solid #DDD' }}>
          <FooterContent
            teamMember={teamMember}
            currentUserId={currentUserId}
            companyTeamMembersPath={companyTeamMembersPath}
            loading={loading}
            setLoading={setLoading}
            csrfToken={csrfToken}
            onCancel={onCancel}
            setErrors={setErrors}
          />
          <Row justify="end">
            <Button type="secondary" onClick={() => onCancel(false)} className="mrl">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Save
            </Button>
          </Row>
        </div>
      </Form>
    </Modal>
  )
}

export default EditTeamMemberModal
