import React from 'react'
import { ORDER_DOC_RETURN_TRACKING as MESSAGES } from '../../../../../../messages/ORDER_DOC_RETURN_TRACKING'
import s from './InTransitDelayCallout.module.scss'

interface Props {
  original: string
  current: string
}

export const InTransitDelayCallout: React.FC<Props> = ({ original, current }) => {
  const DELAY_MESSAGES = MESSAGES.FEDEX_DOC_RETURN.EVENT.ERROR_CALLOUT.IN_TRANSIT_DELAY
  return (
    <div>
      <div className={s.heading}>{DELAY_MESSAGES.HEADING}</div>
      <div className={s.time}>
        <div className={s.time__label}>{DELAY_MESSAGES.LABELS.ORIGINAL_TIME}</div>
        <div className={s.time__value}>{DELAY_MESSAGES.TIME(original)}</div>
      </div>
      <div className={s.time}>
        <div className={s.time__label}>{DELAY_MESSAGES.LABELS.NEW_TIME}</div>
        <div className={s.time__value}>{DELAY_MESSAGES.TIME(current)}</div>
      </div>
    </div>
  )
}
