import { AppointmentTime, Order } from '../models/order/order'
import { AppointmentConfirmationStatus } from '../models/order/appointmentConfirmationStatus'

import { CompletedStatus } from '../models/order/completedStatus'
import { DocsToNotarySent } from '../models/order/docsToNotarySent'
import { FuzzyAppointmentTimes } from '../models/order/fuzzyAppointmentTimes'
import { ShippingMethod } from '../models/order/shippingMethod'
import { UndefinedAppointmentTimes } from '../models/order/undefinedAppointmentTimes'
import moment from 'moment-timezone'
import { userTimeZone } from '../utils/userTimeZone'
import { PostClosingStatus } from '../models/order/postClosingStatus'

const TBD = 'T.B.D'

const timeRemainingToAppointmentTime = (order: Order) => {
  if (
    order.appointmentTime === UndefinedAppointmentTimes.TBD ||
    order.appointmentTime === UndefinedAppointmentTimes.ASAP ||
    order.appointmentTime === UndefinedAppointmentTimes.Blank
  ) {
    if (
      order.appointmentDate &&
      [
        moment.tz(userTimeZone()).format('YYYY-MM-DD'),
        moment.tz(userTimeZone()).add(1, 'days').format('YYYY-MM-DD')
      ].includes(order.appointmentDate)
    ) {
      return TBD
    }
  }

  return distanceOfTimeInWordsToNowFrom(order.appointmentFullTime)
}

const distanceOfTimeInWordsToNowFrom = (time: string | null) => {
  if (!time) return ''

  if (moment.utc(time).diff(moment.utc().subtract(4, 'h')) < 0) {
    return 'Passed'
  }

  return moment.utc(time).fromNow().replace('in ', '')
}

const getTime = (appointmentTime: AppointmentTime): AppointmentTime => {
  switch (appointmentTime) {
    case UndefinedAppointmentTimes.TBD:
      return TBD
    case UndefinedAppointmentTimes.ASAP:
      return 'ASAP'
    default:
      return appointmentTime
  }
}

export const appointmentTimeForShortPrint = (order: Order) => {
  const date = order.appointmentDate ? moment(order.appointmentDate).format('M/D') : ''
  const time = getTime(order.appointmentTime)

  const values = [date, time].filter((element) => element !== '')
  if (values.length === 0) return 'Not set'

  if (
    time === FuzzyAppointmentTimes.Morning ||
    time === FuzzyAppointmentTimes.Afternoon ||
    time === FuzzyAppointmentTimes.Evening
  ) {
    return values.join(' in the ')
  }

  return values.join(` ${order.appointmentTimePrefix} `)
}

const OrderHelper = {
  isOpen: (order: Order) => order.completedStatus === CompletedStatus.Open,
  isCompleted: (order: Order) => order.completedStatus === CompletedStatus.Completed,
  isCanceled: (order: Order) => order.completedStatus === CompletedStatus.Canceled,
  isCanceledOrOnHold: (order: Order) =>
    [CompletedStatus.Canceled, CompletedStatus.OnHold].includes(order.completedStatus),
  isDocsStatusDelivered: (order: Order) =>
    [
      DocsToNotarySent.NotaryPickedUpDocs,
      DocsToNotarySent.Overnighted,
      DocsToNotarySent.AtClosing,
      DocsToNotarySent.EmailedToNotary
    ].includes(order.docsToNotarySent),
  isDocsShouldBeDownloaded: (order: Order) =>
    [DocsToNotarySent.Sent, DocsToNotarySent.DirectLinks, DocsToNotarySent.SentByClient].includes(
      order.docsToNotarySent
    ),
  isAppointmentConfirmed: (order: Order) =>
    order.appointmentConfirmationStatus === AppointmentConfirmationStatus.Confirmed,
  isAppointmentChangeRequested: (order: Order) =>
    order.appointmentConfirmationStatus === AppointmentConfirmationStatus.ChangeRequested,
  timeRemainingToAppointmentTime: timeRemainingToAppointmentTime,
  appointmentTimeForShortPrint: appointmentTimeForShortPrint,
  isShippingMethodFedEx: (order: Order) => order.shippingMethod === ShippingMethod.FedEx,
  isShippingMethodUPS: (order: Order) => order.shippingMethod === ShippingMethod.UPS,
  isPostClosingStatusPending: (order: Order) => order.postClosingStatus === PostClosingStatus.Pending,
  isPostClosingStatusRejected: (order: Order) => order.postClosingStatus === PostClosingStatus.Rejected,
  isPostClosingStatusApproved: (order: Order) => order.postClosingStatus === PostClosingStatus.Approved
}

export default OrderHelper
