import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import classnames from 'classnames'
import s from './DateSelect.module.scss'

function DateSelect({ callbackFn, className, fromDate, hint, isDisabled, placeholder, selectedDate, toDate }) {
  const dFromDate = fromDate ? moment(fromDate).toDate() : null
  const dToDate = toDate ? moment(toDate).toDate() : null
  const dSelectedDate = selectedDate ? moment(selectedDate) : null

  const basicDayPickerProps = {
    localeUtils: MomentLocaleUtils,
    locale: 'en',
    numberOfMonths: 1
    // onDayClick: () => this.to.getInput().focus(),
  }

  const dayPickerProps = dFromDate
    ? {
        ...basicDayPickerProps,
        fromMonth: dFromDate,
        disabledDays: [{ before: dFromDate, after: dToDate }]
      }
    : basicDayPickerProps

  return (
    <div className={classnames(s.input, className)}>
      <DayPickerInput
        inputProps={{
          className: 'form-control',
          disabled: isDisabled
        }}
        onDayChange={callbackFn}
        placeholder={placeholder}
        dayPickerProps={dayPickerProps}
        value={dSelectedDate ? formatDate(dSelectedDate, 'LL') : ''}
        format="LL"
        formatDate={formatDate}
        parseDate={(str, format, locale) => {
          const out = parseDate(str, format, locale)
          if (out) return out
          const [month, day, year] = str.replace(/\s+/gim, ' ').split(' ')
          const newStr = [month, `${parseInt(day, 10)},`, parseInt(year, 10)].join(' ')
          return parseDate(newStr, format, locale)
        }}
      />

      {hint && <div className={s.formElement__hint}>{hint}</div>}
    </div>
  )
}

DateSelect.propTypes = {
  callbackFn: PropTypes.func,
  className: PropTypes.string,
  fromDate: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedDate: PropTypes.string,
  toDate: PropTypes.string
}

DateSelect.defaultProps = {
  callbackFn: () => {},
  className: '',
  fromDate: '',
  hint: undefined,
  isDisabled: false,
  placeholder: 'e.g. June 1, 2018',
  selectedDate: '',
  toDate: ''
}

export default DateSelect
