import React, { SVGProps } from 'react'

const CaretDown: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.35818 9.25012L11.1415 16.6134C11.6163 17.1289 12.3849 17.1289 12.8585 16.6134L19.6418 9.25012C20.4069 8.41967 19.8653 7 18.7833 7H5.21672C4.13474 7 3.59315 8.41967 4.35818 9.25012Z"
      fill="currentColor"
    />
  </svg>
)

export default CaretDown
