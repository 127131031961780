export const validateArrayofThreeEmails = (email: string) => {
  const emails = email.split(',')

  if (emails.length > 3) {
    return false
  } else {
    return emails.every(function (email) {
      return /^\w+([\.-]?\w{0,100})*@\w+([\.-]?\w{0,100})*(\.\w{2,3})+$/.test(email.trim())
    })
  }
}

export const formValidatorForArrayofThreeEmails = (_: any, value: string) => {
  if (validateArrayofThreeEmails(value)) {
    return Promise.resolve()
  }
  return Promise.reject(
    new Error(
      'Must be a valid email or list of no more than 3 valid emails separated by commas (e.g. sam@example.com, sara@example.com)'
    )
  )
}
