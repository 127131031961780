import React from 'react'
import { Typography } from 'antd'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'
import moment from 'moment-timezone'

export const PaymentsPreviousYearColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Invoice - ' + moment().subtract(1, 'years').year(),
    dataIndex: 'paymentsPreviousYear',
    defaultSortOrder: defaultSortOrder('paymentsPreviousYear', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'paymentsPreviousYear',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.NUM_ASC,
      descText: SORT_TEXTS.NUM_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    render: (paymentsPreviousYear: number) => {
      return (
        <div>
          <Typography.Text>
            ${paymentsPreviousYear && paymentsPreviousYear.toFixed(2).replace('.00', '')}
          </Typography.Text>
        </div>
      )
    }
  }
}
