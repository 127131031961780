import React from 'react'
import moment from 'moment-timezone'
import TimeAgo from 'react-timeago'
import shortTime from 'react-timeago/lib/language-strings/en-short'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

export const ORDER_DOC_RETURN_TRACKING = {
  FEDEX_DOC_RETURN: {
    NOT_AVAILABLE: 'Tracking information is not available yet. Please check back later.',
    LOADING: 'Checking FedEx for updates',
    UPDATE_ERROR: 'There was a problem getting updates from FedEx. Please check again later.',
    TRACKING_NUMBER: (num: string): string => `FedEx ${num}`,
    LAST_UPDATED: (time: string): React.ReactElement => (
      <span>
        {' '}
        - updated <TimeAgo formatter={buildFormatter(shortTime)} minPeriod={60} date={time} /> ago
      </span>
    ),
    DELIVERY_DATE: {
      HEADING: {
        DEFAULT: 'Estimated Delivery:',
        DELAYED: 'Late Delivery Expected:',
        DELIVERED: 'Delivered:'
      },
      DATE: {
        DEFAULT: (time: string): string => `${moment(time).format('dddd, M/D/YY')} by ${moment(time).format('h:mm A')}`,
        NOT_PICKED_UP: 'An estimated delivery date will be available when FedEx picks up the documents.',
        NOT_AVAILABLE: 'An estimated delivery date is not yet available from FedEx.',
        DELIVERED: (time: string): string =>
          `${moment(time).format('dddd, M/D/YY')} at ${moment(time).format('h:mm A')}`
      }
    },
    EVENT: {
      TITLE: {
        shipping_label_created: 'Shipping label created',
        documents_received_by_carrier: 'FedEx picked up docs',
        documents_in_transit: 'Docs in transit',
        documents_delivered: 'Docs delivered',
        delivery_delayed_in_transit: 'Docs delayed in transit',
        documents_not_dropped_off: 'Docs not dropped off to FedEx',
        drop_off_missed_cut_off: 'Docs missed cutoff'
      },
      TIME: (time: string): string => `${moment(time).format('MMM Do, YYYY')} at ${moment(time).format('h:mm A')}`,
      ERROR_CALLOUT: {
        IN_TRANSIT_DELAY: {
          HEADING: 'The estimated delivery time has changed:',
          LABELS: {
            ORIGINAL_TIME: 'Original:',
            NEW_TIME: 'New:'
          },
          TIME: (time: string): string => `${moment(time).format('M/D/YY')} by ${moment(time).format('h:mm A')}`
        },
        DOCS_NOT_DROPPED_OFF: 'Documents were not dropped off to FedEx on the day of the signing.',
        DROP_OFF_MISSED_CUT_OFF: 'Documents received after cutoff. Pickup scheduled for next business day.'
      }
    },
    TRACK_VIA_FEDEX: 'Track package via FedEx',
    TRACKING_URL: (trackingNumber: string): string => `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
  },
  OTHER_DOC_RETURN: {
    TITLE: {
      courier_pick_up_from_consumer: 'Courier Delivery',
      courier_pick_up_from_notary: 'Courier Delivery',
      courier_pick_up_from_lender: 'Courier Delivery',
      courier_pick_up_from_branch: 'Courier Delivery',
      drop_off_at_office: 'Notary Delivery'
    },
    DESCRIPTION: {
      courier_pick_up_from_consumer: 'A courier will pick up the signed documents from the consumer.',
      courier_pick_up_from_notary: 'A courier will pick up the signed documents from the notary.',
      courier_pick_up_from_lender: 'A courier will pick up the signed documents from the lender.',
      courier_pick_up_from_branch: 'A courier will pick up the signed documents from a nearby branch office.',
      drop_off_at_office: 'The notary will drop off the signed documents at the office.'
    }
  }
}
