import React, { SVGProps } from 'react'

const Plus: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.4126 2C14.1978 2.00006 13.9878 2.06421 13.8095 2.18426C13.6313 2.30432 13.4929 2.47481 13.412 2.67395C13.3312 2.87309 13.3115 3.09182 13.3556 3.30219C13.3997 3.51255 13.5055 3.70499 13.6595 3.8549L14.3029 4.49852L7.60675 9.87118L6.54557 8.80973C6.44491 8.70623 6.32453 8.62396 6.19155 8.56779C6.05857 8.51161 5.91567 8.48266 5.77131 8.48265C5.55643 8.4827 5.34645 8.54686 5.16821 8.66691C4.98998 8.78696 4.85158 8.95746 4.77072 9.15659C4.68987 9.35573 4.67022 9.57447 4.71429 9.78483C4.75837 9.9952 4.86416 10.1876 5.01815 10.3375L8.58354 13.9038L2.31352 20.1734C1.89549 20.5915 1.89549 21.2683 2.31352 21.6864C2.73154 22.1045 3.40815 22.1045 3.82617 21.6864L10.0941 15.4148L13.6595 18.9811C13.759 19.0848 13.8782 19.1676 14.0101 19.2246C14.1421 19.2816 14.284 19.3118 14.4277 19.3132C14.5714 19.3147 14.714 19.2874 14.8471 19.2331C14.9801 19.1788 15.101 19.0984 15.2026 18.9968C15.3042 18.8951 15.3845 18.7742 15.4389 18.6411C15.4932 18.5081 15.5204 18.3655 15.519 18.2217C15.5175 18.078 15.4874 17.936 15.4304 17.804C15.3733 17.6721 15.2906 17.5528 15.1869 17.4533L14.2185 16.4847L19.516 9.71292L20.1405 10.3375C20.24 10.4412 20.3592 10.524 20.4911 10.5811C20.6231 10.6381 20.765 10.6682 20.9087 10.6697C21.0524 10.6711 21.195 10.6439 21.328 10.5896C21.4611 10.5352 21.582 10.4549 21.6836 10.3533C21.7852 10.2516 21.8655 10.1307 21.9199 9.99761C21.9742 9.86453 22.0014 9.72194 21.9999 9.57819C21.9985 9.43445 21.9684 9.29244 21.9113 9.16049C21.8543 9.02854 21.7716 8.90929 21.6679 8.80973L15.1869 2.32709C15.0862 2.22359 14.9659 2.14132 14.8329 2.08514C14.6999 2.02896 14.557 2.00001 14.4126 2Z"
      fill="currentcolor"
    />
  </svg>
)

export default Plus
