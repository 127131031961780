import React from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { PREFERRED_NOTARIES as MESSAGES } from 'messages/PREFERRED_NOTARIES'
import { ICONS } from 'icons'
import { PREFERRED_NOTARIES as CONSTANTS } from '../../../constants/PREFERRED_NOTARIES'
import s from './styles.module.scss'

interface SearchDistanceProps {
  updateRoute: string
  preferredNotariesSearchDistance: string
}

interface SearchDistanceState {
  isSaved: boolean
  searchDistance: string
  errors?: string
}

export class SearchDistance extends React.Component<SearchDistanceProps, SearchDistanceState> {
  constructor(props: SearchDistanceProps) {
    super(props)

    this.state = {
      searchDistance: this.props.preferredNotariesSearchDistance,
      isSaved: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.resetSaved = this.resetSaved.bind(this)
  }

  updateSearchDistance(newDistance: string) {
    // Create the payload and save the previous distance in case of an error.
    const previousDistance = this.state.searchDistance
    const payload = {
      client_team_member: {
        preferred_notaries_search_distance: newDistance
      }
    }

    // Set the value of the select for immediate feedback
    this.setState({
      searchDistance: newDistance,
      isSaved: false
    })

    // Send the updateSigner action
    axios
      .post(this.props.updateRoute, payload, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then(() => {
        this.setState({
          isSaved: true
        })
      })
      .catch((error) => {
        // If there's error, reset the distance and add errors to the state.
        this.setState({
          errors: error.response.data.errors,
          searchDistance: previousDistance
        })
      })
  }

  handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.updateSearchDistance(e.target.value)
    e.preventDefault()
  }

  resetSaved() {
    this.setState({ isSaved: false })
  }

  render() {
    const classesForSelect = classnames(s.select, 'form-control')

    const optionsForSelect = CONSTANTS.SEARCH_DISTANCE_OPTIONS_FOR_SELECT.map((distance) => (
      <option key={distance} value={distance}>
        {distance} mi
      </option>
    ))

    const savedElement = this.state.isSaved ? (
      <div className={s.saved}>
        {MESSAGES.SAVED} <i className={ICONS.CHECK} />
      </div>
    ) : null

    const errorElement = this.state.errors ? <div className={s.error}>{this.state.errors}</div> : null

    return (
      <div>
        <label htmlFor="preferredNotariesDistance" className={s.label}>
          {MESSAGES.SELECT_LABEL}
        </label>
        <select
          id="preferredNotariesDistance"
          className={classesForSelect}
          value={this.state.searchDistance}
          onChange={this.handleChange}
          onClick={this.resetSaved}
        >
          {optionsForSelect}
        </select>
        {savedElement}
        {errorElement}
        <p className={s.description}>{MESSAGES.SELECT_TOOLTIP}</p>
      </div>
    )
  }
}
