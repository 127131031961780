import React from 'react'
import s from './styles.module.scss'

export const OTHER_DOCUMENT_DELIVERY_METHODS = [
  'courier_pick_up_from_consumer',
  'courier_pick_up_from_notary',
  'courier_pick_up_from_lender',
  'courier_pick_up_from_branch',
  'drop_off_at_office'
] as const

export type OtherDocumentDeliveryMethod = typeof OTHER_DOCUMENT_DELIVERY_METHODS[number]

export const OtherDocumentDeliveryTitle: { [key in OtherDocumentDeliveryMethod]: string } = {
  courier_pick_up_from_consumer: 'Courier Delivery',
  courier_pick_up_from_notary: 'Courier Delivery',
  courier_pick_up_from_lender: 'Courier Delivery',
  courier_pick_up_from_branch: 'Courier Delivery',
  drop_off_at_office: 'Notary Delivery'
}

export const OtherDocumentDeliveryDescription: { [key in OtherDocumentDeliveryMethod]: string } = {
  courier_pick_up_from_consumer: 'A courier will pick up the signed documents from the consumer.',
  courier_pick_up_from_notary: 'A courier will pick up the signed documents from the notary.',
  courier_pick_up_from_lender: 'A courier will pick up the signed documents from the lender.',
  courier_pick_up_from_branch: 'A courier will pick up the signed documents from a nearby branch office.',
  drop_off_at_office: 'The notary will drop off the signed documents at the office.'
}

interface Props {
  documentDeliveryMethod: OtherDocumentDeliveryMethod
}

export const OtherDocReturn: React.FC<Props> = ({ documentDeliveryMethod }) => {
  return (
    <div>
      <div className={s.title}>{OtherDocumentDeliveryTitle[documentDeliveryMethod]}</div>
      <div className={s.description}>{OtherDocumentDeliveryDescription[documentDeliveryMethod]}</div>
    </div>
  )
}
