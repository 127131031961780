import React, { SVGProps } from 'react'

const QuestionCircle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12.5 19H11.5C11.224 19 11 18.776 11 18.5V17.5C11 17.224 11.224 17 11.5 17H12.5C12.776 17 13 17.224 13 17.5V18.5C13 18.776 12.776 19 12.5 19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.867 9.966 14.095 9.308 13.963 8.607C13.828 7.89 13.275 7.28 12.574 7.081C11.468 6.767 10.437 7.4 10.108 8.353C9.98 8.724 9.668 9 9.275 9H8.984C8.403 9 7.997 8.436 8.159 7.878C8.588 6.406 9.84 5.285 11.384 5.047C12.908 4.812 14.353 5.596 15.257 6.846C16.437 8.478 16.089 10.231 15.07 11.25Z"
      fill="currentColor"
    />
  </svg>
)

export default QuestionCircle
