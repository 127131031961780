/* eslint react/no-string-refs: "off" */
import React, { Component } from 'react'
import classnames from 'classnames'
import { isEqual, upperFirst } from 'lodash'
import InputMask from 'react-input-mask'
import { validatePhone } from 'utils/validatePhone'
import { validateWorkPhone } from 'utils/validateWorkPhone'
import { validateZip } from 'utils/validateZip'
import { stripPhoneMaskCharacters } from 'utils/stripPhoneMaskCharacters'
import { MASKS } from 'constants/MASKS'
import s from './TextInput.module.scss'

interface TextInputProps {
  name: string
  value?: string
  callback(name: string, value: string): void
  id?: string
  type?: string
  label?: string
  placeholder?: string
  format?: 'phone' | 'business-phone' | 'zip' | 'loanGuid'
  hint?: React.ReactNode
  className: string
  disabled?: boolean
  valueOverride?: string
  errors: Array<string>
}

interface TextInputState {
  value?: string
  errors: Array<string>
}

export class TextInput extends Component<TextInputProps, TextInputState> {
  constructor(props: TextInputProps) {
    super(props)

    this.state = {
      value: this.initializeInput(this.props.value),
      errors: this.props.errors || []
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidUpdate(prevProps: TextInputProps) {
    if (this.props.valueOverride !== null) {
      this.componentReceivedOverride(this.props.valueOverride)
    }

    if (!isEqual(this.props.errors, prevProps.errors)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ errors: this.props.errors })
    }
  }

  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = this.sanitizeInput(e.target.value)
    const valueForState = this.isMasked() ? e.target.value : sanitizedValue

    // Note: Setting sanitized state for masked values would remove the masking
    // ie, '(123) 123-____' would become '(123) 123-'
    this.setState({
      value: valueForState,
      errors: []
    })

    // Send back sanitized input
    this.props.callback(this.props.name, sanitizedValue)
  }

  onBlur(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: this.sanitizeInput(e.target.value) })
  }

  initializeInput(input?: string): string {
    if (!input) {
      return ''
    }

    const isPhone = this.props.format === 'phone' || this.props.format === 'business-phone'

    // Sanitize the input on the way in, in case it's formatted differently. (ie 111-111-1111)
    // This happens sometimes when we get numbers from the email parser.
    if (isPhone) {
      return stripPhoneMaskCharacters(input)
    }

    return input
  }

  sanitizeInput(input: string): string {
    let sanitizedInput = input

    if (this.props.format === 'phone') {
      sanitizedInput = validatePhone(input)
    }

    if (this.props.format === 'business-phone') {
      sanitizedInput = validateWorkPhone(input)
    }

    if (this.props.format === 'zip') {
      sanitizedInput = validateZip(input)
    }

    return sanitizedInput
  }

  isMasked(): boolean {
    if (this.props.format === undefined) {
      return false
    }

    return ['phone', 'business-phone', 'loanGuid'].includes(this.props.format)
  }

  componentReceivedOverride(valueOverride?: string) {
    if (valueOverride !== this.state.value) {
      this.setState({ value: valueOverride })
    }
  }

  render() {
    const formatToMasks = {
      phone: MASKS.PHONE,
      'business-phone': MASKS.BUSINESS_PHONE,
      loanGuid: MASKS.LOAN_GUID,
      zip: ''
    }

    const inputAttrs = {
      type: this.props.type,
      className: 'form-control',
      id: this.props.id,
      name: this.props.name,
      value: this.state.value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled
    }

    const inputElement =
      this.props.format !== undefined && this.isMasked() ? (
        <InputMask mask={formatToMasks[this.props.format]} {...inputAttrs} />
      ) : (
        <input {...inputAttrs} ref="input" />
      )

    const wrapperClasses = classnames({
      field_with_errors: this.state.errors.length
    })

    const labelElement = this.props.label ? (
      <label className={s.formElement__label} htmlFor={this.props.id}>
        {this.props.label}
      </label>
    ) : null

    const hintElement = this.props.hint ? <div className={s.formElement__hint}>{this.props.hint}</div> : null

    const errorElements = (this.state.errors || []).map((error) => (
      <div className={s.formElement__error} key={error} data-test="inputErrors">
        {upperFirst(error)}
      </div>
    ))

    return (
      <div className={`${this.props.className} ${s.formElement}`}>
        {labelElement}
        <div className={wrapperClasses}>
          {inputElement}
          {errorElements}
          {hintElement}
        </div>
      </div>
    )
  }
}

export default TextInput
