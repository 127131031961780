import React, { SVGProps } from 'react'

const Download: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0014 2C10.4502 2 10.0028 2.448 10.0028 3V11H6.50782C6.3754 11 6.24841 11.0527 6.15477 11.1464C6.06114 11.2402 6.00853 11.3674 6.00853 11.5C6.00855 11.6326 6.06117 11.7598 6.15481 11.8535C6.16479 11.8637 6.1752 11.8735 6.18602 11.8828L11.2842 16.6973L11.3174 16.7285C11.5022 16.9026 11.7463 16.9996 12 17C12.2537 16.9996 12.4978 16.9026 12.6826 16.7285L12.6963 16.7168C12.6996 16.7129 12.7028 16.709 12.706 16.7051L17.8023 11.8926C17.8124 11.8839 17.8222 11.8747 17.8315 11.8652L17.8393 11.8594C17.8413 11.8574 17.8433 11.8555 17.8452 11.8535C17.9388 11.7598 17.9914 11.6326 17.9915 11.5C17.9915 11.3674 17.9389 11.2402 17.8452 11.1464C17.7516 11.0527 17.6246 11 17.4922 11H13.9972V3C13.9972 2.448 13.5498 2 12.9986 2H12H11.0014ZM3.0128 20C2.88048 19.9981 2.7491 20.0226 2.62631 20.072C2.50351 20.1214 2.39175 20.1948 2.29752 20.2878C2.20328 20.3809 2.12845 20.4918 2.07738 20.614C2.0263 20.7363 2 20.8675 2 21C2 21.1325 2.0263 21.2637 2.07738 21.386C2.12845 21.5082 2.20328 21.6191 2.29752 21.7122C2.39175 21.8052 2.50351 21.8786 2.62631 21.928C2.7491 21.9774 2.88048 22.0019 3.0128 22H20.9872C21.1195 22.0019 21.2509 21.9774 21.3737 21.928C21.4965 21.8786 21.6082 21.8052 21.7025 21.7122C21.7967 21.6191 21.8715 21.5082 21.9226 21.386C21.9737 21.2637 22 21.1325 22 21C22 20.8675 21.9737 20.7363 21.9226 20.614C21.8715 20.4918 21.7967 20.3809 21.7025 20.2878C21.6082 20.1948 21.4965 20.1214 21.3737 20.072C21.2509 20.0226 21.1195 19.9981 20.9872 20H3.0128Z"
      fill="currentcolor"
    />
  </svg>
)

export default Download
