import React, { FC, ReactElement, useState } from 'react'
import { Modal, Icons } from '../../../Shared/Ant'
import { Button, notification, Space } from 'antd'
import { MarkAsReadBulkProps } from '../types'
import './MarkAsReadBulk.modules.scss'
import { MarkAsReadBulkContent } from './MarkAsReadBulkContent'
import { apiCall } from '../../../../api/apiCall'
import { getQueryParamsState } from '../../../../utils/queryParams'
import { Snapdocs } from '../../../../hooks/api/snapdocs'
import { Requests } from '../../../../api/requests'
import MESSAGES from './MarkAsReadBulk.messages'

export const MarkAsReadBulk: FC<MarkAsReadBulkProps> = ({ unreadCount }): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const params = getQueryParamsState()
  const owner = params.owner || 'all'
  const updateBulkRequest = Requests.notificationUpdateBulk()

  const handleSuccess = () => {
    const key = `open-${Date.now()}`

    notification.open({
      key: key,
      className: 'mark-all-read-notification',
      message: (
        <Space>
          <span>{MESSAGES.CONFIRM_PROMPT.SUCCESS}</span>
          <Button type="link" onClick={() => handleUndo(key)}>
            {MESSAGES.CONFIRM_PROMPT.UNDO}
          </Button>
        </Space>
      ),
      placement: 'bottomLeft',
      icon: <Icons.CheckCircle className="icon" />,
      closeIcon: (
        <span className="close-icon-wrapper">
          <Icons.Close className="close-icon" />
        </span>
      )
    })
  }

  const handleOk = () => {
    setIsModalVisible(false)

    apiCall
      .request({
        method: 'patch',
        url: updateBulkRequest.path,
        params: { read: true, owner: owner }
      })
      .then(() => {
        handleSuccess()
        Snapdocs.dispatchReset('unreadNotificationsCount')
        Snapdocs.dispatchReset('notifications')
      })
  }

  const handleUndo = (key: string) => {
    notification.close(key)

    apiCall
      .request({
        method: 'patch',
        url: updateBulkRequest.path,
        params: { read: false, owner: owner }
      })
      .then(() => {
        Snapdocs.dispatchReset('unreadNotificationsCount')
        Snapdocs.dispatchReset('notifications')
      })
  }

  return (
    <>
      <Button
        type="link"
        disabled={unreadCount === 0}
        className="mark-all-read-button"
        icon={<Icons.Check className="icon" />}
        onClick={() => setIsModalVisible(true)}
      >
        {MESSAGES.ACTION} ({unreadCount})
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        okText={MESSAGES.CONFIRM_PROMPT.OK}
        cancelButtonProps={{ type: 'link' }}
        width={800}
      >
        <MarkAsReadBulkContent unreadCount={unreadCount} owner={owner} />
      </Modal>
    </>
  )
}
