import React, { FC, ReactElement } from 'react'
import { SettingsBlock } from './SettingsBlock'
import { Setting, SettingsT } from './types'
import { match } from './utils'

const settings: Setting[] = [
  {
    key: '',
    title: 'Order is created with pre-assigned notary',
    description: '',
    type: '',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['newOrderSubmittedWithNotary']
  },
  {
    key: '',
    title: 'Order is created with special instructions',
    description: '',
    type: '',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['orderCreatedWithSpecialInstructions']
  },
  {
    key: 'orderCreatedByClient',
    title: 'Order is created by a client',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'order_created.png',
    dependencies: [],
    notificationSettingKeys: ['clientCreatedOrder']
  },
  {
    key: 'orderCreatedByCompany',
    title: 'Order is created by someone in your company',
    description: 'Individual client team members can still opt out of these emails',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'order_created.png',
    dependencies: []
  }
]

export const NewOrdersSettings: FC<SettingsT> = ({
  emailSettings,
  options,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  return (
    <SettingsBlock
      id={emailSettings.id}
      companyId={emailSettings.companyId}
      title={'New orders'}
      settings={match(settings, emailSettings, options)}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  )
}
