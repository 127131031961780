import Pusher from 'pusher-js'
import { remove } from 'lodash'

export class PusherWrapper {
  appKey: string
  cluster: string
  environment: string | undefined = process.env.RAILS_ENV
  channelEndpoint: string = '/pusher'
  pendingChannels: string[] = []
  channels: string[] = []
  pusher: Pusher

  constructor(appKey: string, cluster: string) {
    this.appKey = appKey
    this.cluster = cluster
    this.init()
  }

  subscribeForEvent(channelName: string, event: string, callback: Function) {
    if (this.channels.includes(channelName)) {
      const channel = this.pusher.channel(channelName)
      channel.bind(event, callback)
    } else if (this.pendingChannels.includes(channelName)) {
      setTimeout(() => {
        this.subscribeForEvent(channelName, event, callback)
      }, 1000)
    } else {
      this.pendingChannels.push(channelName)
      const channel = this.pusher.subscribe(channelName)

      channel.bind('pusher:subscription_succeeded', () => {
        this.channels.push(channelName)
        remove(this.pendingChannels, (pendingChannel) => pendingChannel === channelName)
        channel.bind(event, callback)
      })
    }
  }

  unsubscribeFromTheChannel(channelName: string) {
    this.pusher.unsubscribe(channelName)
    remove(this.channels, (channel) => channel === channelName)
  }

  private init() {
    this.maybeEnableLogging()

    this.pusher = new Pusher(this.appKey, {
      cluster: this.cluster,
      channelAuthorization: {
        endpoint: this.channelEndpoint,
        transport: 'ajax'
      }
    })
  }

  private maybeEnableLogging() {
    if (this.environment === 'development' || this.environment === 'staging') {
      Pusher.log = (message) => {
        if (window.console && window.console.log) {
          window.console.log(message)
        }
      }
    }
  }
}

export const pusherProvider = (appKey: string, cluster: string) =>
  new Pusher(appKey, {
    cluster,
    authEndpoint: '/pusher'
  })
