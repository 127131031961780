import _ from 'lodash'
import { stripPhoneMaskCharacters } from './stripPhoneMaskCharacters'
import { MASKS } from '../constants/MASKS'

export const validateWorkPhone = (input: string): string => {
  const strippedInput = stripPhoneMaskCharacters(input)
  const strippedFormatWithoutExt = stripPhoneMaskCharacters(MASKS.PHONE)

  const isValidPhoneWithExt = strippedInput.length > strippedFormatWithoutExt.length
  const isValidPhoneWithoutExt = strippedInput.length === strippedFormatWithoutExt.length

  let validatedInput = ''

  if (isValidPhoneWithExt) {
    // Trim the extra extension characters, if necessary (' x32__' => ' x32')
    validatedInput = input.replace(/[_]/g, '')
  } else if (isValidPhoneWithoutExt) {
    // Trim all of the extension characters (' x___' => '')
    validatedInput = _.trimEnd(input, ' x____')
  }

  // Otherwise, return nothing.
  return validatedInput
}
