import { get, set, snakeCase } from 'lodash'
import { SORT_DIRECTIONS } from '../../constants/COLUMN_SORTER'

type SorterProp = {
  order?: string
  field?: string
}

type TableChangeProps = {
  pagination: { current: number }
  filters: Record<keyof object, string[]>
  sorter: SorterProp
  params: any
}

const mapSorterOrderToDirection = {
  ascend: SORT_DIRECTIONS.ASC,
  descend: SORT_DIRECTIONS.DESC
}

export const tableChange = (tableChangeProps: TableChangeProps) => {
  if (tableChangeProps.pagination) {
    tableChangeProps.params.page = tableChangeProps.pagination.current
  }

  if (tableChangeProps.sorter) {
    if (tableChangeProps.sorter.order) {
      const defaultValue = ''
      const newSort = `${snakeCase(tableChangeProps.sorter.field)} ${get(
        mapSorterOrderToDirection,
        tableChangeProps.sorter.order,
        defaultValue
      )}`

      set(tableChangeProps.params, ['q', 's'], newSort)
    } else {
      set(tableChangeProps.params, ['q', 's'], undefined)
    }
  }

  return tableChangeProps.params
}
