import { configureStore } from '@reduxjs/toolkit'
import { QueryClient } from '@tanstack/react-query'
import rootReducer from './reducers/rootReducer'

export const store = configureStore({
  reducer: rootReducer
})

export const queryClient = new QueryClient()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
