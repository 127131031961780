import React, { FC, ReactElement } from 'react'
import { Form, Button, Col, DatePicker, Input, Row, Select } from 'antd'
import { values } from 'lodash'
import { Snapdocs } from '../../../../../../hooks/api'
import { Requests } from '../../../../../../api'
import { FileNumberStepProps } from '../../types'
import { timeOptions } from '../../../../../../utils/appointmentTimeOptions'

const prefixOptions = ['at', 'before', 'after']

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

const gridLayout = {
  xs: 24,
  md: 8
}

export const FileNumberStep: FC<FileNumberStepProps> = ({ clientId, onFinish, onValuesChange }): ReactElement => {
  const [form] = Form.useForm()
  const { response: products } = Snapdocs.useResources(Requests.clientProducts({ clientId }))

  return (
    <>
      <Form {...formLayout} form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Signing Type"
              name="productId"
              rules={[{ required: true, message: 'Signing Type is required' }]}
            >
              <Select showSearch placeholder="Select type of signing" optionFilterProp="children">
                {values(products).map((product) => (
                  <Select.Option value={product.id} key={product.id}>
                    {product.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="bottom">
          <Col {...gridLayout}>
            <Form.Item label="Appointment" name="appointmentDate">
              <DatePicker format="MMM DD, YYYY" placeholder="Date" />
            </Form.Item>
          </Col>
          <Col {...gridLayout}>
            <Form.Item name="appointmentTimePrefix" initialValue="at">
              <Select>
                {prefixOptions.map((option) => (
                  <Select.Option value={option} key={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...gridLayout}>
            <Form.Item name="appointmentTime">
              <Select showSearch placeholder="Time" optionFilterProp="children">
                {timeOptions().map((option) => (
                  <Select.Option value={option[1]} key={option[1]}>
                    {option[0]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="File Number"
              name="fileNumber"
              rules={[{ required: true, message: 'File Number is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}
