import React from 'react'
import { FedexDocReturn, ShippingInfo } from './FedexDocReturn/FedexDocReturn'
import {
  OtherDocReturn,
  OtherDocumentDeliveryMethod,
  OTHER_DOCUMENT_DELIVERY_METHODS
} from './OtherDocReturn/OtherDocReturn'

const FEDEX_DELIVERY_METHODS = [
  'fedex_2_day',
  'fedex_standard_overnight',
  'fedex_priority_overnight',
  'fedex_first_overnight',
  'fedex_priority_overnight_with_saturday_delivery',
  'fedex_first_overnight_with_saturday_delivery'
] as const

type FedexDeliveryMethod = typeof FEDEX_DELIVERY_METHODS[number]

interface OrderDocReturnTrackingProps {
  documentDeliveryMethod: FedexDeliveryMethod | OtherDocumentDeliveryMethod
  shippingInfo: ShippingInfo
  updateShippingInfoPath: string
}

export const OrderDocReturnTracking: React.FC<OrderDocReturnTrackingProps> = ({
  documentDeliveryMethod,
  shippingInfo,
  updateShippingInfoPath
}) => {
  // If a doc delivery method isn't provided, don't render the component. (very edge-casey)
  if (!documentDeliveryMethod) {
    return null
  }

  // Doc delivery method is a fedex method.
  const isFedexMethod = FEDEX_DELIVERY_METHODS.find((validType) => validType === documentDeliveryMethod)
  if (isFedexMethod) {
    return <FedexDocReturn shippingInfo={shippingInfo} updateShippingInfoPath={updateShippingInfoPath} />
  }

  // Doc delivery method is not fedex.
  const isOtherMethod = OTHER_DOCUMENT_DELIVERY_METHODS.find((validType) => validType === documentDeliveryMethod)
  if (isOtherMethod) {
    return <OtherDocReturn documentDeliveryMethod={documentDeliveryMethod as OtherDocumentDeliveryMethod} />
  }

  return null
}
