export const ADDRESS_VALIDATION_MSG = {
  STREET_ADDRESS: 'Street address is required',
  CITY: 'City is required',
  STATE: 'State is required',
  ZIP: 'A valid zip is required'
}

export const APPT_TIME_VALIDATION_MSG = {
  APPOINTMENT_DATE: 'Appointment date is required',
  APPOINTMENT_TIME_PREFIX: 'Appointment time prefix is required',
  APPOINTMENT_TIME: 'Appointment time is required'
}

export const NAME_VALIDATION_MSG = {
  FIRST_NAME: 'First name is required',
  LAST_NAME: 'Last name is required'
}

export const EMAIL_VALIDATION_MSG = 'This field should be a valid email'
