import React, { FC, ReactElement } from 'react'
import { OwnerAvatarProps } from '../../types'
import { Avatar, Tooltip } from 'antd'

export const OwnerAvatar: FC<OwnerAvatarProps> = ({ order }): ReactElement => {
  return (
    <>
      {order.ownerFullName && (
        <Tooltip title={`Owned by ${order.ownerFullName}`}>
          <Avatar
            className="owner-avatar"
            size="small"
            shape="square"
            src={order.ownerPhotoUrl ? order.ownerPhotoUrl : ''}
          >
            {!order.ownerPhotoUrl && order.ownerInitials}
          </Avatar>
        </Tooltip>
      )}
    </>
  )
}
