import React, { SVGProps } from 'react'

const Upload: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C11.8687 2.00028 11.7388 2.02647 11.6177 2.07707C11.4965 2.12768 11.3865 2.20171 11.294 2.29492L6.16846 7.13477C6.16451 7.13796 6.16061 7.14122 6.15676 7.14453L6.15481 7.14648C6.06117 7.24024 6.00855 7.3674 6.00853 7.5C6.00853 7.63261 6.06114 7.75979 6.15477 7.85355C6.24841 7.94732 6.3754 8 6.50782 8H10.0028V16C10.0028 16.552 10.4502 17 11.0014 17H12H12.9986C13.5498 17 13.9972 16.552 13.9972 16V8H17.4922C17.6246 8 17.7516 7.94732 17.8452 7.85355C17.9389 7.75979 17.9915 7.63261 17.9915 7.5C17.9914 7.3674 17.9388 7.24024 17.8452 7.14648L17.814 7.11719L12.7158 2.30273C12.705 2.29207 12.6939 2.28165 12.6826 2.27148C12.4978 2.09743 12.2537 2.00036 12 2ZM3.0128 20C2.88048 19.9981 2.7491 20.0226 2.62631 20.072C2.50351 20.1214 2.39175 20.1948 2.29752 20.2878C2.20328 20.3809 2.12845 20.4918 2.07738 20.614C2.0263 20.7363 2 20.8675 2 21C2 21.1325 2.0263 21.2637 2.07738 21.386C2.12845 21.5082 2.20328 21.6191 2.29752 21.7122C2.39175 21.8052 2.50351 21.8786 2.62631 21.928C2.7491 21.9774 2.88048 22.0019 3.0128 22H20.9872C21.1195 22.0019 21.2509 21.9774 21.3737 21.928C21.4965 21.8786 21.6082 21.8052 21.7025 21.7122C21.7967 21.6191 21.8715 21.5082 21.9226 21.386C21.9737 21.2637 22 21.1325 22 21C22 20.8675 21.9737 20.7363 21.9226 20.614C21.8715 20.4918 21.7967 20.3809 21.7025 20.2878C21.6082 20.1948 21.4965 20.1214 21.3737 20.072C21.2509 20.0226 21.1195 19.9981 20.9872 20H3.0128Z"
      fill="currentcolor"
    />
  </svg>
)

export default Upload
