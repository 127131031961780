import React, { SVGProps } from 'react'

const CloseCircle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.707 15.293C17.098 15.684 17.098 16.316 16.707 16.707C16.512 16.902 16.256 17 16 17C15.744 17 15.488 16.902 15.293 16.707L12 13.414L8.707 16.707C8.512 16.902 8.256 17 8 17C7.744 17 7.488 16.902 7.293 16.707C6.902 16.316 6.902 15.684 7.293 15.293L10.586 12L7.293 8.707C6.902 8.316 6.902 7.684 7.293 7.293C7.684 6.902 8.316 6.902 8.707 7.293L12 10.586L15.293 7.293C15.684 6.902 16.316 6.902 16.707 7.293C17.098 7.684 17.098 8.316 16.707 8.707L13.414 12L16.707 15.293Z"
      fill="currentcolor"
    />
  </svg>
)

export default CloseCircle
