import { UserPresence } from './types'
import { find, get, set, remove, cloneDeep } from 'lodash'
import { Events } from '../../../models/event/events'

export const OrderPresenceChangeCallback = <T extends { user: UserPresence; event: string; order_id: string }>(
  data: T,
  userPresences: object,
  setUserPresences: Function
) => {
  const existingUser = find(get(userPresences, data.order_id), (user: UserPresence) => {
    return user.id === data.user.id
  })

  switch (data.event) {
    case Events.MemberAdded:
      if (!existingUser) {
        if (get(userPresences, data.order_id)) {
          get(userPresences, data.order_id).push(data.user)
        } else {
          set(userPresences, data.order_id, [data.user])
        }
      }
      break
    case Events.MemberRemoved:
      remove(get(userPresences, data.order_id), existingUser)
      break
  }
  setUserPresences(cloneDeep(userPresences))
}
