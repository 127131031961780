import React, { FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { NotariesTable } from './NotariesTable'
import '../../Shared/Ant/App.less'

export const BoardContent: FC<{ companyId: number }> = ({ companyId }): ReactElement => {
  return (
    <Provider store={store}>
      <div className="notaries-table">
        <NotariesTable companyId={companyId} />
      </div>
    </Provider>
  )
}
