import React from 'react'
import { Icon } from '../../Shared/Ant'
import { Checkbox, Form, Typography } from 'antd'
import '../styles.modules.scss'
import { defaultSortOrder } from '../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../constants/COLUMN_SORTER'
import { CompanyTeamMember } from '../types'
import { ResetButton } from '../ColumnFilterButtons/ResetButton'
import { ApplyButton } from '../ColumnFilterButtons/ApplyButton'
import Filter from '../../../components/Shared/Icons/Filter/Filter'

export const QCAccessColumn = (
  sortColumn: string,
  sortOrder: string,
  setFilter: (filter: Record<keyof object, string[]>) => void
) => {
  const [form] = Form.useForm()
  const [isColumnFiltered, setIsColumnFiltered] = React.useState(false)

  const handleReset = async (confirm: () => void) => {
    setFilter({ qc_access: null })
    setIsColumnFiltered(false)
    form.resetFields()
    confirm()
  }

  const handleSubmit = async (confirm: () => void) => {
    const { qcAccess } = await form.validateFields()
    if (qcAccess?.length > 0) {
      setFilter({ qc_access: qcAccess })
      setIsColumnFiltered(true)
    } else {
      setFilter({ qc_access: null })
      setIsColumnFiltered(false)
    }
    confirm()
  }

  return {
    title: 'QC Report Access',
    dataIndex: 'qcAccess',
    defaultSortOrder: defaultSortOrder('qcAccess', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'qcAccess',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: 'Sort most access to least',
      descText: 'Sort least access to most',
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column',
    filterDropdown: ({ confirm }: any) => (
      <Form form={form} onFinish={() => handleSubmit(confirm)}>
        <div className="team-table-filter">
          <strong>Filter QC report access</strong>
          <Form.Item name="qcAccess" className="mtxl mbn filter-role-form" valuePropName="checked">
            <Checkbox.Group>
              <Checkbox value="enabled">
                <div className="role-description-container">
                  <strong>Enabled</strong>
                </div>
              </Checkbox>
              <Checkbox value="disabled">
                <div className="role-description-container">
                  <strong>Disabled</strong>
                </div>
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <div className="display_flex justify_content_space_between">
            <ResetButton onClick={() => handleReset(confirm)} />
            <ApplyButton />
          </div>
        </div>
      </Form>
    ),
    filterIcon: <Icon component={Filter} className={isColumnFiltered ? 'filtered-column-icon' : ''} />,
    render: (text: string, companyTeamMember: CompanyTeamMember) => (
      <Typography.Text>{companyTeamMember.fundingReport && 'Enabled'}</Typography.Text>
    )
  }
}
