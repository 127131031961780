export default {
  SEND_EMAIL_NOTIFICATION: {
    HELP: 'Because the signing is today, the notary will also receive a notification via SMS',
    CHECKBOX: 'Send comment notification via email'
  },
  SUBMIT: {
    CREATE_COMMENT: 'Create Comment',
    CREATE_NOTE: 'Add internal note'
  }
}
