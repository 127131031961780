import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'

const wrappedComponents = new Map()

export function withInnerDataTest(WrappedComponent, dataTest) {
  if (!wrappedComponents.has(WrappedComponent)) wrappedComponents.set(WrappedComponent, new Map())
  const dataTests = wrappedComponents.get(WrappedComponent)
  if (!dataTests.has(dataTest)) {
    dataTests.set(dataTest, (componentProps) => {
      const dataTestValue = typeof dataTest === 'function' ? dataTest(componentProps) : dataTest
      return dataTestValue ? (
        <WrappedComponent
          {...{
            ...componentProps,
            innerProps: {
              ...componentProps.innerProps,
              'data-test': dataTestValue
            }
          }}
        />
      ) : (
        <WrappedComponent {...componentProps} />
      )
    })
  }
  return dataTests.get(dataTest)
}

function ReactSelect(props) {
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #66afe9' : '1px solid #ccc',
      'box-shadow': state.isFocused
        ? 'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'
        : 'inset 0 1px 1px rgba(0,0,0,0.075)'
    }),
    menuList: (provided) => ({
      ...provided,
      theme: {
        spacing: {
          baseUnit: 1,
          menuGutter: 0
        }
      }
    })
  }

  const dataTests = {
    SelectContainer: props['data-test'],
    Option: ({ value }) => `${props['data-test']}-${value}`
  }

  return (
    <Select
      clearable={false}
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        border: '1px solid #ccc',
        spacing: {
          ...theme.spacing,
          menuGutter: 2
        }
      })}
      {...props}
      components={{
        ...props.components,
        ...Object.entries(dataTests).reduce(
          (memo, [key, dataTest]) => ({
            ...memo,
            [key]: withInnerDataTest(props.components[key] || components[key], dataTest)
          }),
          {}
        )
      }}
    />
  )
}

ReactSelect.propTypes = {
  components: PropTypes.object,
  'data-test': PropTypes.string
}

ReactSelect.defaultProps = {
  components: {},
  'data-test': ''
}

export default ReactSelect
