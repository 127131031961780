import React, { FC, ReactElement } from 'react'
import { Popover, Button } from 'antd'
import { Icons } from '../../../Shared/Ant'
import './styles.modules.scss'

export const ParticipantPopover: FC<{}> = (): ReactElement => {
  const participantPopoverContent = (
    <div className="participant-popover-content">
      <div className="content-block">
        <p className="content-description">
          Add the loan processor, escrow officer, realtor, or anyone else so they can:
        </p>
        <ul>
          <li>Receive email updates</li>
          <li>View the order</li>
          <li>Send messages</li>
        </ul>
        <p className="content-description">
          Participants <strong>cannot</strong> see fees, docs, or the order invoice.
        </p>
      </div>
    </div>
  )

  return (
    <Popover placement="bottomRight" content={participantPopoverContent} className="participant-popover">
      <Button className="popover-button" icon={<Icons.InfoCircle className="icon" />} type="link"></Button>
    </Popover>
  )
}
