import React, { FC, ReactElement } from 'react'
import { SettingsBlock } from './SettingsBlock'
import { Setting, SettingsT } from './types'
import { match } from './utils'

const settings: Setting[] = [
  {
    key: 'orderUpdatedByClient',
    title: 'Client updates an order',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['clientUpdatedOrder']
  },
  {
    key: 'orderNonAdminAddsComment',
    title: 'Client, participant, consumer, or notary adds a comment to an order',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'adds_comment.png',
    dependencies: [],
    notificationSettingKeys: [
      'clientAddedComment',
      'notaryAddedComment',
      'consumerAddedComment',
      'participantAddedComment',
      'lenderOrTitleAddedComment'
    ]
  },
  {
    key: 'orderUpdatedReswareActionRequired',
    title: 'Appointment details updated via Resware. Action may be required',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['apptUpdatedReswareActionRequired']
  },
  {
    key: 'notaryRespondsToRescheduledAppointment',
    title: 'Notary responds to a rescheduled appointment',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['order_appointment_confirmation'],
    notificationSettingKeys: ['notaryRespondedToRescheduledAppt']
  },
  {
    key: 'orderAppointmentStatusChangeRequested',
    title: 'Consumer or notary requests an appointment change',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_scheduled_time_with_consumer.png',
    dependencies: ['order_appointment_confirmation'],
    notificationSettingKeys: ['notaryRequestedApptChange', 'consumerRequestedApptChange']
  },
  {
    key: 'orderSigningMethodChanged',
    title: 'Lender or Title has changed the closing method to [hybrid/wet]',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['lenderOrTitleChangedClosingMethod']
  },
  {
    key: 'orderNotaryRespondsAsAvailable',
    title: 'Notary responds as available',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_available.png',
    dependencies: []
  },
  {
    key: 'orderNotaryAssigned',
    title: 'Notary is assigned to an order',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_assigned.png',
    dependencies: []
  },
  {
    key: 'notaryRemovedThemselvesFromOrder',
    title: 'A notary removes themselves from an order',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['notaryRemovedThemselvesFromOrder']
  },
  {
    key: 'orderAppointmentStatusConfirmed',
    title: 'Appointment is confirmed',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'appointment_confirmed.png',
    dependencies: ['order_appointment_confirmation']
  },
  {
    key: 'notaryMarksTheConsumerAsUnreachable',
    title: 'Notary is unable to reach the signer and confirm the appointment',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'consumer_unreachable.png',
    dependencies: ['order_appointment_confirmation']
  },
  {
    key: 'notaryScheduledTimeWithConsumer',
    title: 'Notary has scheduled time with the signer',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_scheduled_time_with_consumer.png',
    dependencies: ['order_appointment_confirmation']
  },
  {
    key: '',
    title: 'Automator is unable to auto-start',
    description: '',
    type: '',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['orderAutomatorAutostartFailed']
  },
  {
    key: 'orderAutomatorAutoStopped',
    title: 'The automator is paused during a search',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: [],
    notificationSettingKeys: ['orderAutomatorAutoStopped']
  },
  {
    key: 'notaryRegisteredOnOwnSite',
    title: 'A notary registers on your site',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: []
  }
]

export const GeneralOrderUpdatesSettings: FC<SettingsT> = ({
  emailSettings,
  options,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  return (
    <SettingsBlock
      id={emailSettings.id}
      companyId={emailSettings.companyId}
      title={'General order updates'}
      settings={match(settings, emailSettings, options)}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  )
}
