import _ from 'lodash'
import { TimelineEventType } from './TimelineEvent/TimelineEvent'

const timelineOrder = [
  <TimelineEventType>'shipping_label_created',
  <TimelineEventType>'documents_not_dropped_off',
  <TimelineEventType>'drop_off_missed_cut_off',
  <TimelineEventType>'documents_received_by_carrier',
  <TimelineEventType>'documents_in_transit',
  <TimelineEventType>'delivery_delayed_in_transit',
  <TimelineEventType>'documents_delivered'
]

const happyPath = [
  'shipping_label_created',
  'documents_received_by_carrier',
  'documents_in_transit',
  'documents_delivered'
]

export interface Event {
  type: TimelineEventType
  isError?: boolean
  time?: string
  isObserved?: boolean
}

function eventObserved(events: Event[], type: TimelineEventType): boolean {
  // Return 'isObserved' for this event type
  return _.get(_.find(events, ['type', type]), 'isObserved', false)
}

function handleDocsNotDroppedOff(events: Event[]): Event[] {
  // If the docs have been received, show all historical events.
  // Otherwise, remove the "docs received" event.
  return eventObserved(events, 'documents_received_by_carrier')
    ? events
    : _.compact(_.without(events, _.find(events, ['type', 'documents_received_by_carrier'])))
}

function handleDropOffMissedCutOff(events: Event[]): Event[] {
  // First, remove 'docs_not_dropped_off' if it's present. "Drop off missed cut off" is more up-to-date.
  const eventsWithoutLastDelay = _.compact(_.without(events, _.find(events, ['type', 'documents_not_dropped_off'])))
  // If the docs are in transit, show all historical events.
  // Otherwise, remove the "docs received" event.
  return eventObserved(eventsWithoutLastDelay, 'documents_in_transit')
    ? eventsWithoutLastDelay
    : _.compact(
        _.without(eventsWithoutLastDelay, _.find(eventsWithoutLastDelay, ['type', 'documents_received_by_carrier']))
      )
}

function handleInTransitDelay(events: Event[]): Event[] {
  // If docs have been delivered, show all historical events.
  // But if they're still in transit, remove the "in transit" event.
  return eventObserved(events, 'documents_delivered')
    ? events
    : _.compact(_.without(events, _.find(events, ['type', 'documents_in_transit'])))
}

export const organizeTimelineEvents = (events: Event[]): Event[] => {
  let sortedEvents: Event[] = _.chain(timelineOrder)
    // Sort events by timelineOrder and add the keys our component needs.
    .map((timelineOrderEvent: TimelineEventType) => {
      const thisEvent = _.find(events, ['type', timelineOrderEvent])
      return {
        type: timelineOrderEvent,
        isError: thisEvent ? thisEvent.isError : undefined,
        time: thisEvent ? thisEvent.time : undefined,
        isObserved: !!thisEvent
      }
    })
    // Only keep events that are part of the happy path or have been observed (like delays).
    .filter((event) => event.isObserved || _.includes(happyPath, event.type))
    .value()

  // If the docs were not dropped off by the cut-off time...
  if (_.find(sortedEvents, ['type', 'documents_not_dropped_off'])) {
    sortedEvents = handleDocsNotDroppedOff(sortedEvents)
  }

  // If the docs were received too late...
  if (_.find(sortedEvents, ['type', 'drop_off_missed_cut_off'])) {
    sortedEvents = handleDropOffMissedCutOff(sortedEvents)
  }

  // If there was a delay in-transit...
  if (_.find(sortedEvents, ['type', 'delivery_delayed_in_transit'])) {
    sortedEvents = handleInTransitDelay(sortedEvents)
  }

  return sortedEvents
}
