import {
  ADDRESS_VALIDATION_MSG,
  NAME_VALIDATION_MSG,
  EMAIL_VALIDATION_MSG
} from '../constants/messages/VALIDATION_MESSAGES'
import { validateEmails } from './antFormValidations/validateEmails'

export const FORM_RULES = {
  standard5DigitUSZipCode: [
    {
      required: true,
      pattern: /^\d{5}?$/, // Matches standard 5 digit US Zip Codes
      message: ADDRESS_VALIDATION_MSG.ZIP
    }
  ],
  firstNameRequired: [{ required: true, message: NAME_VALIDATION_MSG.FIRST_NAME }],
  lastNameRequired: [{ required: true, message: NAME_VALIDATION_MSG.LAST_NAME }],
  emailNotRequired: [{ type: 'email', message: EMAIL_VALIDATION_MSG }],
  standardCommaSeparatedEmails: [
    { required: true, message: 'The field should be a valid email or comma separated list of emails' },
    validateEmails
  ]
}
