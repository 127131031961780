import React, { FC, ReactElement } from 'react'
import { SettingsHeader } from './SettingsHeader'
import { NewOrdersSettings } from './NewOrdersSettings'
import { DocumentUpdatesSettings } from './DocumentUpdatesSettings'
import { ESignSettings } from './ESignSettings'
import { GeneralOrderUpdatesSettings } from './GeneralOrderUpdatesSettings'
import { OrderCompletionSettings } from './OrderCompletionSettings'
import { Requests } from '../../../api/requests'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { EmailSettingsT, SettingsParams, EmailSettingsOptions } from './types'
import { SettingsFooter } from './SettingsFooter'
import { useConfirmBrowserExit } from '../../../hooks/useConfirmBrowserExit'

export const Settings: FC<SettingsParams> = ({
  emailSettingsId,
  notificationSettingsId,
  companyId,
  newCommentUiFlipperEnabled
}): ReactElement => {
  const confirmBrowserExit = useConfirmBrowserExit()
  const { request: emailSettingsRequest, response: emailSettings } = Snapdocs.useResources(
    Requests.emailSettings({ id: emailSettingsId, companyId })
  )
  const { request: featurePoliciesRequest, response: featurePolicies } = Snapdocs.useResources(
    Requests.featurePolicies({ companyId })
  )
  const { request: notificationSettingsRequest, response: notificationSettings } = Snapdocs.useResources(
    Requests.notificationSettings({ id: notificationSettingsId, companyId })
  )

  if (
    Requests.hasLoaded(emailSettingsRequest) &&
    Requests.hasLoaded(featurePoliciesRequest) &&
    Requests.hasLoaded(notificationSettingsRequest)
  ) {
    const settings: EmailSettingsT = emailSettings as EmailSettingsT
    const options: EmailSettingsOptions = emailSettingsRequest.meta.options as EmailSettingsOptions

    return (
      <div className="notification-settings">
        <SettingsHeader newCommentUiFlipperEnabled={newCommentUiFlipperEnabled} />
        <NewOrdersSettings
          emailSettings={settings}
          options={options}
          featurePolicies={featurePolicies}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
        <DocumentUpdatesSettings
          emailSettings={settings}
          options={options}
          featurePolicies={featurePolicies}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
        <ESignSettings
          emailSettings={settings}
          options={options}
          featurePolicies={featurePolicies}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
        <GeneralOrderUpdatesSettings
          emailSettings={settings}
          options={options}
          featurePolicies={featurePolicies}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
        <OrderCompletionSettings
          emailSettings={settings}
          options={options}
          featurePolicies={featurePolicies}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
        <SettingsFooter
          emailSettings={emailSettings}
          notificationSettings={notificationSettings}
          confirmBrowserExit={confirmBrowserExit}
        />
      </div>
    )
  }

  return <></>
}
