// This file defines the method columnSortTooltip
// which allows to show custom text on tooltip used for sorting a column
//
// Order of sorter values -> 'asc', 'desc', ''
// if current value is 'asc' : the tooltip should show text for descending
// if current value is 'desc' : the tooltip should show text for ascending
// if current value is '' : the tooltip should show text for ascending

import { SORT_DIRECTIONS } from '../../constants/COLUMN_SORTER'

type SortTooltipProps = {
  dataIndex: string
  sortColumn: string
  sortOrder: string
  ascText: string
  descText: string
  cancelText: string
}

export const columnSortTooltip = (sortTooltipProps: SortTooltipProps): { [key: string]: string } => {
  if (sortTooltipProps.dataIndex !== sortTooltipProps.sortColumn) {
    return { title: sortTooltipProps.ascText, placement: 'bottom' }
  }

  switch (sortTooltipProps.sortOrder) {
    case SORT_DIRECTIONS.ASC:
      return { title: sortTooltipProps.descText, placement: 'bottom' }
    case SORT_DIRECTIONS.DESC:
      return { title: sortTooltipProps.cancelText, placement: 'bottom' }
    default:
      return { title: sortTooltipProps.ascText, placement: 'bottom' }
  }
}
