import React, { FC, ReactElement, useState } from 'react'
import { Form, Input, Collapse, Switch, Row, Col, AutoComplete } from 'antd'
import { AddSignerDetailProps } from './addSignerDetailProps'
import { formValidatorForWorkPhone } from '../../../utils/antFormValidations/validateWorkPhone'
import InputMask from 'react-input-mask'
import { MASKS } from '../../../constants/MASKS'
import { Requests } from '../../../api'
import { ParticipantPopover } from './AddParticipantsPopover/ParticipantPopover'
import { formValidatorForPhone } from '../../../utils/validatePhone'
import { Snapdocs } from '../../../hooks/api/snapdocs'
import { FORM_RULES } from '../../../utils/formRules'
import './styles.modules.scss'

export const AddSignerDetailForm: FC<AddSignerDetailProps> = ({
  order,
  isTitleCompany,
  isClosingGenerated
}): ReactElement => {
  const [observerEmailSearchParams, setObserverEmailSearchParams] = useState('')
  const { Panel } = Collapse
  const [sendUpdatesToSigner, setSendUpdatesToSigner] = useState(true)
  const [sendUpdatesToCoSigner, setSendUpdatesToCoSigner] = useState(true)
  // TODO: For MNS-2744, currently only email is provided, if in the future this for is expanded to edit orders
  // then this request needs to be updated with order ID
  const { response: observerEmails } = Snapdocs.useResources(Requests.matchParticipantEmails(observerEmailSearchParams))
  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }

  const toggleSendUpdatesToSigner = () => {
    setSendUpdatesToSigner(!sendUpdatesToSigner)
  }

  const toggleSendUpdatesToCoSigner = () => {
    setSendUpdatesToCoSigner(!sendUpdatesToCoSigner)
  }

  const checkForAndSetMatchingParticipants = (email: string) => {
    setObserverEmailSearchParams(email)
  }

  return (
    <>
      <Form className="signer-detail-form" layout="vertical">
        {isTitleCompany ? (
          <div className="signer-detail-header">Consumer</div>
        ) : (
          <div className="signer-detail-header">Signer</div>
        )}

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item label="First name" name="firstName" rules={FORM_RULES.firstNameRequired}>
              <Input type="text" disabled={isClosingGenerated} autosize="true" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name" name="lastName" rules={FORM_RULES.lastNameRequired}>
              <Input type="text" disabled={isClosingGenerated} autosize="true" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              label="Mobile phone"
              name="signerMobilePhone"
              rules={[
                {
                  validator: formValidatorForPhone
                }
              ]}
            >
              <InputMask mask={MASKS.PHONE} className="input-mask" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Home phone"
              name="signerHomePhone"
              rules={[
                {
                  validator: formValidatorForPhone
                }
              ]}
            >
              <InputMask mask={MASKS.PHONE} className="input-mask" />
            </Form.Item>
          </Col>
        </Row>
        {!order.workPhone && (
          <Row className="work-phone">
            <Col span={24}>
              <Collapse className="work-phone-collapse">
                <Panel header="Add work phone?" key="workPhone" className="work-phone-information-panel">
                  <Form.Item label="Work phone" name="workPhone" rules={[{ validator: formValidatorForWorkPhone }]}>
                    <InputMask mask={MASKS.BUSINESS_PHONE} className="input-mask" />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
        <Row className="signer-email-address" gutter={rowGutter}>
          <Col span={24}>
            <Form.Item label="Email address" name="signerEmailAddress" rules={FORM_RULES.emailNotRequired}>
              <Input disabled={isClosingGenerated} autosize="true" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="form-row">
          <Col span={4}>
            <Form.Item name="sendUpdatesToSigner" valuePropName="checked">
              <Switch onChange={toggleSendUpdatesToSigner} checked={sendUpdatesToSigner} />
            </Form.Item>
          </Col>
          <Col span={20}>
            <span className="send-updates-to-signer-explanation">
              Send a confirmation, give access to basic info and, if enabled, preview order docs
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse className="work-phone-collapse">
              <Panel header="Add cosigner?" key="coSigner" className="work-phone-information-panel">
                <Row className="form-row" gutter={rowGutter}>
                  <Col span={12}>
                    <Form.Item
                      placeholder="First Name"
                      label="First name"
                      name="firstName"
                      rules={FORM_RULES.firstNameRequired}
                    >
                      <Input type="text" disabled={isClosingGenerated} autosize="true" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Last name" name="lastName" rules={FORM_RULES.lastNameRequired}>
                      <Input type="text" disabled={isClosingGenerated} autosize="true" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="form-row" gutter={rowGutter}>
                  <Col span={12}>
                    <Form.Item
                      label="Mobile phone"
                      name="coSignerMobilePhone"
                      rules={[
                        {
                          validator: formValidatorForPhone
                        }
                      ]}
                    >
                      <InputMask mask={MASKS.PHONE} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Home phone"
                      name="coSignerHomePhone"
                      rules={[
                        {
                          validator: formValidatorForPhone
                        }
                      ]}
                    >
                      <InputMask mask={MASKS.PHONE} className="input-mask" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Work phone" name="workPhone" rules={[{ validator: formValidatorForWorkPhone }]}>
                      <InputMask mask={MASKS.BUSINESS_PHONE} className="input-mask" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={rowGutter}>
                  <Col span={24}>
                    <Form.Item label="Email address" name="coSignerEmailAddress" rules={FORM_RULES.emailNotRequired}>
                      <Input type="text" disabled={isClosingGenerated} autosize="true" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col span={4}>
                    <Form.Item name="sendUpdatesToCoSigner" valuePropName="checked">
                      <Switch onChange={toggleSendUpdatesToCoSigner} checked={sendUpdatesToCoSigner} />
                    </Form.Item>
                  </Col>
                  <Col span={20}>
                    <span className="send-updates-to-signer-explanation">
                      Send a confirmation, give access to basic info and, if enabled, preview order docs
                    </span>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>

        {/* TODO: Add typeahead functionality in MNS-3393 */}

        <Row className="add-participants-header" gutter={rowGutter} align="bottom">
          <h3>Add participants</h3>
          <span>
            <ParticipantPopover />
          </span>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="addParticipants" rules={FORM_RULES.standardCommaSeparatedEmails}>
              <AutoComplete
                type="text"
                placeholder="john@example.com, jane@example.com"
                onChange={checkForAndSetMatchingParticipants}
                options={Object.values(observerEmails)}
                disabled={isClosingGenerated}
                autosize="true"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}
