import React from 'react'
import moment from 'moment-timezone'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip, Typography } from 'antd'
import './styles.modules.scss'
import { Order } from '../../../../../models/order/order'
import { InvoicingMethod } from '../../../../../models/order/invoicingMethod'
import { PayoutStatus } from '../../../../../models/order/payoutStatus'

export const PaidColumn = () => {
  const handlePaidStatus = (order: Order) => {
    if (order.invoicingMethod === InvoicingMethod.Classical) {
      return { text: 'Non VP', tooltip: "This order is not on VendorPay. It's your responsibility to pay the notary." }
    }

    switch (order.payoutStatus) {
      case PayoutStatus.CheckPaid:
        return {
          text: 'Check sent',
          tooltip:
            "This notary has opted out of direct deposit via VendorPay. We've mailed them a check, but can't show deposit status."
        }
      case PayoutStatus.Paid:
        return { text: 'Paid', tooltip: "This payment has been successfully deposited in the notary's bank account." }
      case PayoutStatus.Charged:
        return {
          text: 'In transit',
          tooltip: "We've initiated a transfer to the notary's bank account but it hasn't been deposited yet."
        }
      default:
        return {
          text: 'Not yet',
          tooltip: `We'll initiate a deposit on ${moment(order.nextChargeDate).format('M/D/YY')}`
        }
    }
  }

  return {
    title: 'Paid?',
    dataIndex: 'paid',
    className: 'table-column paid-status-column',
    render: (text: string, order: Order) => {
      const paidStatus = handlePaidStatus(order)
      return (
        <>
          {order.closed && (
            <Tooltip title={paidStatus.tooltip}>
              {text === 'Paid' && <Icons.Check className="paid-icon" />}
              {text !== 'Paid' && (
                <Typography.Text type={paidStatus.text === 'Not yet' ? 'secondary' : ''}>
                  {paidStatus.text}
                </Typography.Text>
              )}
            </Tooltip>
          )}
        </>
      )
    }
  }
}
