import React, { FC, ReactElement } from 'react'
import { Row, Col } from 'antd'
import { ClientAndParticipantPopover } from '../ClientAndParticipantPopover/ClientAndParticipantPopover'

export const SettingsHeader: FC<{ newCommentUiFlipperEnabled: boolean }> = ({
  newCommentUiFlipperEnabled
}): ReactElement => {
  return (
    <Row className="settings-header" align="bottom">
      <Col xs={24} sm={16}>
        <h3>Notification settings</h3>
        <p>Choose how you would like to receive notifications for certain events.</p>
      </Col>
      <Col xs={24} sm={8}>
        <ClientAndParticipantPopover newCommentUiFlipperEnabled={newCommentUiFlipperEnabled} />
      </Col>
    </Row>
  )
}
