import React, { SVGProps } from 'react'

const File: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.172 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8.828C20 8.298 19.789 7.789 19.414 7.414L14.586 2.586C14.211 2.211 13.702 2 13.172 2ZM18.5 9H13V3.5L18.5 9Z"
      fill="currentcolor"
    />
  </svg>
)

export default File
