// --- Axios (Handles HTTP requests) --- //
import axios from 'axios'
import { get } from 'lodash'

// Set common headers
axios.defaults.headers.common.Accept = 'text/javascript'

// Get the auth token
const authenticity_token = get(document.querySelectorAll('meta[name="csrf-token"]'), '0.content')

// Add a request interceptor to put authenticity_token in requests (if it's not a 'get')
axios.interceptors.request.use(
  (config) => {
    const returnedConfig = { ...config }

    // Don't change the config if we're talking to amazon
    if (config.url.match(/s3\.(.*)\.amazonaws\.com/)) {
      return config
    }
    // If this needs to be form url encoded (we can probably remove this once we are done with the JSON API endpoints)
    if (
      config.headers &&
      config.headers['Content-Type'] &&
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      returnedConfig.headers = {
        ...returnedConfig.headers,
        'X-CSRF-Token': authenticity_token
      }
      return returnedConfig
    }

    return returnedConfig
  },
  (error) => Promise.reject(error) // Do something with request error
)
