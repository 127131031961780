import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Form } from 'antd'
import { get, set, invoke, snakeCase, cloneDeep } from 'lodash'
import { BoardFilterForm } from '../../../../Shared/Filters/BoardFilterForm'
import { DashboardFilterProps } from '../types'
import moment, { Moment } from 'moment-timezone'
import { subStringSearch } from '../../../../../utils/matchStrings'
import DashboardHelper from '../../../../../helpers/DashboardHelper/DashboardHelper'

export const DashboardFilter: FC<DashboardFilterProps> = ({
  searchOptions,
  defaultFilter,
  searchParams,
  changeSearchParams
}): ReactElement => {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState(DashboardHelper.parseFilters(get(searchParams, ['q', 'c'], [])))
  const addFilter = () => {
    for (const option in searchOptions) {
      const snakeCasedOption = snakeCase(option)
      if (!get(filters, snakeCasedOption)) {
        setFilters({ ...filters, ...set({}, snakeCasedOption, undefined) })
        break
      }
    }
  }

  const removeFilter = (filterToRemove: string) => {
    onSearch(form.getFieldsValue(), filterToRemove)
  }

  const onSearch = <T extends { options: string[]; values: string[] | Moment[] }>(
    formValues: T,
    filterToRemove: string = ''
  ) => {
    const newSearchParams = cloneDeep(searchParams)
    const params = []
    const options = get(formValues, 'options', [])
    const values = get(formValues, 'values')

    for (let i = 0; i < options.length; i++) {
      if (options[i] === filterToRemove || values[i] === undefined || values[i] === '') continue
      params.push({
        a: [{ name: options[i] }],
        v: [{ value: subStringSearch(options[i], 'date') ? invoke(values[i], 'format', 'MM/DD/YY') : values[i] }]
      })
    }

    if (params.length !== 0) {
      set(newSearchParams, ['q', 'c'], params)
      set(newSearchParams, 'commit', 'Search')
      set(newSearchParams, 'utf8', '✓')
    }
    changeSearchParams(newSearchParams)
  }

  const onFieldsChange = <T extends { name: string[] | number[] }[]>(changedFields: T) => {
    const fieldName = get(changedFields[changedFields.length - 1], 'name')

    if (fieldName && fieldName[0] === 'options') {
      const values = get(form.getFieldsValue(), 'values')
      values[fieldName[1]] = undefined
      form.setFieldsValue({ values })
    }
  }

  const onValuesChange = <T extends { options: string[]; values: string[] }>(changedValues: T) => {
    if (!get(changedValues, 'options')) {
      return
    }

    const fieldsValue = form.getFieldsValue()
    const options = get(fieldsValue, 'options', [])
    const values = get(fieldsValue, 'values', [])
    const newFilters = {}

    for (let i = 0; i < options.length; i++) {
      // Set the value to undefined if the option is appointment_date or the value is a moment object (we don't want to
      // show datetime object for the other options)
      // Otherwise don't change the value
      if (
        (options[i] === changedValues.options[i] && changedValues.options[i] === 'appointment_date') ||
        moment.isMoment(values[i])
      ) {
        set(newFilters, options[i], undefined)
      } else {
        set(newFilters, options[i], values[i])
      }
    }
    setFilters(newFilters)
  }

  useEffect(() => {
    setFilters(DashboardHelper.parseFilters(get(searchParams, ['q', 'c'], [])))
  }, [searchParams])

  return (
    <>
      <Form
        form={form}
        role="form"
        onFinish={<T extends { options: string[]; values: string[] | Moment[] }>(formValues: T) => onSearch(formValues)}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
        preserve={false}
      >
        {Object.keys(filters).length === 0 && (
          <BoardFilterForm
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            currentFilter=""
            index={0}
            disabled={false}
            searchOptions={searchOptions}
            defaultFilter={defaultFilter}
          />
        )}
        {Object.keys(filters).length > 0 &&
          Object.keys(filters).map((filter, index) => (
            <BoardFilterForm
              filters={filters}
              addFilter={addFilter}
              removeFilter={removeFilter}
              currentFilter={filter}
              index={index}
              disabled={index + 1 !== Object.keys(filters).length}
              key={filter}
              searchOptions={searchOptions}
              defaultFilter={defaultFilter}
            />
          ))}
      </Form>
    </>
  )
}
